import React from 'react';
import { Trans } from 'react-i18next';
import {
  DownloadOutlined,
  ShareAltOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import pic4 from 'assets/img/tutorial-slides/Picture4.png';

import Slide, {
  SpaceCenterer,
  PrevButton,
  NextButton,
  CarouselType,
} from './Slide';

const Picture = () => <img src={pic4} alt="" />;

export default ({ carousel }: CarouselType) => (
  <Slide>
    <h2>
      <Trans>Download dashboard data</Trans>
    </h2>

    <SpaceCenterer>
      <Picture />
    </SpaceCenterer>

    <p>
      <Trans>
        Quickly download dashboard data with all filter settings applied using
        the button{' '}
      </Trans>
      <DownloadOutlined style={{ margin: '0 3px' }} />
      <Trans> next to the dashboard name.</Trans>
    </p>

    <p>
      <Trans>Downloads in </Trans>
      <b>
        <Trans>PDF and CSV</Trans>
      </b>
      <Trans> formats are available.</Trans>
    </p>

    <p>
      <Trans>Also now you can share the dashboard </Trans>
      <ShareAltOutlined style={{ margin: '0 3px' }} />
      <Trans> via web link </Trans>
      <SmileOutlined style={{ margin: '0 3px' }} />.
    </p>

    <SpaceCenterer>
      <PrevButton carousel={carousel} />
      <NextButton carousel={carousel} />
    </SpaceCenterer>
  </Slide>
);
