import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';

export default [
  { key: 'slide1', Slide: Slide1 },
  { key: 'slide2', Slide: Slide2 },
  { key: 'slide3', Slide: Slide3 },
  { key: 'slide4', Slide: Slide4 },
  { key: 'slide5', Slide: Slide5 },
];
