import React, { useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar, Upload, Button, Form, Input, Skeleton, Select } from 'antd';
import {
  UserOutlined,
  UploadOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { RootState } from 'reducers';
import { updateUserData } from 'actions/account';
import { getFileUrl } from 'helpers/utils';
import { usePromptError } from 'helpers/hooks';

import { FormItem, Title } from '../ui';

const { Option } = Select;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Personal = ({ user, updUserData, updUserDataStatus }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => form.resetFields(), [form, user.data]);

  const [imgUpload, setImgUpload] = useState<{
    loading: boolean;
    url: string | null;
  }>({ loading: false, url: null });

  const handleImgUpload = async (info: any) => {
    setImgUpload({ loading: true, url: null });
    const imageUrl: string = await getFileUrl(info.file.originFileObj);
    setImgUpload({ loading: false, url: imageUrl });
  };

  usePromptError(updUserDataStatus.prompt, updUserDataStatus.error);

  return (
    <>
      <Title>{t('Personal details')}</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={updUserData}
        style={{ width: '320px' }}
        initialValues={{
          name: user.data.name,
          email: user.data.email,
          language: localStorage.getItem('locale') || 'en',
        }}
      >
        {user.loading ? (
          <Skeleton.Avatar size={80} style={{ margin: '30px 0 24px' }} active />
        ) : (
          <Avatar
            src={imgUpload.url || user.data.image}
            size={80}
            icon={<UserOutlined />}
            style={{ margin: '30px 0 24px' }}
          />
        )}

        <FormItem name="image" valuePropName="file">
          <Upload
            accept="image/png, image/jpeg"
            showUploadList={false}
            customRequest={() => {}}
            onChange={handleImgUpload}
          >
            <Button loading={imgUpload.loading} icon={<UploadOutlined />}>
              {t('Click to upload')}
            </Button>
          </Upload>
        </FormItem>

        <FormItem label={t('Name')} name="name">
          {user.loading ? (
            <Skeleton.Input active />
          ) : (
            <Input placeholder={t('Name')} />
          )}
        </FormItem>

        <FormItem label={t('Language')} name="language">
          <Select>
            <Option value="en">{t('English')}</Option>
            <Option value="ru">{t('Russian')}</Option>
          </Select>
        </FormItem>

        <FormItem
          label={t('Email address')}
          name="email"
          hasFeedback
          rules={[
            {
              message: t('Please enter valid email'),
              type: 'email',
            },
          ]}
        >
          {user.loading ? (
            <Skeleton.Input active />
          ) : (
            <Input placeholder={t('Email address')} disabled />
          )}
        </FormItem>

        <FormItem
          label={t('Change password')}
          name="password"
          hasFeedback
          rules={[
            { min: 8, message: t('Password must be at least 8 symbols') },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('Password')}
          />
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={updUserDataStatus.loading}
          >
            {t('Update personal details')}
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  user: store.account.user,
  updUserDataStatus: store.account.updateUserData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updUserData: updateUserData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
