import React from 'react';
import { Trans } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

import pic1 from 'assets/img/tutorial-slides/Picture1.png';

import Slide, { NextButton, CarouselType, SpaceCenterer } from './Slide';

const Picture = () => <img src={pic1} alt="" />;

export default ({ carousel }: CarouselType) => (
  <Slide>
    <h2>
      <Trans>Metrics</Trans>
    </h2>

    <SpaceCenterer>
      <Picture />
    </SpaceCenterer>

    <p>
      <Trans>Dashboards contain a huge variation of different</Trans>
      <i>
        <Trans> Metrics</Trans>
      </i>
      .
    </p>

    <p>
      <Trans>Each metric is provided with a short </Trans>
      <b>
        <Trans>description</Trans>
      </b>
      <Trans>, which can be read by clicking on </Trans>
      <InfoCircleOutlined style={{ margin: '0 3px' }} />.
    </p>

    <p>
      <Trans>Detailed information about each metric is available in our </Trans>
      <a
        href="https://docs.onedash.cc/#/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>documentation</Trans>
      </a>
      .
    </p>

    <NextButton carousel={carousel} />
  </Slide>
);
