import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Personal, Account } from 'components/sections/settings';

const SettingsRoutes = () => (
  <Switch>
    <Redirect exact from="/settings" to="/settings/account" />
    <Route path="/settings/account" component={Account} />
    <Route path="/settings/personal" component={Personal} />
    <Redirect from="*" to="/settings" />
  </Switch>
);

export default SettingsRoutes;
