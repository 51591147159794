/* eslint-disable camelcase */
import axios from 'axios';
import moment, { Moment } from 'moment';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import {
  Plan,
  BotData,
  UserData,
  LogInFormData,
  SignUpFormData,
  ResetPasswordFormData,
  AccountActionType,
  AccountActionTypeConstant,
  FBLoginResponse,
} from 'types/account';

import { getFileUrl, remEmptyFields, switchLanguage } from 'helpers/utils';
import { RootState } from 'reducers';
import { setTutorialVisible } from './dashboard';

const logInSuccess = (
  prompt: string,
  email: string,
  firstSession = false,
): AccountActionType => ({
  type: AccountActionTypeConstant.LOG_IN_SUCCESS,
  firstSession,
  email,
  prompt,
});

const logInFailure = (error: string): AccountActionType => ({
  type: AccountActionTypeConstant.LOG_IN_FAILURE,
  error,
});

export const logIn = (formData: LogInFormData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const { email, password } = formData;

    try {
      const {
        data: { access_token },
      } = await axios.post(`${process.env.REACT_APP_BACKEND_ADDRESS}/login`, {
        email,
        password,
      });

      localStorage.setItem('access_token', access_token);

      dispatch(logInSuccess("You've successfully logged in!", email));

      setTimeout(() => {
        dispatch({ type: AccountActionTypeConstant.LOG_IN });
        checkAuth(access_token)(dispatch);
      }, 2000);
    } catch (error) {
      dispatch(
        logInFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

export const logInFB = ({ accessToken, status }: FBLoginResponse) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: AccountActionTypeConstant.LOG_IN });

    try {
      if (status === 'not_authorized')
        throw new Error('Your Facebook login was interrupted!');

      if (status === 'unknown')
        throw new Error('Logging in to the Facebook has failed!');

      type Response = {
        access_token: string;
        first_session: boolean;
        email: string;
      };

      const {
        data: { access_token, first_session, email },
      } = await axios.post<Response>(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/register/facebook`,
        {
          token: accessToken,
        },
      );

      localStorage.setItem('access_token', access_token);

      dispatch(
        logInSuccess("You've successfully logged in!", email, first_session),
      );

      if (!first_session) {
        setTimeout(() => {
          checkAuth(access_token)(dispatch);
        }, 2000);
      }
    } catch (error) {
      dispatch(
        logInFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const signUpSuccess = (email: string, prompt: string): AccountActionType => ({
  type: AccountActionTypeConstant.SIGN_UP_SUCCESS,
  email,
  prompt,
});

const signUpFailure = (error: string): AccountActionType => ({
  type: AccountActionTypeConstant.SIGN_UP_FAILURE,
  error,
});

export const signUp = (formData: SignUpFormData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: AccountActionTypeConstant.SIGN_UP });

    try {
      const { name, email, password, website } = formData;

      const {
        data: { message },
      } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/register`,
        {
          name,
          email,
          password,
          website,
        },
      );

      dispatch(signUpSuccess(email, message));
    } catch (error) {
      dispatch(
        signUpFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const resetPasswordSuccess = (prompt: string): AccountActionType => ({
  type: AccountActionTypeConstant.RESET_PASSWORD_SUCCESS,
  prompt,
});

const resetPasswordFailure = (error: string): AccountActionType => ({
  type: AccountActionTypeConstant.RESET_PASSWORD_FAILURE,
  error,
});

export const resetPassword = (formData: ResetPasswordFormData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const {
        data: { message },
      } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/password/recovery`,
        formData,
      );

      dispatch(resetPasswordSuccess(message));

      setTimeout(() => {
        dispatch(push('/auth/login'));
        dispatch({ type: AccountActionTypeConstant.RESET_PASSWORD });
      }, 3000);
    } catch (error) {
      dispatch(
        resetPasswordFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const verifyUserSuccess = (prompt: string): AccountActionType => ({
  type: AccountActionTypeConstant.VERIFY_USER_SUCCESS,
  prompt,
});

const verifyUserFailure = (error: string): AccountActionType => ({
  type: AccountActionTypeConstant.VERIFY_USER_FAILURE,
  error,
});

export const verifyUser = (token: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const {
        data: { message },
      } = await axios.get(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/verification/${token}`,
      );

      dispatch(verifyUserSuccess(message));

      setTimeout(() => {
        dispatch(push('/auth/login'));
        dispatch({ type: AccountActionTypeConstant.VERIFY_USER });
      }, 3000);
    } catch (error) {
      dispatch(
        verifyUserFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const changePasswordSuccess = (prompt: string): AccountActionType => ({
  type: AccountActionTypeConstant.CHANGE_PASSWORD_SUCCESS,
  prompt,
});

const changePasswordFailure = (error: string): AccountActionType => ({
  type: AccountActionTypeConstant.CHANGE_PASSWORD_FAILURE,
  error,
});

export const changePassword = (token: string, password?: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/password/recovery/${token}`,
        password ? { password } : {},
      );

      if (password) {
        dispatch(changePasswordSuccess('Your password has been changed'));

        setTimeout(() => {
          dispatch(push('/auth/login'));
          dispatch({ type: AccountActionTypeConstant.CHANGE_PASSWORD });
        }, 3000);
      }
    } catch (error) {
      dispatch(
        changePasswordFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
      if (!password && error.message) {
        setTimeout(() => {
          dispatch(push('/auth/login'));
          dispatch({ type: AccountActionTypeConstant.CHANGE_PASSWORD });
        }, 3000);
      }
    }
  };
};

export const logOut = () => {
  return (dispatch: Dispatch) => {
    const token = localStorage.getItem('access_token');

    axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    localStorage.removeItem('access_token');
    dispatch({ type: AccountActionTypeConstant.LOG_OUT });
    dispatch(push('/auth'));
  };
};

const getUserDataSuccess = (data: UserData) => ({
  type: AccountActionTypeConstant.GET_USER_DATA_SUCCESS,
  data,
});

const getUserDataFailure = (error: string) => ({
  type: AccountActionTypeConstant.GET_USER_DATA_FAILURE,
  error,
});

export const getUserData = () => async (dispatch: Dispatch) => {
  dispatch({ type: AccountActionTypeConstant.GET_USER_DATA });

  const token = localStorage.getItem('access_token');

  let imageUrl;

  try {
    const { data: image } = await axios.get(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/photo`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    imageUrl = await getFileUrl(image);
  } catch (error) {
    imageUrl = null;
  }

  try {
    type UserDataBackend = {
      name: string;
      email: string;
      company: string | null;
      timestamp: string;
      tariff: Plan;
      trial_exp_date: string | null;
    };

    const { data } = await axios.get<UserDataBackend>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const regDate = moment(data.timestamp);
    const trialExpDate = moment(data.trial_exp_date);

    dispatch(
      getUserDataSuccess({
        name: data.name,
        email: data.email,
        company: data.company,
        image: imageUrl,
        plan: data.tariff,
        regDate,
        trialExpDate,
      }),
    );
  } catch (error) {
    dispatch(getUserDataFailure('Error!'));
  }
};

const updateUserDataSuccess = (
  data: { [key: string]: string },
  prompt: string,
) => ({
  type: AccountActionTypeConstant.UPDATE_USER_DATA_SUCCESS,
  data,
  prompt,
});

const updateUserDataFailure = (error: string) => ({
  type: AccountActionTypeConstant.UPDATE_USER_DATA_FAILURE,
  error,
});

export const updateUserData = (data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: AccountActionTypeConstant.UPDATE_USER_DATA });

  const { image, language, ...rest } = data;
  const updData = remEmptyFields(rest);

  switchLanguage(language);

  try {
    const token = localStorage.getItem('access_token');

    await axios.put(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/update`,
      updData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (image) {
      const formData = new FormData();
      formData.append('photo', image.file.originFileObj);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user/photo`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const imageUrl: string = await getFileUrl(image.file.originFileObj);

      dispatch(
        updateUserDataSuccess(
          { ...updData, image: imageUrl },
          'Your data has been successfully updated',
        ),
      );
    }

    dispatch(
      updateUserDataSuccess(updData, 'Your data has been successfully updated'),
    );
  } catch (error) {
    dispatch(updateUserDataFailure('Error!'));
  }
};

export const getBotsListSuccess = (bots: BotData[], botInit: BotData) => ({
  type: AccountActionTypeConstant.GET_BOTS_LIST_SUCCESS,
  bots,
  botInit,
});

const getBotsListFailure = (error: string) => ({
  type: AccountActionTypeConstant.GET_BOTS_LIST_FAILURE,
  error,
});

export const getBotsList = () => async (dispatch: Dispatch) => {
  try {
    type BotsList = {
      result: {
        name: string;
        id: number;
        timestamp: string;
        token: string;
        remove_pii: boolean;
      }[];
    };

    dispatch({ type: AccountActionTypeConstant.GET_BOTS_LIST });

    const {
      data: { result },
    } = await axios.get<BotsList>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/bot/list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    const botsList = result.map(rec => {
      const { remove_pii, ...bot } = rec;
      return {
        ...bot,
        timestamp: moment(bot.timestamp),
        anonymized: remove_pii,
      };
    });

    const botIdCached = localStorage.getItem('botId');
    const botInit =
      botIdCached === null
        ? botsList[0]
        : botsList.filter(bot => bot.id === +botIdCached)[0] || botsList[0];

    dispatch(getBotsListSuccess(botsList, botInit));
  } catch (error) {
    dispatch(getBotsListFailure('Error!'));
  }
};

export const switchBot = (_botId: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { account } = getState();

  const botId = account.bots.list.filter(bot => bot.id === +_botId)[0]
    ? _botId
    : account.bots.current?.id || account.bots.list[0].id;

  localStorage.setItem('botId', botId.toString());

  dispatch({
    type: AccountActionTypeConstant.SWITCH_BOT,
    botId,
  });
};

const checkAuthSuccess = (firstSession: boolean) => ({
  type: AccountActionTypeConstant.CHECK_AUTH_SUCCESS,
  firstSession,
});

const checkAuthFailure = (error: string) => ({
  type: AccountActionTypeConstant.CHECK_AUTH_FAILURE,
  error,
});

export const checkAuth = (_token?: string) => async (dispatch: Dispatch) => {
  dispatch({ type: AccountActionTypeConstant.CHECK_AUTH });

  try {
    type Response = {
      first_session: boolean;
    };

    const token = _token || localStorage.getItem('access_token');

    if (token === null) {
      throw new Error('You are not logged in!');
    }

    const { data } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/check`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    dispatch(setTutorialVisible(data.first_session));
    dispatch(checkAuthSuccess(data.first_session));
  } catch (error) {
    localStorage.removeItem('access_token');
    dispatch(checkAuthFailure('error'));
  }
};

const createIntercomBotSuccess = (
  botName: string,
  botId: number,
  botToken: string,
  timestamp: Moment,
  prompt: string,
) => ({
  type: AccountActionTypeConstant.CREATE_INTERCOM_BOT_SUCCESS,
  botToken,
  botId,
  botName,
  prompt,
  timestamp,
});

const createIntercomBotFailure = (error: string) => ({
  type: AccountActionTypeConstant.CREATE_INTERCOM_BOT_FAILURE,
  error,
});

export const createIntercomBot = (
  intercomToken: string,
  name: string,
) => async (dispatch: Dispatch) => {
  dispatch({ type: AccountActionTypeConstant.CREATE_INTERCOM_BOT });

  try {
    type Response = {
      token: string;
      id: number;
    };

    const token = localStorage.getItem('access_token');

    const { data } = await axios.post<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/bot/intercom/create`,
      {
        name,
        token: intercomToken,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    dispatch(
      createIntercomBotSuccess(
        name,
        data.id,
        data.token,
        moment(),
        'Your new Bot has been successfully created!',
      ),
    );
  } catch (error) {
    dispatch(
      createIntercomBotFailure(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
};

const createBotSuccess = (
  botName: string,
  botId: number,
  botToken: string,
  timestamp: Moment,
  prompt: string,
  anonymized: boolean,
) => ({
  type: AccountActionTypeConstant.CREATE_BOT_SUCCESS,
  botToken,
  botId,
  botName,
  prompt,
  timestamp,
  anonymized,
});

const createBotFailure = (error: string) => ({
  type: AccountActionTypeConstant.CREATE_BOT_FAILURE,
  error,
});

export const createBot = (name: string, anonymize: boolean) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: AccountActionTypeConstant.CREATE_BOT });

  try {
    type Response = {
      token: string;
      id: number;
    };

    const token = localStorage.getItem('access_token');

    const { data } = await axios.post<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/bot/create`,
      {
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    dispatch(updateBotAnonymization(data.id, anonymize) as any);

    dispatch(
      createBotSuccess(
        name,
        data.id,
        data.token,
        moment(),
        'Your new Bot has been successfully created',
        anonymize,
      ),
    );
  } catch (error) {
    dispatch(
      createBotFailure(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
};

const deleteBotSuccess = (
  id: number,
  prompt: string,
  newCurrentBot?: BotData | null,
) => ({
  type: AccountActionTypeConstant.DELETE_BOT_SUCCESS,
  id,
  newCurrentBot,
  prompt,
});

const deleteBotFailure = (error: string) => ({
  type: AccountActionTypeConstant.DELETE_BOT_FAILURE,
  error,
});

export const deleteBot = (id: number) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  dispatch({ type: AccountActionTypeConstant.DELETE_BOT });

  const {
    account: { bots },
  } = getState();

  try {
    type Response = {
      message: string;
    };

    let newCurrentBot: BotData | undefined | null = null;

    const token = localStorage.getItem('access_token');

    const { data } = await axios.delete<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/bot/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (id === bots.current?.id) {
      [newCurrentBot] = bots.list.filter(bot => bot.id !== id) as [
        BotData | undefined,
      ];

      if (newCurrentBot) {
        localStorage.setItem('botId', newCurrentBot.id.toString());
      } else {
        localStorage.removeItem('botId');
      }
    }

    dispatch(deleteBotSuccess(id, data.message, newCurrentBot));
  } catch (error) {
    dispatch(
      deleteBotFailure(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
};

const updateBotAnonymizationSuccess = () => ({
  type: AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION_SUCCESS,
});

const updateBotAnonymizationFailure = (error: string) => ({
  type: AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION_FAILURE,
  error,
});

export const updateBotAnonymization = (
  botId: number,
  anonymization: boolean,
) => async (dispatch: Dispatch) => {
  dispatch({ type: AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION });

  try {
    type Response = {
      result: string;
    };

    const token = localStorage.getItem('access_token');

    const { data } = await axios.post<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/bot/pii/update`,
      { bot_id: botId, remove_pii: anonymization },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    dispatch(updateBotAnonymizationSuccess());
  } catch (error) {
    dispatch(
      updateBotAnonymizationFailure(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
};
