import { MetricsState, MetricName } from 'types/metrics';
import { DashboardState, DatesRange } from 'types/dashboard';
import JSZip from 'jszip';
import moment from 'moment';
import { transposeArray } from './utils';

export const arrayToCSVBlob = (array: any[][]) => {
  const csvContent = `\ufeff${array
    .map(row =>
      row?.map(cell => cell.toString().replace(/[";]/g, '')).join(';'),
    )
    .join('\n')}`;

  const csvBlob = new Blob([csvContent], {
    type: 'data:text/csv;charset=utf-8,',
  });

  return csvBlob;
};

type MetricTable = (string | number)[][];

export const getMetricArray = (
  key: string,
  metricData: MetricsState['metric'][MetricName],
  formatted = true,
): MetricTable => {
  switch (metricData.type) {
    case 'stat': {
      if (['likes'].includes(key)) {
        const statVal = (metricData as MetricsState['metric']['likes']).data;
        const nullResult = formatted ? [['No data']] : [];
        return statVal === undefined
          ? nullResult
          : [
              ['likes', statVal.likes],
              ['dislikes', statVal.dislikes],
              ['like percent', statVal.like_percent],
            ];
      }

      const statVal = (metricData as MetricsState['metric']['nps']).value;
      const nullResult = formatted ? [['No data']] : [];
      return statVal === null ? nullResult : [[statVal]];
    }

    case 'table': {
      const tableData = (metricData as MetricsState['metric']['refs']).data;
      if (!tableData?.rows?.length) return formatted ? [['No data']] : [];

      const cols = tableData?.columns.map(col => col.title) || [];
      const rows = tableData?.rows.map(row => Object.values(row).slice(1));

      return [cols, ...rows];
    }

    case 'chart': {
      // Sankey
      if (['flowThroughHandler', 'conversationalFlow'].includes(key)) {
        const sankeyData = (metricData as MetricsState['metric']['flowThroughHandler'])
          .chart;
        if (!sankeyData?.links?.length) return formatted ? [['No data']] : [];

        const sankeyLinks = sankeyData?.links.map(link => [
          sankeyData?.nodes[link.source].name || '',
          sankeyData?.nodes[link.target].name || '',
          link.value,
        ]);

        return [['Source', 'Target', 'Count'], ...sankeyLinks];
      }

      // Funnel
      if (key === 'funnel') {
        const funnelLevels = (metricData as MetricsState['metric']['funnel']).chart?.map(
          level => [level.intent, level.count, `${level.percent.toFixed(2)}%`],
        );

        if (!funnelLevels?.length) return formatted ? [['No data']] : [];
        return [['Intent', 'Count', 'Percentage'], ...funnelLevels];
      }

      // Scatter
      if (key === 'activity') {
        const scatterData = (metricData as MetricsState['metric']['activity'])
          .chart;

        if (!scatterData?.length) return formatted ? [['No data']] : [];

        const weekdays = [...new Set(scatterData?.map(cell => cell.date))];

        const activity = weekdays.map(day => {
          const countsHourly = new Array(24).fill(0);

          scatterData
            ?.filter(cell => cell.date === day)
            .forEach(cell => {
              countsHourly[cell.hour] = cell.count.toString();
            });

          return [day, ...countsHourly];
        });

        const activityMatrix = [...activity, ['Hour', ...new Array(24).keys()]];

        if (!formatted) return transposeArray(activityMatrix);
        return activityMatrix;
      }

      if (['engagement'].includes(key)) {
        const engagementData = (metricData as MetricsState['metric']['engagement'])
          .chart;
        if (
          !engagementData?.time?.length ||
          !engagementData?.interactions?.length ||
          !engagementData?.sessions?.length
        )
          return formatted ? [['No data']] : [];

        const time = Object.keys(engagementData?.time[0]).map(label => [
          `${label[0].toUpperCase()}${label.slice(1)}`,
          // @ts-ignore
          ...engagementData.time?.map(r => r[label]),
        ]);
        const interactions = Object.keys(engagementData?.interactions[0]).map(
          label => [
            `${label[0].toUpperCase()}${label.slice(1)}`,
            // @ts-ignore
            ...engagementData.interactions?.map(r => r[label]),
          ],
        );
        const sessions = Object.keys(engagementData?.sessions[0]).map(label => [
          `${label[0].toUpperCase()}${label.slice(1)}`,
          // @ts-ignore
          ...engagementData.sessions?.map(r => r[label]),
        ]);
        const chartArr = [
          ['Time'],
          ...time,
          ['Interactions'],
          ...interactions,
          ['Sessions'],
          ...sessions,
        ];

        return formatted ? chartArr : transposeArray(chartArr);
      }

      // Common metrics (Bar, Line, Area)
      const chartData = (metricData as MetricsState['metric']['newUsers'])
        .chart;

      if (!chartData?.length) return formatted ? [['No data']] : [];

      const chartArr = Object.keys(chartData[0]).map(label => [
        `${label[0].toUpperCase()}${label.slice(1)}`,
        ...chartData?.map(r => r[label]),
      ]);

      return formatted ? chartArr : transposeArray(chartArr);
    }

    default:
      return formatted ? [['ERROR: There is no such metric type!']] : [];
  }
};

export const createSearchMessagesCSV = (
  data: any,
  botName: string,
  datesRange: DatesRange,
): Blob => {
  if (!data.length) {
    return arrayToCSVBlob([['no data :(']]);
  }

  const table: MetricTable = [
    ['Search-Messages'],
    ['Bot:', botName],
    [
      'Dates range:',
      `${datesRange.startDate.format(
        'DD.MM.YYYY',
      )} - ${datesRange.endDate.format('DD.MM.YYYY')}`,
    ],
  ];

  table.push([]);
  table.push(Object.keys(data[0]));

  data.forEach((dataElement: object) => {
    table.push(Object.values(dataElement).map(value => String(value)));
  });

  return arrayToCSVBlob(table);
};

export const createTableCSV = (
  data: any,
  currentTool: string,
  botName: string,
  datesRange?: DatesRange,
): Blob => {
  if (!data.length) {
    return arrayToCSVBlob([['no data :(']]);
  }

  const table: MetricTable = [[currentTool], ['Bot:', botName]];

  if (datesRange) {
    table.push([
      'Dates range:',
      `${datesRange.startDate.format(
        'DD.MM.YYYY',
      )} - ${datesRange.endDate.format('DD.MM.YYYY')}`,
    ]);
  }

  table.push([]);
  table.push(Object.keys(data[0]).slice(1));

  data.forEach((dataElement: object) => {
    table.push(Object.values(dataElement).slice(1));
  });

  return arrayToCSVBlob(table);
};

export const createClusteringCSV = (
  data: any[],
  botName: string,
  datesRange: DatesRange,
): Blob => {
  if (!data.length) {
    return arrayToCSVBlob([['no data :(']]);
  }

  data.unshift(['Sentence', 'Label']);
  data.unshift([]);
  data.unshift(
    ['Log-clustering'],
    ['Bot:', botName],
    [
      'Dates range:',
      `${datesRange.startDate.format(
        'DD.MM.YYYY',
      )} - ${datesRange.endDate.format('DD.MM.YYYY')}`,
    ],
  );

  return arrayToCSVBlob(data);
};

export const createAllOneCSV = (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
  botName: string,
  datesRange: DatesRange,
): Blob => {
  if (!currentDashboard) {
    return arrayToCSVBlob([['no data :(']]);
  }

  const table: MetricTable = [
    ['Dashboard:', currentDashboard.name],
    ['Bot:', botName],
    [
      'Dates range:',
      `${datesRange.startDate.format(
        'DD.MM.YYYY',
      )} - ${datesRange.endDate.format('DD.MM.YYYY')}`,
    ],
  ];

  Object.entries(metrics)
    .filter(([_key, metricData]) =>
      metricData.boards.includes(currentDashboard.key),
    )
    .forEach(([key, metricData]) => {
      table.push([]);
      table.push([metricData.title]);
      table.push(...getMetricArray(key, metricData));
    });

  return arrayToCSVBlob(table);
};

export const createFullZipCSV = (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
) => {
  const zip = JSZip();

  if (!currentDashboard) return zip.generateAsync({ type: 'blob' });

  Object.entries(metrics)
    .filter(([_key, metricData]) =>
      metricData.boards.includes(currentDashboard.key),
    )
    .forEach(([key, metricData]) => {
      zip.file<'blob'>(
        `${metricData.title.replace('/', '_')}.csv`,
        arrayToCSVBlob([...getMetricArray(key, metricData, false)]),
      );
    });

  return zip.generateAsync({ type: 'blob' });
};
