import styled from 'styled-components';
import { grey } from '@ant-design/colors';

const SubTitle = styled.h2`
  margin: 60px 0 24px;

  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${grey[7]};
`;

export default SubTitle;
