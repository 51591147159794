import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, Skeleton, Input, Button, message } from 'antd';

type Props = {
  title: string;
  text: string | null | undefined;
  placeholder?: string;
  messageSuccess?: string;
  visible: boolean;
  loading?: boolean;
  disabled?: boolean;
  onCancel: () => void;
};

const CopyClipboardModal = ({
  title,
  text,
  visible,
  loading,
  disabled,
  onCancel,
  placeholder,
  messageSuccess,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      title={title}
      destroyOnClose
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
        <CopyToClipboard
          key="copy"
          text={text}
          onCopy={() => message.success(messageSuccess)}
        >
          <Button type="primary" disabled={disabled || loading}>
            {t('Copy to clipboard')}
          </Button>
        </CopyToClipboard>,
      ]}
    >
      <Skeleton title={false} paragraph={{ rows: 4 }} active loading={loading}>
        <Input.TextArea
          rows={4}
          placeholder={placeholder}
          style={{ maxHeight: '50vh' }}
          readOnly
          defaultValue={text}
        />
      </Skeleton>
    </Modal>
  );
};

export default CopyClipboardModal;
