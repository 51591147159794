/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Result, Spin } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { grey } from '@ant-design/colors';

const withPlan = (component: JSX.Element | null, granted: boolean | null) =>
  granted ? (
    component
  ) : granted === null ? (
    <Spin size="large" spinning style={{ margin: 'auto' }} />
  ) : (
    <Result
      // eslint-disable-next-line react/no-unescaped-entities
      title={<Trans>This metric isn't available on your account</Trans>}
      icon={<InfoCircleFilled style={{ color: grey[0] }} />}
      extra={
        <Link to="/settings/account" style={{ fontSize: '18px' }}>
          <Trans>Upgrade plan</Trans>
        </Link>
      }
      style={{ margin: 'auto' }}
    />
  );

export default withPlan;
