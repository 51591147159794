import styled from 'styled-components';
import { Form } from 'antd';

const FiltersWrapper = styled(Form)`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 14px;

  & > div.ant-form-item {
    height: 38px;
    margin-bottom: 0px !important;
  }

  .ant-input-affix-wrapper,
  .ant-picker-range {
    height: 38px;
    input {
      font-size: 16px;
    }
  }

  .ant-select-selector,
  .ant-btn-primary {
    font-size: 16px;
    height: 38px !important;
    padding-top: 3px !important;
    input {
      height: 36px !important;
    }
  }
`;

export default FiltersWrapper;
