import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Checkbox, Button, Modal } from 'antd';
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import * as accountActions from 'actions/account';
import { RootState } from 'reducers';
import { ResponseMsgBox, FacebookButton } from 'components/ui';
import { FBLoginResponse } from 'types/account';

import { FormField, HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LogIn = ({
  userEmail,
  firstSession,
  error,
  prompt,
  logIn,
  logInFB,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('Log in to your account')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <Form initialValues={{ remember: false }} onFinish={logIn}>
        <FormField
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter valid email'),
              type: 'email',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: gold[5] }} />}
            placeholder={t('Email')}
          />
        </FormField>

        <FormField
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Password must be at least 8 symbols'),
            },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('Password')}
          />
        </FormField>

        <FormField>
          <FormField name="remember" valuePropName="checked" noStyle>
            <Checkbox>{t('Remember me')}</Checkbox>
          </FormField>

          <Link to="/auth/reset-password" style={{ marginLeft: 'auto' }}>
            {t('Forgot password')}
          </Link>
        </FormField>

        <FormField>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            style={{ borderRadius: '4px' }}
          >
            {t('Log in')}
          </Button>
        </FormField>
      </Form>

      <FacebookButton
        text={t('Continue with Facebook')}
        callback={(res: FBLoginResponse) => logInFB(res)}
        style={{ margin: '-10px 0 24px' }}
        // disabled
      />

      <HelpMessage>
        {`${t('New to OneDash?')} `}
        <Link to="/auth/register">{t('Register now')}</Link>
      </HelpMessage>

      <Modal
        visible={prompt !== '' && firstSession}
        centered
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0', height: '80vh' }}
      >
        <iframe
          title="airtable"
          src={`https://airtable.com/embed/shrnQ7MD47cSEhNCJ?backgroundColor=pink&prefill_email=${userEmail}`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  error: store.account.logIn.error,
  prompt: store.account.logIn.prompt,
  userEmail: store.account.user.data.email,
  firstSession: store.account.firstSession,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logIn: accountActions.logIn,
      logInFB: accountActions.logInFB,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
