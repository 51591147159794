import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import { Layout, Result } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { grey } from '@ant-design/colors';

import { RootState } from 'reducers';

import { setDashboardLoaded, resetDashboardsLoaded } from 'actions/dashboard';
import * as metrics from 'actions/metrics';
import * as dashboardActions from 'actions/dashboard';
import * as accountActions from 'actions/account';
import { MetricName, MetricPanelOption, MetricsState } from 'types/metrics';
import { renderMetric } from 'components/sections/dashboards/DashboardRenderer';

import { Dashboard, getDashboardConfig } from 'helpers/configs/dashboard';
import { isMetricGranted } from 'helpers/utils';
import Header from './Header';
import FilterPanel from './FilterPanel';

const { Content } = Layout;

const MetricsWrapper = styled(Content)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  align-items: stretch;
  margin: -5px;
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Dashboards = ({
  bots,
  dashboards,
  referral,
  channel,
  currentDashboard,
  datesRange,
  refs,
  intents,
  metric,
  search,
  loadMetric,
  plan,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const dashboardConfig: Dashboard = useMemo(
    () =>
      getDashboardConfig(
        t,
        metric,
        loadMetric,
        intents,
        (intent: string) => dispatch(dashboardActions.getIntentMessage(intent)),
        ([option]: MetricPanelOption[]) =>
          dispatch(metrics.getIntentsTree([option])),
      ),
    [t, metric, loadMetric, intents, dispatch],
  );

  const dashboardMetrics = useMemo(
    () => dashboardConfig.map(dashMetric => renderMetric(t, dashMetric)),
    [dashboardConfig, t],
  );

  const loadMetrics = useCallback(
    (boardKey: string, initLoad: boolean = false) => {
      if (dashboards.all?.loaded || dashboards[boardKey]?.loaded) {
        return;
      }

      const loadedBoards = Object.entries(dashboards)
        .filter(([, val]) => val.loaded)
        .map(([key]) => key);

      // case when all dashboards except 'all' were loaded already
      if (
        !loadedBoards.includes('all') &&
        Object.keys(dashboards).length - loadedBoards.length === 1
      ) {
        dispatch(setDashboardLoaded('all'));
        return;
      }

      const currentMetrics = Object.entries(metric).reduce(
        (prev, [key, metricData]) =>
          metricData.boards.includes(boardKey) &&
          metricData?.visible &&
          isMetricGranted(key as MetricName, plan) &&
          key !== 'sessionsAvgTime' && // sessionsAvgTime loaded already -> not included
          !metricData.boards.some(board => loadedBoards.includes(board)) // = metric isn't already loaded
            ? [...prev, key]
            : prev,
        [] as string[],
      );

      currentMetrics.forEach((metricKey: string, index: number) => {
        setTimeout(
          // if options = null, the metric intents aren't reset
          // if options = undefined, the metric intents are reset
          () => (loadMetric as any)[metricKey](initLoad ? null : undefined),
          index * 100,
        );
      });

      dispatch(setDashboardLoaded(boardKey));
    },
    [dashboards, metric, dispatch, plan, loadMetric],
  );

  useEffect(() => {
    if (bots.loading) return;

    if (!paramsSet.current.includes('intents')) {
      // set intent values from URL params to existing metrics
      metricsWithIntents.forEach(metricName => {
        if (urlParams.has(metricName)) {
          dispatch(
            metrics.setMetricIntent(
              metricName as keyof MetricsState['metric'],
              metricName === 'funnel'
                ? String(urlParams.get('funnel')).split(',')
                : urlParams.get(metricName),
            ),
          );
        }
      });
      paramsSet.current.push('intents');
    }

    if (!paramsSet.current.includes('botId')) {
      if (urlParams.has('botId')) {
        dispatch(accountActions.switchBot(urlParams.get('botId') || ''));
      }
      paramsSet.current.push('botId');
    }

    if (!paramsSet.current.includes('datesRange')) {
      if (urlParams.has('startDate') && urlParams.has('endDate')) {
        dispatch(
          dashboardActions.updateDatesRange({
            startDate: moment(urlParams.get('startDate')),
            endDate: moment(urlParams.get('endDate')),
          }),
        );
      }
      paramsSet.current.push('datesRange');
    }

    if (
      refs.loading ||
      !paramsSet.current.includes('botId') ||
      !paramsSet.current.includes('datesRange')
    )
      return;

    if (!paramsSet.current.includes('ref')) {
      if (urlParams.has('ref')) {
        dispatch(dashboardActions.updateRef(urlParams.get('ref') || undefined));
      }
      paramsSet.current.push('ref');
    }

    if (!paramsSet.current.includes('channel')) {
      if (urlParams.has('channel')) {
        dispatch(
          dashboardActions.updateChannel(urlParams.get('channel') || undefined),
        );
      }
      paramsSet.current.push('channel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots.loading, refs.loading, accountActions.switchBot, dashboardActions]);

  const refsIntentsLoaded = useRef(false);

  const reloadMetrics = useCallback(
    (boardKey?: string) => {
      // load refs, channels, intents first, then load metrics
      if (!refsIntentsLoaded.current) {
        dispatch(dashboardActions.getRefsList());
        dispatch(dashboardActions.getChannelList());
        dispatch(dashboardActions.getIntentsList());
        dispatch(resetDashboardsLoaded());
        refsIntentsLoaded.current = true;
      } else if (
        !refs.loading &&
        boardKey &&
        paramsSet.current.includes('ref') &&
        paramsSet.current.includes('channel')
      ) {
        loadMetrics(boardKey, firstLoad.current);
        refsIntentsLoaded.current = false;
        firstLoad.current = false;
      }
    },
    [dispatch, loadMetrics, refs.loading],
  );

  // the list of metric names containing intent(s) filter
  const metricsWithIntents = useMemo<string[]>(
    () =>
      Object.entries(metric).reduce(
        (prevIntents, [metricKey, metricData]: [string, any]) =>
          urlParams.get(metricKey) &&
          metricData.boards.includes(urlParams.get('dashboard')) &&
          ('intent' in metricData || 'intents' in metricData)
            ? [...prevIntents, metricKey]
            : prevIntents,
        [] as string[],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const paramsSet = useRef<string[]>([]);

  // initial load + reload (clear load status for all boards)
  useEffect(() => {
    if (bots.loading || !currentDashboard) {
      return;
    }

    reloadMetrics(currentDashboard.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral, channel, datesRange, bots, refs.loading]);

  const firstLoad = useRef(true);

  // section switch
  useEffect(() => {
    if (!currentDashboard) return;

    if (!firstLoad.current) {
      loadMetrics(currentDashboard.key);
    }
  }, [currentDashboard, loadMetrics]);

  return (
    <Content style={{ flexShrink: 0 }}>
      <Header loadMetrics={() => reloadMetrics(currentDashboard?.key)} />

      <FilterPanel />

      {!bots.current ? (
        <Result
          title={
            <p style={{ color: grey[0] }}>
              Sorry, but you have no bots connected to the account
            </p>
          }
          icon={<InfoCircleFilled style={{ color: grey[0] }} />}
        />
      ) : (
        <MetricsWrapper>{dashboardMetrics}</MetricsWrapper>
      )}
    </Content>
  );
};

const mapStateToProps = (store: RootState) => ({
  bots: store.account.bots,
  referral: store.dashboard.filter.ref,
  channel: store.dashboard.filter.channel,
  intents: store.dashboard.intents,
  refs: store.dashboard.refs,
  datesRange: store.dashboard.filter.datesRange,
  metric: store.metrics.metric,
  dashboards: store.dashboard.list,
  currentDashboard: store.dashboard.current,
  search: store.router.location.search,
  plan: store.account.user.data.plan,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadMetric: bindActionCreators(
    {
      usersTotal: metrics.getUsersTotal,
      conversion: metrics.getConversion,
      sessionsCount: metrics.getSessionsCount,
      newUsers: metrics.getNewUsers,
      uniqueUsers: metrics.getUniqueUsers,
      unsubscribed: metrics.getUnsubscribed,
      engagement: metrics.getEngagement,
      sessions: metrics.getSessions,
      lastMsgDate: metrics.getLastMsgDate,
      sessionsAvgTime: metrics.getSessionStats,
      sessionsAvgCount: metrics.getSessionStats,
      nps: metrics.getNPS,
      likes: metrics.getLikes,
      refs: metrics.getRefs,
      messages: metrics.getMessages,
      messagesCount: metrics.getMessagesCount,
      misunderstood: metrics.getMisunderstood,
      misunderstoodCount: metrics.getMisunderstoodCount,
      misunderstoodIntents: metrics.getMisunderstoodIntents,
      topIntents: metrics.getIntents,
      dauMau: metrics.getDauMau,
      retention: metrics.getRetention,
      intentStats: metrics.getIntentStats,
      activity: metrics.getActivity,
      funnel: metrics.getFunnel,
      flowThroughHandler: metrics.getFlowThroughHandler,
      conversationalFlow: metrics.getConversationalFlow,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
