import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import Dashboards from 'components/sections/dashboards/Dashboards';
import Tools from 'components/sections/tools/Tools';
import Alerts from 'components/sections/alerts/Alerts';
import MyBots from 'components/sections/my-bots/MyBots';
import Settings from 'components/sections/settings/Settings';

const HomeRoutes = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboards" />
    <Redirect exact from="/dashboards" to="/dashboards/all" />
    <Redirect exact from="/tools" to="/tools/link-creator" />

    <Route path="/dashboards" component={Dashboards} />
    <Route path="/tools" component={Tools} />
    <Route path="/alerts" component={Alerts} />
    <Route path="/my-bots" component={MyBots} />
    <Route path="/settings" component={Settings} />

    <Redirect push from="*" to="/" />
  </Switch>
);

export default HomeRoutes;
