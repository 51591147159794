import React from 'react';
import styled, { css } from 'styled-components';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button } from 'antd';
import { FacebookFilled } from '@ant-design/icons';

const FacebookButton = styled(Button)`
  ${props =>
    props.type === 'default' &&
    css`
      color: #4267b2;

      :hover,
      :focus {
        color: #2d88ff;
        border-color: #2d88ff;
      }
    `}

  ${props =>
    props.type === 'primary' &&
    css`
      background-color: #4267b2;
      border-color: #4267b2;

      :hover,
      :focus {
        background-color: #2d88ff;
        border-color: #2d88ff;
      }
    `}
`;

type Props = {
  text: string;
  type?: string;
  callback: Function;
  disabled?: boolean;
  scope?: string;
  style?: { [key: string]: string };
};

type RenderProps = {
  onClick: Function;
  isDisabled: boolean;
  isProcessing: boolean;
  isSdkLoaded: boolean;
};

export default ({
  callback,
  scope,
  type = 'default',
  text,
  disabled = false,
  style,
}: Props) => (
  <FacebookLogin
    appId="633680777236986"
    callback={callback}
    scope={scope}
    render={(renderProps: RenderProps) => (
      <FacebookButton
        type={type}
        icon={<FacebookFilled />}
        disabled={
          disabled || renderProps.isProcessing || !renderProps.isSdkLoaded
        }
        onClick={renderProps.onClick}
        style={style}
      >
        {text}
      </FacebookButton>
    )}
  />
);
