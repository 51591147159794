import styled from 'styled-components';
import { grey } from '@ant-design/colors';

const Title = styled.h1`
  white-space: pre-line;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${grey[9]};
  margin-bottom: 24px;
`;

export default Title;
