import React from 'react';
import { Trans } from 'react-i18next';
import pic5 from 'assets/img/tutorial-slides/Picture5.png';

import Slide, { SpaceCenterer, PrevButton, CarouselType } from './Slide';

const Picture = () => <img src={pic5} alt="" />;

export default ({ carousel }: CarouselType) => (
  <Slide>
    <h2>
      <Trans>Bots</Trans>
    </h2>

    <SpaceCenterer>
      <Picture />
    </SpaceCenterer>

    <p>
      <Trans>
        From the very beginning, you already have a dashboard with a{' '}
      </Trans>
      <b>
        <Trans>demo bot</Trans>
      </b>
      <Trans> and all metrics!</Trans>
    </p>

    <p>
      <Trans>To add your own bot go to </Trans>
      <b>
        “<Trans>My bots</Trans>”
      </b>
      <Trans> section and get a token.</Trans>
    </p>

    <p>
      <Trans>With this </Trans>
      <b>
        <Trans>token</Trans>
      </b>
      <Trans> you are allowed to start transferring data to the OneDash.</Trans>
    </p>

    <p>
      <Trans>To find out more about the connection, check our</Trans>
      <a
        href="https://docs.onedash.cc/#/"
        target="_black"
        rel="noopener noreferrer"
      >
        <Trans> documentation</Trans>
      </a>
      .
    </p>

    <SpaceCenterer>
      <PrevButton carousel={carousel} />
    </SpaceCenterer>
  </Slide>
);
