import React from 'react';
import styled from 'styled-components';
import Metric, { MetricProps } from './Metric';

const StatWrapper = styled.span`
  font-size: 50px;
  line-height: 50px;
`;

type Props = {
  value: string;
} & MetricProps;

const Stat = ({ metricKey, value, empty, size, onReload, panels }: Props) => {
  return (
    <Metric
      metricKey={metricKey}
      type="stat"
      empty={empty}
      size={size}
      onReload={onReload}
      panels={panels}
    >
      <StatWrapper>{value}</StatWrapper>
    </Metric>
  );
};

export default Stat;
