/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table, Spin, Empty, Select, Button, Form } from 'antd';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { AccountState } from 'types/account';
import { EditableTableCell } from 'helpers/commonComponents';
import { FiltersWrapper } from 'components/sections/ui';
import { getColumnSearchProps } from 'helpers/commonComponents/SearchTableColumn';
import { clearSlugTable, getIntentSlug } from 'actions/tools';
import { openInfoModal } from 'helpers/commonComponents/ToolsFilters';
import { renderMetricActionButton } from 'helpers/renderers';
import { RootState } from 'reducers';
import { ToolsState } from 'types/tools';
import { usePromptError } from 'helpers/hooks';
import { downloadTableCSV } from 'actions/dashboard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const MainFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px !important;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 68px);
  width: 60%;
  thead {
    font-size: 16px;
    .ant-table-filter-column-title,
    th {
      font-weight: 600;
      text-align: center;
    }
  }
  td {
    text-align: center;
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-top: 3px;

  & > .ant-btn-link {
    font-size: 20px;
  }

  .ant-btn-link:first-child {
    margin-right: 15px;
  }
`;

const IntentNaming = () => {
  const bots = useSelector<RootState, AccountState['bots']>(
    state => state.account.bots,
  );
  const intentTable = useSelector<RootState, ToolsState['intentTable']>(
    state => state.tools.intentTable,
  );
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [botId, setBotId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  usePromptError(intentTable.prompt, intentTable.error);

  const getIntentNamingColumns = () => [
    {
      title: <Trans>Intent name</Trans>,
      dataIndex: 'intent',
      key: 'intent',
      width: 300,
      ...getColumnSearchProps(
        'intent',
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
      ),
    },
    {
      title: <Trans>Name in OneDash UI</Trans>,
      dataIndex: 'slug',
      key: 'slug',
      width: 300,
      ...getColumnSearchProps(
        'slug',
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
      ),
      render: (slug: string, record: { intent: string }) => (
        <EditableTableCell
          slug={slug}
          intent={record.intent}
          botId={botId as number}
        />
      ),
    },
    {
      title: <Trans>Bot's message</Trans>,
      dataIndex: 'messages',
      key: 'messages',
      render: (messages: string[]) => {
        return messages.map(
          message =>
            message && (
              <div style={{ textAlign: 'left' }} key={message}>
                {message}
              </div>
            ),
        );
      },
      width: 300,
    },
  ];

  const actionButtons = [
    {
      key: 'download',
      type: 'link',
      icon: <DownloadOutlined />,
      visible: !!intentTable.list.length,
      onClick: () =>
        dispatch(
          downloadTableCSV(
            intentTable.list,
            t('Intent naming'),
            bots.list.find(bot => bot.id === botId)?.name,
          ),
        ),
    },
    {
      key: 'info',
      type: 'link',
      icon: <InfoCircleOutlined />,
      visible: true,
      onClick: () => {
        openInfoModal(t('Intent naming'), t('intent-naming-info'));
      },
    },
  ];

  const handleFormValuesChange = (formData: { botId: number }) => {
    setBotId(formData.botId);
  };

  const handleFormFinish = () => {
    dispatch(getIntentSlug(botId as number));
  };

  useEffect(() => {
    return () => {
      dispatch(clearSlugTable());
    };
  }, [dispatch]);
  return (
    <Wrapper>
      <FiltersWrapper
        layout="inline"
        onFinish={handleFormFinish}
        onValuesChange={handleFormValuesChange}
      >
        <MainFilterWrapper>
          <FormItem
            name="botId"
            rules={[
              {
                required: true,
                message: t('This field is required!'),
              },
            ]}
          >
            <Select
              showSearch
              allowClear={false}
              placeholder={t('Select bot')}
              loading={bots.loading}
              options={bots.list.map(bot => ({
                label: bot.name,
                value: bot.id,
              }))}
              style={{ width: '160px' }}
            />
          </FormItem>
          <Button
            type="primary"
            loading={intentTable.loading}
            htmlType="submit"
          >
            {t('Show intents')}
          </Button>
        </MainFilterWrapper>
        <ActionButtonsWrapper>
          {actionButtons.map(button =>
            renderMetricActionButton({
              ...button,
            }),
          )}
        </ActionButtonsWrapper>
      </FiltersWrapper>
      <TableWrapper>
        {intentTable.loading ? (
          <Spin spinning style={{ margin: 'auto' }} />
        ) : !intentTable.list.length ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 'auto' }}
          />
        ) : (
          <Table
            bordered
            dataSource={intentTable.list}
            columns={getIntentNamingColumns()}
            loading={intentTable.loading}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
          />
        )}
      </TableWrapper>
    </Wrapper>
  );
};
export default IntentNaming;
