import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Layout, Popover } from 'antd';
import { grey } from '@ant-design/colors';
import { Trans, useTranslation } from 'react-i18next';

import { InfoCircleFilled } from '@ant-design/icons';
import { sidebarConfig } from 'helpers/configs/sidebar';

const { Header } = Layout;

const HeaderWrapper = styled(Header)`
  display: flex;
  align-items: center;

  height: 56px;
  padding: 0;
  margin-bottom: 16px;
  background-color: transparent;

  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  color: ${grey[9]};
`;

const ContentWrapper = styled(Layout)`
  flex-basis: 0;
  border-radius: 18px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
`;

const InfoIcon = styled(InfoCircleFilled)`
  font-size: 10px;
`;

const Beta = styled.sup`
  vertical-align: super;
  cursor: help;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: ${grey[4]};
`;

const BetaLabel = () => (
  <Popover
    title="Clustering may work unstable during beta"
    content={
      <>
        <p>
          <Trans>
            The date interval for analysis is temporarily limited to 1 week.
          </Trans>
        </p>
        <p>
          <Trans>
            If an error occurs, try decreasing the dates range or choosing a
            different intent to lower the amount of data to show.
          </Trans>
        </p>
      </>
    }
    overlayStyle={{ maxWidth: 320 }}
  >
    <Beta>
      βeta <InfoIcon />
    </Beta>
  </Popover>
);

type Props = {
  title: string;
  subSection?: string;
  children: ReactNode;
};

const Settings = ({ title, subSection, children }: Props) => {
  const { t } = useTranslation();
  const tools = sidebarConfig.find(section => section.key === 'tools')
    ?.children;

  const subSectionName = () => {
    switch (subSection) {
      case 'clustering':
        return (
          <>
            {` / ${t('Log clustering')}`}
            <BetaLabel />
          </>
        );

      default: {
        if (!subSection) return null;
        const toolName = tools?.find(tl => tl.key === subSection)?.value || '';
        return ` / ${t(toolName)}`;
      }
    }
  };

  return (
    <>
      <HeaderWrapper>
        <>
          {`${t(title)}`}
          {subSectionName()}
        </>
      </HeaderWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default Settings;
