import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar, Skeleton, Button } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';

import { AccountState } from 'types/account';
import { Dropdown } from 'components/ui';
import { history } from 'configureStore';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  margin-right: 8px;
  line-height: 0;
`;

const UsernameWrapper = styled.div`
  max-width: 95px;
  max-height: 45px;
  overflow: hidden;

  color: ${grey[5]};
  font-size: 14px;
  line-height: 16px;

  margin-right: 8px;
`;

const DpButton = styled(DownOutlined)`
  color: #8c8c8c;
  font-size: 12px;
  margin-bottom: -3px;
`;

const AuthButton = styled(Button)`
  height: 38px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

type Props = {
  user: AccountState['user'];
  auth: AccountState['auth'];
  logOut: () => void;
};

const AccountPanel = ({ user, auth, logOut }: Props) => {
  const { t } = useTranslation();

  return !auth.passed && !auth.checking ? (
    <AuthButton type="primary" onClick={() => history.push('/auth')}>
      Login \ Register
    </AuthButton>
  ) : (
    <Wrapper style={{ paddingRight: '24px' }}>
      <>
        <AvatarWrapper>
          {auth.checking || user.loading ? (
            <Skeleton.Avatar active size={32} />
          ) : (
            <Link to="/settings/personal">
              <Avatar icon={<UserOutlined />} src={user.data.image} />
            </Link>
          )}
        </AvatarWrapper>

        <Dropdown
          trigger={['click']}
          overlayItems={[
            { key: 'logOut', content: t('Log out'), onClick: logOut },
            { key: 'support', content: t('Support'), disabled: true },
          ]}
          alignItems="center"
        >
          <Wrapper style={{ cursor: 'pointer' }}>
            <UsernameWrapper>
              {user.loading ? (
                <Skeleton
                  title={{ width: 90, style: { margin: 0 } }}
                  paragraph={false}
                  active
                  loading
                />
              ) : (
                user.data.name || ''
              )}
            </UsernameWrapper>

            <DpButton />
          </Wrapper>
        </Dropdown>
      </>
    </Wrapper>
  );
};

export default AccountPanel;
