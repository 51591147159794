import React from 'react';
import styled from 'styled-components';
import { Modal, Button as ButtonAntd } from 'antd';
import { history } from 'configureStore';

const Wrapper = styled.div`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Button = styled(ButtonAntd)`
  padding: 0;
  border: 0;
  height: unset;
`;

const showFeatDeniedModal = (reason?: string) => {
  const modal = Modal.info();

  modal.update({
    title: "Your plan doesn't include this feature",
    maskClosable: true,
    content: (
      <Wrapper>
        {reason && <p>{reason}.</p>}
        <p>
          Please, go to the{' '}
          <Button
            type="link"
            onClick={() => {
              modal.destroy();
              history.push('/settings/account');
            }}
          >
            Account settings
          </Button>{' '}
          to expand the subscription.
        </p>
      </Wrapper>
    ),
  });
};

export default showFeatDeniedModal;
