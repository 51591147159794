import styled from 'styled-components';
import { Layout } from 'antd';

const HomeLayoutWrapper = styled(Layout)`
  padding: 16px 20px;
  position: relative;

  flex-basis: 0;
  overflow: auto;
`;

export default HomeLayoutWrapper;
