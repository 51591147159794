import styled from 'styled-components';
import { grey } from '@ant-design/colors';

const Title = styled.h1`
  margin-bottom: 20px;

  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${grey[10]};
`;

export default Title;
