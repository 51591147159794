import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Route, Switch, Redirect } from 'react-router';

import Home from 'components/home/Home';
import Auth from 'components/auth/Auth';
import Share from 'components/Share';

import { RootState } from 'reducers';
import { checkAuth } from 'actions/account';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AppRoutes = ({ auth, chkAuth }: Props) => {
  useEffect(() => {
    chkAuth();
  }, [chkAuth]);

  return auth.checking ? null : (
    <Switch>
      <Route path="/share" component={Share} />

      {auth.passed ? (
        <Route path="/" component={Home} />
      ) : (
        <Route path="/auth" component={Auth} />
      )}

      {auth.passed ? (
        <Redirect push from="*" to="/" />
      ) : (
        <Redirect push from="*" to="/auth" />
      )}
    </Switch>
  );
};

const mapStateToProps = (store: RootState) => ({
  auth: store.account.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      chkAuth: checkAuth,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
