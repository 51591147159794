import moment from 'moment';
import jsPDF from 'jspdf';
import { MetricsState } from 'types/metrics';
import { DashboardState, DatesRange } from 'types/dashboard';
import logo from 'assets/img/logo-full.png';

import { getImage } from './utils';

const createPDF = async (
  metrics: MetricsState['metric'],
  currentDashboard: DashboardState['current'],
  botName: string,
  datesRange: DatesRange,
) => {
  const pageSize = { width: 800, height: 1131 };
  const pageBorders = { top: 100, right: 80, bottom: 100, left: 80 };
  const fontSize = { title: 30, subTitle: 25, plainText: 18 };
  const gap = 20;

  let topPosition = pageBorders.top;
  let pageCount = 1;

  const addPage = () => {
    doc.addPage();

    // add OneDash logo
    doc.addImage(logoImg, 'PNG', pageBorders.left, 20);

    doc.setTextColor('#5B5B5B');
    doc.setFontSize(fontSize.plainText);

    // add page counter
    doc.text(
      pageSize.width - pageBorders.right - 10,
      pageSize.height - (pageBorders.bottom + fontSize.plainText) / 2,
      (pageCount + 1).toString(),
    );

    // add date of pdf creation
    doc.text(
      pageBorders.left,
      pageSize.height - (pageBorders.bottom + fontSize.plainText) / 2,
      `Report generated: ${moment().format('DD.MM.YYYY')}`,
    );

    doc.setTextColor('#000000');

    return [pageBorders.top, pageCount + 1];
  };

  // eslint-disable-next-line new-cap
  const doc = new jsPDF('p', 'px', [
    (pageSize.width * 4) / 3,
    (pageSize.height * 4) / 3,
  ]);

  // add OneDash logo
  const logoImg = new Image();
  logoImg.src = logo;
  doc.addImage(logoImg, 'PNG', pageBorders.left, 20);

  doc.setTextColor('#5B5B5B');
  doc.setFontSize(fontSize.plainText);

  // add page counter
  doc.text(
    pageSize.width - pageBorders.right - 10,
    pageSize.height - (pageBorders.bottom + fontSize.plainText) / 2,
    pageCount.toString(),
  );

  // add date of pdf creation
  doc.text(
    pageBorders.left,
    pageSize.height - (pageBorders.bottom + fontSize.plainText) / 2,
    `Report generated: ${moment().format('DD.MM.YYYY')}`,
  );

  doc.setTextColor('#000000');

  if (!currentDashboard) {
    doc.setFontSize(fontSize.title);
    doc.text(pageBorders.left, topPosition + gap, 'No data :(');
    return doc;
  }

  // add dashboard name
  doc.setFontSize(fontSize.title);
  doc.text(
    pageBorders.left,
    topPosition + gap,
    `${botName}  —  ${currentDashboard.name}`,
  );
  topPosition += fontSize.title + gap / 2;

  // add dates range
  doc.setTextColor('#8C8C8C');
  doc.setFontSize(fontSize.plainText);
  doc.text(
    pageBorders.left,
    topPosition,
    `Dates range: ${datesRange.startDate.format(
      'DD.MM.YYYY',
    )} - ${datesRange.endDate.format('DD.MM.YYYY')}`,
  );
  doc.setTextColor('#000000');
  topPosition += fontSize.plainText;

  topPosition += 80; // gap before main content part

  const currentMetrics = Object.entries(metrics).filter(([key, metric]) =>
    metric.boards.includes(currentDashboard.key),
  );

  for (const [, metric] of currentMetrics) {
    switch (metric.type) {
      case 'stat':
        if (
          topPosition + fontSize.subTitle + fontSize.plainText >
          pageSize.height - pageBorders.bottom
        ) {
          [topPosition, pageCount] = addPage();
        }

        // add stat title
        doc.setFontSize(fontSize.subTitle);
        doc.text(
          pageBorders.left,
          topPosition + fontSize.subTitle,
          metric.title,
        );
        topPosition += fontSize.subTitle + gap;

        // add stat value
        doc.setFontSize(fontSize.plainText);
        doc.text(
          pageBorders.left,
          topPosition + fontSize.plainText,
          (metric as any).value?.toString() || 'None',
        );
        topPosition += fontSize.plainText + gap;

        break;

      case 'chart':
        if (metric.ref !== undefined && metric.ref.current !== null) {
          const width = pageSize.width - (pageBorders.left + pageBorders.right);

          const { file, height } = await getImage(metric.ref.current, width);

          if (
            topPosition + fontSize.subTitle + height >
            pageSize.height - pageBorders.bottom
          ) {
            [topPosition, pageCount] = addPage();
          }

          // add chart title
          doc.setFontSize(fontSize.subTitle);
          doc.text(
            pageBorders.left,
            topPosition + fontSize.subTitle,
            metric.title,
          );
          topPosition += fontSize.subTitle + gap;

          // add chart image
          doc.addImage(
            file,
            'PNG',
            pageBorders.left,
            topPosition,
            width,
            height,
          );
          topPosition += height + gap;
        }
        break;

      case 'table':
        if (metric.ref !== undefined && metric.ref.current !== null) {
          const width = pageSize.width - (pageBorders.left + pageBorders.right);

          const { file, height } = await getImage(
            metric.ref.current.getElementsByClassName('ant-table-content')[0]
              .children[0] as HTMLDivElement,
            width,
          );

          if (
            topPosition + fontSize.subTitle + height >
            pageSize.height - pageBorders.bottom
          ) {
            [topPosition, pageCount] = addPage();
          }

          // add chart title
          doc.setFontSize(fontSize.subTitle);
          doc.text(
            pageBorders.left,
            topPosition + fontSize.subTitle,
            metric.title,
          );
          topPosition += fontSize.subTitle + gap;

          // add chart image
          doc.addImage(
            file,
            'PNG',
            pageBorders.left,
            topPosition,
            width,
            height,
          );
          topPosition += height + gap;
        }
        break;

      default:
        break;
    }
  }

  return doc;
};

export default createPDF;
