import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';

const SpaceCenterer = styled.div`
  height: 100%;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const MetricPlaceholder = ({
  loaded,
  empty,
  children,
}: {
  loaded: boolean;
  empty: boolean;
  children?: ReactNode;
}): JSX.Element | null => {
  const childrenOrEmpty = empty ? (
    <SpaceCenterer>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </SpaceCenterer>
  ) : (
    <>{children}</>
  );

  return loaded ? (
    childrenOrEmpty
  ) : (
    <SpaceCenterer>
      <Spin
        size="large"
        indicator={<LoadingOutlined style={{ color: grey[0] }} />}
      />
    </SpaceCenterer>
  );
};

export default MetricPlaceholder;
