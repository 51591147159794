import React, { useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';

import { RootState } from 'reducers';
import { BotData } from 'types/account';
import * as toolsActions from 'actions/tools';
import { usePromptError, useSectionPlan } from 'helpers/hooks';
import { CopyClipboardModal } from 'components/ui';

import { withPlan } from 'helpers/commonComponents';
import { FormItem, Title } from '../ui';

const { Option } = Select;

const ColsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Col = styled.div`
  width: 256px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 80px;
  }
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LinkCreator = ({ refLink, createRefLink, bots }: Props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const granted = useSectionPlan('link-creator');

  usePromptError(refLink.prompt, refLink.error);

  const handleFormSubmit = (data: any) => {
    createRefLink(data);
    setModalVisible(true);
  };

  return withPlan(
    <>
      <Title>{t('Link creator')}</Title>
      <Form layout="vertical" onFinish={handleFormSubmit}>
        <ColsWrapper>
          <Col>
            <FormItem
              label={t('Bot')}
              name="bot"
              extra={t('Your bot')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('This field is required!'),
                },
              ]}
            >
              <Select placeholder={t('Choose your bot')}>
                {bots.list.map((b: BotData) => (
                  <Option value={b.id} key={b.id}>
                    {b.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label={t('Bot URL')}
              name="botUrl"
              extra={t('Link to your bot')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('This field is required!'),
                },
                {
                  type: 'url',
                  message: t('This field must be a valid url!'),
                },
              ]}
            >
              <Input placeholder={t('Bot URL')} />
            </FormItem>

            <FormItem
              label={t('Campaign Source (utm_source)')}
              name="campaignSource"
              extra={t('Identify source')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('This field is required!'),
                },
              ]}
            >
              <Input placeholder={t('Campaign Source')} />
            </FormItem>

            <FormItem
              label={t('Campaign Medium (utm_medium)')}
              name="campaignMedium"
              extra={t('Marketing medium')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('This field is required!'),
                },
              ]}
            >
              <Input placeholder={t('Campaign Medium')} />
            </FormItem>

            <FormItem
              label={t('Campaign Name (utm_campaign)')}
              name="campaignName"
              extra={t('Product, promo code, or slogan')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('This field is required!'),
                },
              ]}
            >
              <Input placeholder={t('Campaign Name')} />
            </FormItem>
          </Col>

          <Col>
            <FormItem
              label={t('Campaign Term (utm_term)')}
              name="campaignTerm"
              extra={t('Identify the paid keywords')}
            >
              <Input placeholder={t('Campaign Term')} />
            </FormItem>

            <FormItem
              label={t('Campaign Content (utm_content)')}
              name="campaignContent"
              extra={t('Use to differentiate ads')}
            >
              <Input placeholder={t('Campaign Content')} />
            </FormItem>
            <FormItem
              label={`${t('Referral')} (ref)`}
              name="referral"
              extra={
                <>
                  <span>{t('Referral for some channels.')}</span>
                  <br />
                  <span>
                    {t('See more in ')}
                    <a
                      href="https://docs.onedash.cc/#/link-creator"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('our documentation')}
                    </a>
                  </span>
                </>
              }
            >
              <Input placeholder={t('Referral')} />
            </FormItem>
            <FormItem
              label={t('Referral Source (ref_source)')}
              name="referralSource"
              extra={
                <>
                  <span>{t('Referral Source for some channels.')}</span>
                  <br />
                  <span>
                    {t('See more in ')}
                    <a
                      href="https://docs.onedash.cc/#/link-creator"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('our documentation')}
                    </a>
                  </span>
                </>
              }
            >
              <Input placeholder={t('Referral Source')} />
            </FormItem>
          </Col>
        </ColsWrapper>

        <FormItem>
          <Button type="primary" htmlType="submit">
            {t('Generate link')}
          </Button>
        </FormItem>
      </Form>
      <CopyClipboardModal
        title={t('Generated link')}
        visible={modalVisible}
        text={refLink.link}
        loading={refLink.loading}
        disabled={refLink.error !== null}
        placeholder={t('Your link will appear here')}
        messageSuccess={t('Link is copied to clipboard')}
        onCancel={() => setModalVisible(false)}
      />
    </>,
    granted,
  );
};

const mapStateToProps = (store: RootState) => ({
  refLink: store.tools.refLink,
  bots: store.account.bots,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createRefLink: toolsActions.createRefLink,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkCreator);
