/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  BarChart,
  Bar,
  LineChart,
  Line,
  Legend,
  ScatterChart,
  ZAxis,
  Scatter,
  Funnel,
  FunnelChart,
  LabelList,
} from 'recharts';
import { TFunction } from 'i18next';

import { Chart, Stat, Table, Sankey } from 'components/metrics';
import { MetricsState } from 'types/metrics';
import { getChartMargins } from 'helpers/utils';
import { Metric } from 'helpers/configs/dashboard';

const getBodyByChartType = (t: TFunction, metricConfig: Metric) => {
  const chart =
    metricConfig.metricKey === 'engagement'
      ? (metricConfig.chartOptions
          ?.chart as MetricsState['metric']['engagement']['chart']['time'])
      : (metricConfig.metric as MetricsState['metric']['newUsers']).chart;

  const getLabelObject = (obj: any) => {
    if (!obj) {
      return { label: {}, rest: {} };
    }
    const { label, ...rest } = obj;
    return { label, rest };
  };

  const xAxis = getLabelObject(metricConfig?.chartOptions?.xAxis);
  const yAxis = getLabelObject(metricConfig?.chartOptions?.yAxis);

  const xAxisProps = {
    dataKey: 'date',
    label: {
      value: t('Date'),
      offset: 0,
      position: 'bottom',
      ...xAxis.label,
    },
    ...xAxis.rest,
  };
  const yAxisProps = {
    dataKey: 'count',
    label: {
      value: t('Users'),
      angle: -90,
      dy: 20,
      position: 'insideLeft',
      offset: -getChartMargins(yAxis?.rest?.dataKey || 'count', chart).left + 6,
      ...yAxis.label,
    },
    ...yAxis.rest,
  };

  switch (metricConfig?.chartOptions?.type) {
    case 'area': {
      return (
        <AreaChart
          data={chart}
          margin={getChartMargins(
            metricConfig?.chartOptions?.yAxis?.dataKey || 'count',
            chart,
          )}
          {...metricConfig?.chartOptions?.additionalChartProps}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis {...xAxisProps} />
          <YAxis {...yAxisProps} />
          <Tooltip {...metricConfig?.chartOptions?.tooltipProps} />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            fill="#8884d8"
            isAnimationActive={false}
          />
          {metricConfig?.chartOptions?.additionalChartComponents}
        </AreaChart>
      );
    }

    case 'bar': {
      return (
        <BarChart
          data={chart}
          margin={getChartMargins(
            metricConfig?.chartOptions?.yAxis?.dataKey || 'count',
            chart,
          )}
          {...metricConfig?.chartOptions?.additionalChartProps}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis {...xAxisProps} />
          <YAxis {...yAxisProps} />
          <Tooltip {...metricConfig?.chartOptions?.tooltipProps} />
          <Bar
            dataKey={yAxisProps.dataKey || 'count'}
            fill="#8884d8"
            isAnimationActive={false}
          />
          {metricConfig?.chartOptions?.additionalChartComponents}
        </BarChart>
      );
    }

    case 'daumau': {
      return (
        <LineChart
          data={chart}
          margin={getChartMargins(
            'count',
            chart?.map(rec => ({
              ...rec,
              count: Math.max(
                rec.daily as number,
                rec.monthly as number,
                rec.ratio as number,
              ),
            })),
          )}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis {...xAxisProps} />
          <YAxis
            label={{
              ...yAxisProps.label,
              offset:
                -getChartMargins(
                  'count',
                  chart?.map(rec => ({
                    ...rec,
                    count: Math.max(
                      rec.daily as number,
                      rec.monthly as number,
                      rec.ratio as number,
                    ),
                  })),
                ).left + 6,
            }}
            {...yAxisProps.dataKey}
          />
          <Tooltip
            formatter={(value: string, name: string) => {
              switch (name) {
                case 'daily':
                  return [value, 'DAU'];

                case 'monthly':
                  return [value, 'MAU'];

                case 'ratio':
                  return [value, 'DAU/MAU'];

                default:
                  return 'Unknown';
              }
            }}
          />
          <Legend
            formatter={(value: string) => {
              switch (value) {
                case 'daily':
                  return 'DAU';

                case 'monthly':
                  return 'MAU';

                case 'ratio':
                  return 'DAU/MAU';

                default:
                  return 'Unknown';
              }
            }}
          />
          <Line
            type="monotone"
            dataKey="daily"
            stroke="#8884d8"
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="monthly"
            stroke="#82ca9d"
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="ratio"
            stroke="#5192ca"
            isAnimationActive={false}
          />
        </LineChart>
      );
    }

    case 'intentStats': {
      return (
        <BarChart
          data={chart}
          margin={getChartMargins(
            'count',
            chart?.map(rec => ({
              ...rec,
              count: Math.max(rec.entered as number, rec.exit as number),
            })),
          )}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis {...xAxisProps} />
          <YAxis
            label={{
              ...yAxisProps.label,
              offset:
                -getChartMargins(
                  'count',
                  chart?.map(rec => ({
                    ...rec,
                    count: Math.max(rec.entered as number, rec.exit as number),
                  })),
                ).left + 6,
            }}
            {...yAxisProps.dataKey}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="entered" fill="#8884d8" isAnimationActive={false} />
          <Bar dataKey="exit" fill="#82ca9d" isAnimationActive={false} />
        </BarChart>
      );
    }

    case 'scatter': {
      return (
        <ScatterChart margin={getChartMargins('date', chart)}>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="hour"
            name="Hour"
            ticks={[...new Array(24).keys()]}
            tickCount={24}
            interval={0}
            allowDuplicatedCategory={false}
            label={{ value: t('Hour'), offset: 0, position: 'bottom' }}
          />
          <YAxis
            reversed
            label={{
              value: t('Weekday'),
              angle: -90,
              dy: 15,
              position: 'insideLeft',
              offset: -getChartMargins('date', chart).left + 6,
            }}
            type="category"
            dataKey="date"
            name="Day"
            interval={0}
            allowDuplicatedCategory={false}
          />
          <ZAxis type="number" dataKey="count" name="Count" range={[10, 800]} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter data={chart} fill="#8884d8" isAnimationActive={false} />
        </ScatterChart>
      );
    }

    case 'funnel': {
      return (
        <FunnelChart
          margin={{
            right: chart?.length && (chart[0].intent as string).length * 3,
          }}
        >
          <Funnel
            dataKey="count"
            nameKey="intent"
            data={chart}
            isAnimationActive={false}
          >
            <LabelList
              position="right"
              fill="#000"
              stroke="none"
              dataKey="intent"
            />
          </Funnel>
        </FunnelChart>
      );
    }

    default:
      return undefined;
  }
};

export const renderMetric = (t: TFunction, metricConfig: Metric) => {
  switch (metricConfig.metric.type) {
    case 'stat': {
      const {
        value,
      } = metricConfig.metric as MetricsState['metric']['usersTotal'];
      return (
        <Stat
          key={metricConfig.metricKey}
          metricKey={metricConfig.metricKey}
          size={metricConfig.sizeProps?.size || 3}
          value={metricConfig?.value || String(value)}
          empty={metricConfig?.empty}
          onReload={metricConfig.onReload}
          panels={metricConfig.panels}
        />
      );
    }

    case 'chart': {
      return metricConfig?.chartOptions?.type === 'sankey' ? (
        <Sankey
          key={metricConfig.metricKey}
          metricKey={metricConfig.metricKey}
          data={
            (metricConfig.metric as MetricsState['metric']['flowThroughHandler'])
              .chart
          }
          margin={{ right: 100, bottom: 5 }}
          size={metricConfig.sizeProps?.size || 1.5}
          empty={metricConfig.empty}
          onReload={metricConfig.onReload}
          panels={metricConfig.panels}
          {...metricConfig?.sizeProps}
        />
      ) : (
        <Chart
          key={metricConfig.metricKey}
          metricKey={metricConfig.metricKey}
          size={metricConfig.sizeProps?.size || 3}
          onReload={metricConfig.onReload}
          panels={metricConfig.panels}
          empty={metricConfig.empty}
          {...metricConfig?.sizeProps}
        >
          {getBodyByChartType(t, metricConfig)}
        </Chart>
      );
    }

    case 'table': {
      const {
        data,
      } = metricConfig.metric as MetricsState['metric']['messages'];
      return (
        <Table
          key={metricConfig.metricKey}
          metricKey={metricConfig.metricKey}
          data={data}
          size={metricConfig.sizeProps?.size || 3}
          onReload={metricConfig.onReload}
          panels={metricConfig.panels}
          {...metricConfig?.sizeProps}
        />
      );
    }

    default:
      return null;
  }
};
