import React, { useState, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Checkbox } from 'antd';

import { RootState } from 'reducers';
import * as accountActions from 'actions/account';
import { usePromptError } from 'helpers/hooks';
import { isTrialExpired } from 'helpers/utils';
import { plansConfig } from 'helpers/configs/plans';
import { CopyClipboardModal /* , FacebookButton */ } from 'components/ui';

import { FormItem, Section, Title, SubTitle } from '../ui';
import BotsTable from './BotsTable';
import IntercomModal from './IntercomModal';

const ContentWrapper = styled.div`
  padding: 20px 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const IntercomButton = styled(Button)`
  margin-top: 10px;
  margin-left: 20px;
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const MyBots = ({
  bots,
  userLoading,
  userData,
  createBotStatus,
  deleteBotStatus,
  createIntercomBot,
  createBot,
  deleteBot,
}: Props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [intercomModalVisible, setIntercomModalVisible] = useState(false);
  const [botName, setBotName] = useState('');
  const [anonymize, setAnonymize] = useState(true);
  const maxBots = userData.plan
    ? plansConfig.botsNumber[userData.plan].value
    : 1;
  usePromptError(createBotStatus.prompt, createBotStatus.error);
  usePromptError(deleteBotStatus.prompt, deleteBotStatus.error);

  const handleAddBot = () => {
    if (!botName) return;
    createBot(botName, anonymize);
    setModalVisible(true);
  };

  const handleCreateIntercomBot = (data: { intercomToken: string }) => {
    createIntercomBot(data.intercomToken, botName);
    setIntercomModalVisible(false);
  };

  const disabled = useMemo(
    () =>
      userLoading ||
      bots.loading ||
      bots.list.length > maxBots ||
      (userData.plan === 'demo' && isTrialExpired(userData.trialExpDate)),
    [
      bots.list.length,
      bots.loading,
      maxBots,
      userData.plan,
      userData.trialExpDate,
      userLoading,
    ],
  );

  return (
    <Section title={t('My bots')}>
      <ContentWrapper>
        <Title>{t('Create bot and token')}</Title>

        <Form layout="vertical" style={{ width: '320px' }}>
          <FormItem
            style={{ marginBottom: `${disabled ? '0px' : '10px'}` }}
            label={t('Bot name')}
            name="botName"
            hasFeedback
            rules={[
              {
                required: true,
                message: t('This field is required!'),
              },
            ]}
            help={
              `${
                bots.list.length > maxBots
                  ? `${t('You cannot add more than')} ${maxBots + 1} ${t(
                      'bots',
                    )}\n`
                  : ''
              }${
                userData.plan === 'demo' &&
                isTrialExpired(userData.trialExpDate)
                  ? t('Your trial period is over')
                  : ''
              }` || undefined
            }
          >
            <Input
              value={botName}
              placeholder={t('Bot name')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBotName(e.target.value)
              }
              disabled={disabled}
            />
          </FormItem>

          <FormItem style={{ marginBottom: 0 }}>
            <Checkbox
              disabled={disabled}
              checked={anonymize}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAnonymize(e.target.checked)
              }
            >
              {t('Anonymize all data')} (
              <a
                href="https://docs.onedash.cc"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('more information')}
              </a>
              )
            </Checkbox>
          </FormItem>

          <ButtonsWrapper>
            <FormItem style={{ marginTop: '10px' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={createBotStatus.loading}
                disabled={disabled}
                onClick={handleAddBot}
              >
                {t('Add bot')}
              </Button>
            </FormItem>

            <FormItem>
              <IntercomButton
                type="primary"
                htmlType="submit"
                onClick={() => {
                  if (!botName) return;
                  setIntercomModalVisible(true);
                }}
                loading={createBotStatus.intercomLoading}
                disabled={disabled}
              >
                {t('Add bot from Intercom')}
              </IntercomButton>
            </FormItem>
          </ButtonsWrapper>

          {/* <FacebookButton
              type="primary"
              text="Add bot from Facebook"
              callback={(res: any) => console.log(res)}
              scope="public_profile, email, user_birthday, pages_messaging"
              style={{ marginLeft: '10px' }}
              // disabled :)
            /> */}
        </Form>

        <SubTitle>{t('My bots')}</SubTitle>
        <BotsTable bots={bots} deleteBot={deleteBot} />

        <CopyClipboardModal
          title={t("New bot's token")}
          visible={modalVisible}
          text={createBotStatus.token}
          loading={createBotStatus.loading || createBotStatus.intercomLoading}
          disabled={createBotStatus.error !== ''}
          placeholder={t(
            createBotStatus.error || 'Your new bot token will appear here',
          )}
          messageSuccess={t('Token is copied to clipboard')}
          onCancel={() => setModalVisible(false)}
        />

        <IntercomModal
          disabled={disabled}
          visible={intercomModalVisible}
          onSubmit={handleCreateIntercomBot}
          onCancel={() => setIntercomModalVisible(false)}
        />
      </ContentWrapper>
    </Section>
  );
};

const mapStateToProps = (store: RootState) => ({
  createBotStatus: store.account.createBot,
  deleteBotStatus: store.account.deleteBot,
  bots: store.account.bots,
  userLoading: store.account.user.loading,
  userData: store.account.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createIntercomBot: accountActions.createIntercomBot,
      createBot: accountActions.createBot,
      deleteBot: accountActions.deleteBot,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyBots);
