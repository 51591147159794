import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AuthRoutes from 'routes/AuthRoutes';
import { Footer } from 'components/ui';

const AuthScreen = styled.div`
  height: 100vh;
  min-height: 100vh;
  text-align: center;
  background-color: #f0f2f5;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow-y: auto;
`;

const Header = styled.header`
  margin-top: 13vh;
  margin-bottom: 40px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

const LogoImg = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 12px;
`;

const LogoTitle = styled.p`
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
  color: #000000;
  margin: 0;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-bottom: 0;
  max-width: 400px;
`;

const AuthWrapper = styled.main`
  width: 304px;
  padding: 24px;
  background-color: white;
  border-radius: 18px;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Auth = () => {
  const { t } = useTranslation();

  return (
    <AuthScreen>
      <Header>
        <Logo>
          <LogoImg
            src={`${process.env.PUBLIC_URL}/logo192.png`}
            alt="OneDash logo"
          />

          <LogoTitle>OneDash</LogoTitle>
        </Logo>

        <Description>
          {t('Chatbot analytics that gives you answers')}
        </Description>
      </Header>

      <AuthWrapper>
        <AuthRoutes />
      </AuthWrapper>

      <Footer style={{ margin: '8vh 0 17vh' }} auth />
    </AuthScreen>
  );
};

export default Auth;
