import { AnyAction } from 'redux';
import { Moment } from 'moment';
import { planNames } from 'helpers/configs/plans';

// Action constants

export enum AccountActionTypeConstant {
  LOG_IN = 'LOG_IN',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = 'SIGN_UP_FAILURE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  VERIFY_USER = 'VERIFY_USER',
  VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
  LOG_OUT = 'LOG_OUT',
  GET_USER_DATA = 'GET_USER_DATA',
  GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE',
  GET_BOTS_LIST = 'GET_BOTS_LIST',
  GET_BOTS_LIST_SUCCESS = 'GET_BOTS_LIST_SUCCESS',
  GET_BOTS_LIST_FAILURE = 'GET_BOTS_LIST_FAILURE',
  CHECK_AUTH = 'CHECK_AUTH',
  CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS',
  CHECK_AUTH_FAILURE = 'CHECK_AUTH_FAILURE',
  CREATE_INTERCOM_BOT = 'CREATE_INTERCOM_BOT',
  CREATE_INTERCOM_BOT_SUCCESS = 'CREATE_INTERCOM_BOT_SUCCESS',
  CREATE_INTERCOM_BOT_FAILURE = 'CREATE_INTERCOM_BOT_FAILURE',
  CREATE_BOT = 'CREATE_BOT',
  CREATE_BOT_SUCCESS = 'CREATE_BOT_SUCCESS',
  CREATE_BOT_FAILURE = 'CREATE_BOT_FAILURE',
  DELETE_BOT = 'DELETE_BOT',
  DELETE_BOT_SUCCESS = 'DELETE_BOT_SUCCESS',
  DELETE_BOT_FAILURE = 'DELETE_BOT_FAILURE',
  SWITCH_BOT = 'SWITCH_BOT',
  UPDATE_BOT_ANONYMIZATION = 'UPDATE_BOT_ANONYMIZATION',
  UPDATE_BOT_ANONYMIZATION_SUCCESS = 'UPDATE_BOT_ANONYMIZATION_SUCCESS',
  UPDATE_BOT_ANONYMIZATION_FAILURE = 'UPDATE_BOT_ANONYMIZATION_FAILURE',
}

// Account state type

export type AccountState = {
  firstSession: boolean;
  auth: { passed: boolean; checking: boolean; error: string };
  user: {
    data: UserData;
    error: string;
    loading: boolean;
  };
  bots: {
    current: BotData | null;
    list: BotData[];
    error: string;
    loading: boolean;
  };
  logIn: {
    error: string;
    prompt: string;
  };
  signUp: {
    error: string;
    prompt: string;
  };
  resetPwd: {
    error: string;
    prompt: string;
  };
  verifyUser: {
    error: string;
    prompt: string;
  };
  updateUserData: {
    error: string;
    prompt: string;
    loading: boolean;
  };
  changePwd: {
    error: string;
    prompt: string;
  };
  createBot: {
    error: string;
    prompt: string;
    loading: boolean;
    intercomLoading: boolean;
    token: string | null;
  };
  deleteBot: {
    error: string;
    prompt: string;
    loading: boolean;
  };
};

// Asynchronous action types

export type Plan = typeof planNames[number] | null;

export type UserData = {
  name: string | null;
  company: string | null;
  email: string | null;
  image: string | null;
  plan: Plan;
  regDate: Moment | null;
  trialExpDate: Moment | null;
};

export type BotData = {
  name: string;
  id: number;
  token?: string;
  timestamp?: Moment;
  anonymized?: boolean;
};

export type LogInFormData = {
  email: string;
  password: string;
  remember: boolean;
};

export type SignUpFormData = {
  name: string;
  email: string;
  password: string;
  accepted: boolean;
  website?: string;
  promocode?: string;
};

export type ResetPasswordFormData = {
  email: string;
};

export type FBLoginResponse = {
  status?: string;
  accessToken: string;
  // eslint-disable-next-line camelcase
  data_access_expiration_time: number;
  expiresIn: number;
  grantedScopes: string;
  graphDomain: string;
  id: string;
  name: string;
  signedRequest: string;
  userID: string;
};

export type PlansDescription = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        type: string;
        granted?: boolean;
        duration?: [number, string];
        value?: number;
      };
    };
  };
};

// Action types

export interface LogIn extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface LogInSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  firstSession: boolean;
  email: string;
  prompt: string;
}

export interface LogInFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface SignUp extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface SignUpSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  email: string;
  prompt: string;
}

export interface SignUpFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface ResetPassword extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface ResetPasswordSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  prompt: string;
}

export interface ResetPasswordFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface VerifyUser extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface VerifyUserSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  prompt: string;
}

export interface VerifyUserFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface ChangePassword extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface ChangePasswordSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  prompt: string;
}

export interface ChangePasswordFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface LogOut extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface GetUserData extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface GetUserDataSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  data: string;
}

export interface GetUserDataFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface UpdateUserData extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface UpdateUserDataSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  data: string;
  prompt: string;
}

export interface UpdateUserDataFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface GetBotsList extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface GetBotsListSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  bots: BotData[];
  botInit: BotData;
}

export interface GetBotsListFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface CheckAuth extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface CheckAuthSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  firstSession: boolean;
}

export interface CheckAuthFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface CreateIntercomBot extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface CreateIntercomBotSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  prompt: string;
  botName: string;
  botId: number;
  botToken: string;
}

export interface CreateIntercomBotFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface CreateBot extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface CreateBotSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  prompt: string;
  botName: string;
  botId: number;
  botToken: string;
}

export interface CreateBotFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface DeleteBot extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface DeleteBotSuccess extends AnyAction {
  type: AccountActionTypeConstant;
  id: number;
  newCurrentBot?: BotData | null;
}

export interface DeleteBotFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export interface SwitchBot extends AnyAction {
  type: AccountActionTypeConstant;
  botId: string;
}

export interface UpdateBotAnonymization extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface UpdateBotAnonymizationSuccess extends AnyAction {
  type: AccountActionTypeConstant;
}

export interface UpdateBotAnonymizationFailure extends AnyAction {
  type: AccountActionTypeConstant;
  error: string;
}

export type AccountActionType =
  | LogIn
  | LogInSuccess
  | LogInFailure
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | VerifyUser
  | VerifyUserSuccess
  | VerifyUserFailure
  | GetUserData
  | GetUserDataSuccess
  | GetUserDataFailure
  | UpdateUserData
  | UpdateUserDataSuccess
  | UpdateUserDataFailure
  | GetBotsList
  | GetBotsListSuccess
  | GetBotsListFailure
  | CheckAuth
  | CheckAuthSuccess
  | CheckAuthFailure
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailure
  | LogOut
  | SwitchBot
  | CreateIntercomBot
  | CreateIntercomBotSuccess
  | CreateIntercomBotFailure
  | CreateBot
  | CreateBotSuccess
  | CreateBotFailure
  | DeleteBot
  | DeleteBotSuccess
  | DeleteBotFailure
  | UpdateBotAnonymization
  | UpdateBotAnonymizationSuccess
  | UpdateBotAnonymizationFailure;
