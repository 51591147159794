import { AlertManagerPanelsConfig } from 'types/tools';

const alertManagerPanelsConfig: AlertManagerPanelsConfig = [
  {
    type: 0,
    requiredFields: ['botId', 'payload'],
    items: [
      { key: 'botId', type: 'select', placeholder: 'Bot' },
      {
        key: 'text1',
        type: 'text',
        placeholder: 'received no messages for more than',
      },
      {
        key: 'payload',
        type: 'inputNum',
        placeholder: 'N',
      },
      { key: 'text2', type: 'text', placeholder: 'day(s)' },
    ],
    msg:
      'Bot {bot} has no incoming messages received for more than {payload} day(s).',
  },
  {
    type: 1,
    requiredFields: ['botId', 'ref', 'payload'],
    items: [
      {
        key: 'botId',
        type: 'select',
        placeholder: 'Bot',
      },
      {
        key: 'ref',
        type: 'select',
        placeholder: 'Referral',
        dependencies: ['botId'],
      },
      { key: 'text1', type: 'text', placeholder: 'link was used' },
      { key: 'payload', type: 'inputNum', placeholder: 'N' },
      { key: 'text2', type: 'text', placeholder: 'time(s)' },
    ],
    msg: 'The referral link {ref} was used {payload} time(s) in bot {bot}.',
  },
  {
    type: 2,
    requiredFields: ['botId', 'payload'],
    items: [
      { key: 'botId', type: 'select', placeholder: 'Bot' },
      {
        key: 'text1',
        type: 'text',
        placeholder: 'had the total number of users less than',
      },
      { key: 'payload', type: 'inputNum', placeholder: 'N' },
      { key: 'text2', type: 'text', placeholder: 'at the last day' },
    ],
    msg:
      'The total number of {bot} bot users in one day was less than {payload}.',
  },
];

export default alertManagerPanelsConfig;
