import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AccountState } from 'types/account';
import { MetricName } from 'types/metrics';
import { plansConfig } from 'helpers/configs/plans';
import { RootState } from 'reducers';

/**
 * Is metric granted to the current user's plan.
 *
 * @param metricKey metric name
 * @param plan plan name
 */
const useMetricPlan = (metricKey: MetricName) => {
  const plan = useSelector<RootState, AccountState['user']['data']['plan']>(
    state => state.account.user.data.plan,
  );
  const isGranted = useMemo(() => {
    if (plan === null || !(metricKey in plansConfig.metric)) return true;

    const currentPlan = plansConfig.metric[metricKey][plan];

    switch (currentPlan.type) {
      case 'COMMON':
        return currentPlan.granted;

      default:
        return true;
    }
  }, [metricKey, plan]);

  return isGranted;
};

export default useMetricPlan;
