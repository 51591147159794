import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';

import { AccountState } from 'types/account';
import { MetricsState } from 'types/metrics';
import { DashboardState } from 'types/dashboard';
import { ToolsState } from 'types/tools';

import account from './account';
import metrics from './metrics';
import dashboard from './dashboard';
import tools from './tools';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    account,
    metrics,
    dashboard,
    tools,
  });

export interface RootState {
  router: RouterState;
  account: AccountState;
  metrics: MetricsState;
  dashboard: DashboardState;
  tools: ToolsState;
}

export default createRootReducer;
