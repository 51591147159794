import React, { useEffect, useMemo, useRef, Fragment, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

import { Table as TableAntd, Popover, Divider, Skeleton } from 'antd';
import { grey } from '@ant-design/colors';
import { QuestionCircleFilled, LoadingOutlined } from '@ant-design/icons';

import { getIntentMessage, clearIntentMessage } from 'actions/dashboard';
import {
  getMessages,
  getMisunderstood,
  updateMetricRef,
} from 'actions/metrics';
import { arrayToCSVBlob } from 'helpers/csv';
import { RootState } from 'reducers';
import { TableData } from 'types/metrics';
import Metric, { MetricProps } from './Metric';

const { Column } = TableAntd;

const TableWrapper = styled.div`
  .ant-table {
    overflow-x: auto;
  }
`;

const QuestionIconWrapper = styled.button`
  cursor: pointer;
  margin-left: 5px;
  background-color: transparent;

  span {
    color: ${grey[0]};
    font-size: 10px;
  }
`;

type Props = {
  data?: TableData;
  pageSize?: number;
} & MetricProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Table = ({
  updMetricRef,
  data,
  pageSize,
  metricKey,
  metric,
  size,
  height,
  onReload,
  panels,
  intentMessages,
  getIntentMsg,
  clearIntentMsg,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: pageSize || 6,
    total: data?.total || data?.rows.length,
  });

  const { title, loaded } = useMemo(() => metric[metricKey], [
    metric,
    metricKey,
  ]);

  const intent = useMemo(() => metric.messages?.intent, [metric.messages]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loaded && ref.current) {
      updMetricRef(metricKey, ref);
    }
  }, [metricKey, updMetricRef, loaded]);

  const downloadCSV = () => {
    const rows = [
      data?.columns.map(c => c.title) || [],
      ...data?.rows.map(r => Object.values(r).slice(1)),
    ];

    const csvBlob = arrayToCSVBlob(rows);

    saveAs(csvBlob, `${title}.csv`);
  };

  const handleTableChange = (e: any) => {
    switch (metricKey) {
      case 'misunderstood':
        dispatch(getMisunderstood({ page: e.current - 1 }));
        break;
      case 'messages':
        dispatch(getMessages({ intent, page: e.current - 1 }));

        break;
      default:
        break;
    }
    setPagination(prevState => ({
      ...prevState,
      current: e.current,
    }));
  };

  useEffect(() => {
    setPagination(prevState => ({
      ...prevState,
      total: data?.total || data?.rows.length,
    }));
  }, [data]);

  return (
    <Metric
      metricKey={metricKey}
      type="table"
      size={size}
      height={height}
      onReload={onReload}
      panels={panels}
      download={downloadCSV}
    >
      <TableWrapper ref={ref}>
        {data && (
          <TableAntd
            onChange={handleTableChange}
            dataSource={data.rows}
            size="small"
            pagination={{
              ...pagination,
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            loading={{
              spinning: !loaded,
              indicator: <LoadingOutlined style={{ color: grey[0] }} />,
            }}
            sortDirections={['descend', 'ascend']}
          >
            {data.columns.map(col => (
              <Column
                title={t(col.title)}
                key={col.key}
                dataIndex={col.dataIndex}
                sorter={col.sorter}
                render={(text: string) => (
                  <>
                    <span style={{ wordBreak: 'break-word' }}>{text}</span>

                    {col.key === 'intent' && (
                      <Popover
                        title={t('Example bot messages')}
                        content={
                          <Skeleton
                            loading={intentMessages.loading}
                            active
                            title={false}
                            paragraph={{ rows: 4 }}
                          >
                            {intentMessages?.list?.map((msg, i) => (
                              <Fragment key={`msg-${i}`}>
                                <p style={{ marginBottom: '0' }}>{msg}</p>
                                {i + 1 < intentMessages?.list?.length && (
                                  <Divider
                                    dashed
                                    style={{ margin: '16px 0 12px' }}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </Skeleton>
                        }
                        placement="rightTop"
                        trigger="hover"
                        overlayStyle={{
                          whiteSpace: 'break-spaces',
                          maxWidth: '320px',
                        }}
                        getPopupContainer={(trigger: HTMLDivElement) =>
                          trigger.parentNode?.parentNode?.parentNode?.parentNode
                            ?.parentNode?.parentNode?.parentNode?.parentNode
                            ?.parentNode?.parentNode
                        }
                        onVisibleChange={(visible: boolean) => {
                          if (visible) {
                            getIntentMsg(text);
                          } else {
                            setTimeout(clearIntentMsg, 100);
                          }
                        }}
                      >
                        <QuestionIconWrapper>
                          <QuestionCircleFilled />
                        </QuestionIconWrapper>
                      </Popover>
                    )}
                  </>
                )}
              />
            ))}
          </TableAntd>
        )}
      </TableWrapper>
    </Metric>
  );
};

const mapStateToProps = (store: RootState) => ({
  intentMessages: store.dashboard.intentMessages,
  metric: store.metrics.metric,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updMetricRef: updateMetricRef,
      getIntentMsg: getIntentMessage,
      clearIntentMsg: clearIntentMessage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Table);
