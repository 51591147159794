/* eslint-disable camelcase */
import axios from 'axios';
import { Dispatch } from 'redux';
import {
  RefLinkData,
  ClusteringOptions,
  ToolsActionTypeConstant,
  Alert,
  AlertData,
  SearchMessagesOptions,
  MessageData,
  SummarizationData,
  SummarizationOptions,
  IntentTable,
} from 'types/tools';
import { getMetric, MetricOptions } from 'api/metrics';
import * as airtable from 'helpers/airtable';
import * as api from 'api/tools';
import moment from 'moment';

const createRefLinkSuccess = (link: string, prompt: string) => ({
  type: ToolsActionTypeConstant.CREATE_REF_LINK_SUCCESS,
  link,
  prompt,
});

const createRefLinkFailure = (error: string) => ({
  type: ToolsActionTypeConstant.CREATE_REF_LINK_FAILURE,
  error,
});

export const createRefLink = (data: RefLinkData) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: ToolsActionTypeConstant.CREATE_REF_LINK });

  const refLinkData = {
    bot_id: data.bot,
    to: data.botUrl,
    utm_source: data.campaignSource,
    utm_medium: data.campaignMedium,
    utm_campaign: data.campaignName,
    utm_content: data.campaignContent,
    utm_term: data.campaignTerm,
    ref: data.referral,
    ref_source: data.referralSource,
  };

  try {
    const {
      data: { link },
    } = await axios.post(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/ref/create`,
      refLinkData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    airtable.sendRecord({ 'Short links usage': 1 });

    dispatch(createRefLinkSuccess(link, 'Ref link is generated successfully'));
  } catch (error) {
    dispatch(createRefLinkFailure('Error!'));
  }
};

export const resetClustering = () => ({
  type: ToolsActionTypeConstant.RESET_CLUSTERING,
});

const getClusteringSuccess = (prompt: string, jobId: number | null) => ({
  type: ToolsActionTypeConstant.GET_CLUSTERING_SUCCESS,
  prompt,
  jobId,
});

const getClusteringFailure = (error: string) => ({
  type: ToolsActionTypeConstant.GET_CLUSTERING_FAILURE,
  error,
});

export const getClustering = (options: ClusteringOptions) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: ToolsActionTypeConstant.GET_CLUSTERING });

  try {
    type Response = { job_id: number; result: string };
    const { botId, datesRange, ref, channel, ...data } = options;
    const {
      data: { job_id, result },
    } = await getMetric<Response>(
      'messages/clusters',
      botId,
      {
        datesRange: {
          startDate: datesRange[0],
          endDate: datesRange[1],
        },
        ref,
        channel,
      },
      data as MetricOptions,
    );

    airtable.sendRecord({ 'Number of clusterization usage': 1 });

    if (!job_id) {
      dispatch(getClusteringFailure(result));
      return;
    }

    dispatch(
      getClusteringSuccess('Request for Clustering has been created', job_id),
    );
  } catch (error) {
    dispatch(
      getClusteringFailure(
        error.response ? error.response.data.message : error.message,
      ),
    );
  }
};

const checkClusteringSuccess = (prompt: string) => ({
  type: ToolsActionTypeConstant.CHECK_CLUSTERING_SUCCESS,
  prompt,
});

export const checkClustering = (jobId: number) => async (
  dispatch: Dispatch,
) => {
  try {
    type Response = {
      status?: 'In progress' | 'SUCCESS' | 'Failed' | 'Killed';
    };

    const { data } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/cluster/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    if (data.status === 'SUCCESS' || data.status === undefined) {
      dispatch(checkClusteringSuccess('Clustering plot is ready'));
      (window as any).Bokeh.embed.embed_item(data, 'bk-clustering');
    }
  } catch (error) {
    if (
      error?.response?.status !== 400 &&
      error?.response?.data?.status !== 'In progress'
    ) {
      dispatch(
        getClusteringFailure(
          'Error in checking the status of clustering process',
        ),
      );
    }
  }
};

export const killClusteringJob = (jobId: number) => async (
  dispatch: Dispatch,
) => {
  try {
    type Response = { status?: boolean };

    const { data } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/cluster/kill/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );
  } catch (error) {
    dispatch(getClusteringFailure('Clustering process aborted'));
  }
};

export const getClusteringActiveJob = () => async (dispatch: Dispatch) => {
  try {
    type Job = { id: number; timestamp: string; is_done: boolean | null };
    type Response = {
      result: Job[];
    };

    const {
      data: { result },
    } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/jobs`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    const activeJob: Job | undefined = result.filter(
      job => job.is_done === false,
    )[0];

    if (activeJob) {
      dispatch(
        getClusteringSuccess(
          'Your request for Clustering is still being processed',
          activeJob.id,
        ),
      );
      dispatch({ type: ToolsActionTypeConstant.GET_CLUSTERING });
    }
  } catch (error) {
    dispatch(
      getClusteringFailure(
        'Error in getting the list of active requests for Clustering',
      ),
    );
  }
};

const getAlertsSuccess = (prompt: string, data: Alert[]) => ({
  type: ToolsActionTypeConstant.GET_ALERTS_SUCCESS,
  prompt,
  data,
});

const getAlertsFailure = (error: string) => ({
  type: ToolsActionTypeConstant.GET_ALERTS_FAILURE,
  error,
});

export const getAlerts = () => async (dispatch: Dispatch) => {
  dispatch({ type: ToolsActionTypeConstant.GET_ALERTS });

  try {
    type Response = {
      result: (Alert & { bot: number })[];
    };

    const {
      data: { result },
    } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/alerts`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    dispatch(
      getAlertsSuccess(
        'Alerts data is fetched successfully',
        result.map(r => ({ ...r, botId: r.bot })),
      ),
    );
  } catch (error) {
    dispatch(getAlertsFailure('Error occurred in fetching the Alerts data'));
  }
};

const createAlertSuccess = (prompt: string, data: Alert) => ({
  type: ToolsActionTypeConstant.CREATE_ALERT_SUCCESS,
  prompt,
  data,
});

const createAlertFailure = (error: string) => ({
  type: ToolsActionTypeConstant.CREATE_ALERT_FAILURE,
  error,
});
type CreateResponse = {
  id: number;
};
export const createAlert = (alertData: AlertData) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: ToolsActionTypeConstant.CREATE_ALERT });

  try {
    const {
      data: { id },
    } = await api.createAlert<CreateResponse>(alertData);

    dispatch(
      createAlertSuccess('Alert is activated', {
        ...alertData,
        id,
        status: 1,
        timestamp: moment().format('YYYY-MM-DD HH:mm'),
      }),
    );
  } catch (error) {
    dispatch(createAlertFailure('Error occurred in activation the alert'));
  }
};

const deleteAlertSuccess = (prompt: string, id: number) => ({
  type: ToolsActionTypeConstant.DELETE_ALERT_SUCCESS,
  prompt,
  id,
});

const deleteAlertFailure = (error: string) => ({
  type: ToolsActionTypeConstant.DELETE_ALERT_FAILURE,
  error,
});

type DeleteResponse = {
  message: string;
};

export const deleteAlert = (id: number) => async (dispatch: Dispatch) => {
  dispatch({ type: ToolsActionTypeConstant.DELETE_ALERT });

  try {
    const { data } = await api.deleteAlert<DeleteResponse>(id);

    dispatch(deleteAlertSuccess(data.message, id));
  } catch (error) {
    dispatch(deleteAlertFailure('Error occurred in deleting the alert'));
  }
};

const editAlertSuccess = (prompt: string, oldID: number, alert: Alert) => ({
  type: ToolsActionTypeConstant.EDIT_ALERT_SUCCESS,
  prompt,
  oldID,
  data: alert,
});

const editAlertFailure = (error: string) => ({
  type: ToolsActionTypeConstant.EDIT_ALERT_FAILURE,
  error,
});

export const editAlert = (oldID: number, alertData: AlertData) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: ToolsActionTypeConstant.EDIT_ALERT });

  try {
    await api.deleteAlert<DeleteResponse>(oldID);
    const {
      data: { id },
    } = await api.createAlert<CreateResponse>(alertData);

    dispatch(
      editAlertSuccess('Alert configuration is edited', oldID, {
        ...alertData,
        id,
        status: 1,
        timestamp: moment().format('YYYY-MM-DD HH:mm'),
      }),
    );
  } catch (error) {
    dispatch(
      editAlertFailure('Error occurred in editing the alert configuration'),
    );
  }
};

const getMessagesSuccess = (data: MessageData[]) => ({
  type: ToolsActionTypeConstant.GET_USERS_MESSAGES_SUCCESS,
  data,
});

const getMessagesFailure = (error: string) => ({
  type: ToolsActionTypeConstant.GET_USERS_MESSAGES_FAILURE,
  error,
});

export const getMessages = (options: SearchMessagesOptions) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: ToolsActionTypeConstant.GET_USERS_MESSAGES });

  try {
    type Response = {
      result: {
        intent_name: string;
        message: string;
        timestamp: string;
        user_id: string;
      }[];
    };
    const { botId, datesRange, ref, channel, ...data } = options;
    const {
      data: { result },
    } = await getMetric<Response>(
      'messages/search',
      botId,
      {
        datesRange: {
          startDate: datesRange[0],
          endDate: datesRange[1],
        },
        ref,
        channel,
      },
      data as MetricOptions,
    );

    dispatch(
      getMessagesSuccess(
        result.map((message, index: number) => ({
          key: index,
          intent: message.intent_name,
          message: message.message,
          timeStamp: message.timestamp,
          userId: message.user_id,
        })),
      ),
    );
  } catch (error) {
    dispatch(
      getMessagesFailure(
        error.response
          ? error.response?.data?.message || error.response?.statusText
          : error.message,
      ),
    );
  }
};

const getMisunderstoodSumSuccess = (data: SummarizationData[]) => ({
  type: ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM_SUCCESS,
  data,
});

const getMisunderstoodSumFailure = (error: string) => ({
  type: ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM_FAILURE,
  error,
});

export const getMisunderstoodSum = (options: SummarizationOptions) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ToolsActionTypeConstant.GET_MISUNDERSTOOD_SUM });

    try {
      type Response = {
        result: string[];
      };

      const { botId, datesRange, ref, channel } = options;

      const {
        data: { result },
      } = await getMetric<Response>('messages/miss/summarize', botId, {
        datesRange: {
          startDate: datesRange[0],
          endDate: datesRange[1],
        },
        ref,
        channel,
      });

      dispatch(
        getMisunderstoodSumSuccess(
          result.map((message, index: number) => ({
            key: index,
            message,
          })),
        ),
      );
    } catch (error) {
      dispatch(getMisunderstoodSumFailure('Error!'));
    }
  };
};

const getIntentSlugSuccess = (data: IntentTable) => ({
  type: ToolsActionTypeConstant.GET_INTENT_SLUG_SUCCESS,
  data,
});

const getIntentSlugFailure = (error: string) => ({
  type: ToolsActionTypeConstant.GET_INTENT_SLUG_FAILURE,
  error,
});

export const getIntentSlug = (botId: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ToolsActionTypeConstant.GET_INTENT_SLUG });

    try {
      type Response = {
        result: { intent: string; slug: string; messages: string[] }[];
      };

      const {
        data: { result },
      } = await getMetric<Response>('intent/slug', botId, undefined, {
        message_count: 1,
      });

      dispatch(
        getIntentSlugSuccess(
          result.map((field, index: number) => ({
            key: index,
            intent: field.intent,
            slug: field.slug,
            messages: field.messages,
          })),
        ),
      );
    } catch (error) {
      dispatch(getIntentSlugFailure(error));
    }
  };
};

export const clearSlugTable = () => (dispatch: Dispatch) => {
  dispatch({ type: ToolsActionTypeConstant.DELETE_INTENT_TABLE });
};

const renameIntentSuccess = (prompt: string) => ({
  type: ToolsActionTypeConstant.RENAME_INTENT_SUCCESS,
  prompt,
});

const renameIntentFailure = (error: string) => ({
  type: ToolsActionTypeConstant.RENAME_INTENT_FAILURE,
  error,
});

export const renameIntent = (
  newIntentName: string,
  oldIntentName: string,
  botId: number,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: ToolsActionTypeConstant.RENAME_INTENT });

    try {
      type Response = {
        result: string;
      };

      const {
        data: { result },
      } = await getMetric<Response>('intent/rename', botId, undefined, {
        intent: newIntentName,
        from_intent: oldIntentName,
      });

      dispatch(renameIntentSuccess(`${oldIntentName} successfully updated`));
    } catch (error) {
      dispatch(renameIntentFailure('Error!'));
    }
  };
};
