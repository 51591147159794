import { Filter, DatesRange } from 'types/dashboard';
import axios from 'axios';
import { getMetric } from './metrics';

type RefsListFilter = {
  ref?: string;
  datesRange?: DatesRange;
};

export const loadRefsList = async (
  botId: number,
  filter: RefsListFilter,
  sharingToken?: string,
) => {
  type Response = {
    result: { ref: string; count: string }[];
  };

  const {
    data: { result },
  } = await getMetric<Response>(
    'refs',
    botId,
    {
      datesRange: filter.datesRange,
    },
    undefined,
    sharingToken,
  );

  return result;
};

export const loadChannelList = async (
  botId: number,
  filter: RefsListFilter,
  sharingToken?: string,
) => {
  type Response = {
    result: string[];
  };

  const {
    data: { result },
  } = await getMetric<Response>(
    'channels',
    botId,
    {
      datesRange: filter.datesRange,
    },
    undefined,
    sharingToken,
  );

  return result;
};

export const loadIntentsList = async (
  botId: number,
  filter: Filter,
  sharingToken?: string,
) => {
  type Response = {
    result: { intent: string; count: string }[];
  };

  const {
    data: { result },
  } = await getMetric<Response>(
    'intents',
    botId,
    filter,
    undefined,
    sharingToken,
  );

  return result;
};

export const getDashboardShareToken = async (id: number) => {
  type Response = {
    result: string;
  };

  const {
    data: { result },
  } = await axios.post<Response>(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/dashboard/link`,
    { id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
  );

  return result;
};

export const checkDashboardShareToken = async (token: string) => {
  type Response = {
    id?: number;
    name?: string;
    message?: string;
  };

  const {
    data: { id, name, message },
  } = await axios.get<Response>(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/dashboard/${token}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
  );

  return { id, name } || message;
};
