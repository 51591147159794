import axios from 'axios';

export const checkPromocode = async (promocode: string) => {
  try {
    type Response = {
      result: string;
    };

    const {
      data: { result },
    } = await axios.get<Response>(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/user/promocode/check/${promocode}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    );

    return result === 'Correct promo code';
  } catch (error) {
    return null;
  }
};
