import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

const usePromptError = (
  prompt: string | null,
  error: string | null,
  active = true,
) => {
  const firstRender = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!firstRender.current && active) {
      if (prompt) {
        message.success(t(prompt));
      }

      if (error) {
        message.error(t(error));
      }
    }

    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [active, error, prompt, t]);
};

export default usePromptError;
