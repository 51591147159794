import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import LogIn from 'components/auth/LogIn';
import SignUp from 'components/auth/SignUp';
import PasswordReset from 'components/auth/PasswordReset';
import NewPassword from 'components/auth/NewPassword';
import Verification from 'components/auth/Verification';

const AuthRoutes = () => (
  <Switch>
    <Redirect exact from="/auth" to="/auth/login" />

    <Route path="/auth/login" component={LogIn} />
    <Route path="/auth/register" component={SignUp} />
    <Route path="/auth/reset-password" component={PasswordReset} />
    <Route path="/auth/new-password" component={NewPassword} />
    <Route path="/auth/verification" component={Verification} />

    <Redirect push from="*" to="/auth" />
  </Switch>
);

export default AuthRoutes;
