import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Layout, Tooltip, Button as ButtonAntd, Skeleton, Badge } from 'antd';
import {
  FilterOutlined,
  ReloadOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { gold, grey } from '@ant-design/colors';

import {
  Dropdown,
  CopyClipboardModal,
  showFeatDeniedModal,
} from 'components/ui';
import { RootState } from 'reducers';
import * as dashboardActions from 'actions/dashboard';
import { getDashboardShareToken } from 'api/dashboard';
import {
  plansConfig,
  CommonPermission,
  TimeLimitPermission,
} from 'helpers/configs/plans';

const { Header: HeaderAnt } = Layout;

const HeaderWrapper = styled(HeaderAnt)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  background-color: transparent;
  height: 56px;
  margin-bottom: 16px;
`;

const OptionsPanel = styled.div`
  display: flex;
  margin-left: 40px;
  line-height: 0px;
`;

const Button = styled(ButtonAntd)`
  &.ant-btn {
    border: 0;
    outline: 0;
    border-radius: 6px;
    color: ${props => (props.active === 'true' ? gold[5] : grey[6])};
    background-color: transparent;
    box-shadow: none;

    ${props =>
      props.disabled
        ? css`
            color: ${grey[2]};
          `
        : css`
            :hover {
              color: ${gold[5]};
              border-color: ${gold[5]};
            }
          `}
  }
`;

const ButtonPDF = styled(Button)`
  &.ant-btn {
    margin-left: 10px;
    border: 1px solid ${gold[5]};

    &.ant-btn-loading {
      cursor: wait;

      ::before {
        background-color: transparent;
      }

      span {
        color: ${gold[5]};
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;

  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  color: #000000;
`;

const withTooltip = (
  button: JSX.Element,
  title: string,
  enabled: boolean = true,
) =>
  enabled ? (
    button
  ) : (
    <Tooltip
      title={title}
      getPopupContainer={(triggerNode: HTMLDivElement) =>
        triggerNode.parentNode
      }
    >
      {button}
    </Tooltip>
  );

type Props = {
  loadMetrics: Function;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Header = ({
  sharingToken,
  metric,
  userPlan,
  currentDashboard,
  dbDownload,
  filter,
  currentBot,
  loadMetrics,
  filterPanelHidden,
  downloadPDF,
  downloadCSV,
  downloadFullCSV,
  toggleFilterPanel,
}: Props) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [sharingLink, setSharingLink] = useState<string | null>(null);
  const [reloadMetricsInterval, setReloadMetricsInterval] = useState<number>();
  const [reloadButtonBadge, setReloadButtonBadge] = useState('');

  const datesDuration = useMemo(
    () =>
      filter.datesRange.endDate.diff(filter.datesRange.startDate, 'days') + 1,
    [filter.datesRange.endDate, filter.datesRange.startDate],
  );

  const cvsInterval = useMemo(
    () =>
      (plansConfig.reports.csv[userPlan || 'essential'] as TimeLimitPermission)
        .interval,
    [userPlan],
  );

  const timeKeyToDays = (timeKey: string) => {
    switch (timeKey) {
      case 'd':
        return 1;
      case 'w':
        return 7;
      case 'm':
        return 30;
      case 'y':
        return 365;

      default:
        return 1;
    }
  };

  const isCSVDenied = useMemo(
    () => datesDuration > cvsInterval[0] * timeKeyToDays(cvsInterval[1]),
    [cvsInterval, datesDuration],
  );

  const downloadByPlan = (downloadFunc: typeof downloadCSV) => {
    if ((userPlan === 'demo' || userPlan === 'essential') && isCSVDenied)
      showFeatDeniedModal(
        'You cannot download data for more than 1 month dates range on Essential account',
      );
    else if (userPlan === 'pro' && isCSVDenied)
      showFeatDeniedModal(
        'You cannot download data for more than 5 months dates range on Pro account',
      );
    else
      downloadFunc(
        metric,
        currentDashboard,
        currentBot?.name,
        filter.datesRange,
      );
  };

  const getMetricsWithIntents = useCallback(
    () =>
      Object.entries(metric).reduce(
        (
          prevIntents: { [key: string]: string },
          [metricKey, metricData]: [string, any],
        ) =>
          metricData.boards.includes(currentDashboard?.key) &&
          ('intent' in metricData || 'intents' in metricData) &&
          (metricData.intent || metricData.intents?.length)
            ? {
                ...prevIntents,
                [metricKey]:
                  metricKey === 'funnel'
                    ? metricData?.intents
                    : metricData?.intent,
              }
            : prevIntents,
        {},
      ),
    [currentDashboard, metric],
  );

  const shareDashboard = async () => {
    if (!currentBot) return;
    const { datesRange, ref } = filter;

    const token = await getDashboardShareToken(currentBot.id);
    const metricsWithIntents = getMetricsWithIntents();

    const urlParams = new URLSearchParams({
      token,
      startDate: datesRange.startDate.format('YYYY-MM-DD'),
      endDate: datesRange.endDate.format('YYYY-MM-DD'),
      ...(currentDashboard !== null ? { dashboard: currentDashboard.key } : {}),
      ...(ref !== undefined ? { ref: ref.toString() } : {}),
      ...metricsWithIntents,
    });

    setSharingLink(
      `${window.location.origin}${
        window.location.pathname
      }#/share?${urlParams.toString()}`,
    );
    setModalVisible(true);
  };

  const refreshInterval = useCallback(
    (time: number) => {
      setReloadButtonBadge(time ? `${time} min${time > 1 ? 's' : ''}` : '');
      clearInterval(reloadMetricsInterval);
      localStorage.setItem('refreshInterval', JSON.stringify(time));
      if (!time) {
        loadMetrics();
        return;
      }
      setReloadMetricsInterval(setInterval(loadMetrics, time * 60000));
    },
    [loadMetrics, reloadMetricsInterval],
  );

  useEffect(() => {
    const interval = Number(localStorage.getItem('refreshInterval'));
    if (interval && interval !== 0) {
      refreshInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderWrapper>
      <TitleWrapper>
        {currentDashboard === null ? (
          <Skeleton.Input active style={{ display: 'flex', width: '300px' }} />
        ) : (
          <Trans>{currentDashboard.name}</Trans>
        )}
      </TitleWrapper>

      <OptionsPanel>
        <Tooltip
          title={t('Show/Hide filter panel')}
          getPopupContainer={(triggerNode: HTMLDivElement) =>
            triggerNode.parentNode
          }
        >
          <Button
            type="default"
            size="large"
            icon={<FilterOutlined />}
            onClick={toggleFilterPanel}
            active={(!filterPanelHidden).toString()}
          />
        </Tooltip>

        <Dropdown
          placement="bottomCenter"
          trigger={['click']}
          overlayItems={[
            {
              key: 'once',
              content: t('Once'),
              onClick: () => {
                refreshInterval(0);
              },
            },
            {
              key: '1min',
              content: t('Every 1 min'),
              onClick: () => refreshInterval(1),
            },
            {
              key: '5min',
              content: t('Every 5 min'),
              onClick: () => refreshInterval(5),
            },
            {
              key: '15min',
              content: t('Every 15 min'),
              onClick: () => refreshInterval(15),
            },
          ]}
        >
          <Tooltip
            title={t('Reload all metrics')}
            trigger={['hover', 'click']}
            getPopupContainer={(triggerNode: HTMLDivElement) =>
              triggerNode.parentNode
            }
          >
            <Badge
              count={t(reloadButtonBadge)}
              size="small"
              offset={[-6, 6]}
              style={{
                fontSize: '11px',
                padding: '0 2px',
                height: '11px',
                lineHeight: '11px',
              }}
            >
              <Button type="default" size="large" icon={<ReloadOutlined />} />
            </Badge>
          </Tooltip>
        </Dropdown>

        {withTooltip(
          <Button
            type="default"
            size="large"
            icon={<ShareAltOutlined />}
            onClick={shareDashboard}
            disabled={
              sharingToken !== undefined ||
              !(plansConfig.reports.sharing[
                userPlan || 'essential'
              ] as CommonPermission).granted
            }
          />,
          t('Share the dashboard'),
          sharingToken !== undefined,
        )}

        <Dropdown
          placement="bottomCenter"
          trigger={['click']}
          overlayItems={[
            {
              key: 'pdf',
              content: t('Generative PDF report'),
              disabled: (plansConfig.reports.pdf[
                userPlan || 'essential'
              ] as CommonPermission).granted,
              onClick: () =>
                downloadPDF(
                  metric,
                  currentDashboard,
                  currentBot?.name,
                  filter.datesRange,
                ),
            },
            {
              key: 'csv',
              content: t('All-in-one CSV file'),
              warning: isCSVDenied,
              onClick: () => downloadByPlan(downloadCSV),
            },
            {
              key: 'csv-full',
              content: t('Raw data CSV archive'),
              warning: isCSVDenied,
              onClick: () => downloadByPlan(downloadFullCSV),
            },
          ]}
        >
          <Tooltip
            title={t('Download dashboard')}
            trigger={['hover', 'click']}
            getPopupContainer={(triggerNode: HTMLDivElement) =>
              triggerNode.parentNode
            }
          >
            <ButtonPDF
              type="default"
              size="large"
              icon={<DownloadOutlined style={{ color: gold[5] }} />}
              loading={dbDownload.loading}
            />
          </Tooltip>
        </Dropdown>
      </OptionsPanel>

      <CopyClipboardModal
        title={t('Sharing link')}
        visible={modalVisible}
        text={sharingLink}
        placeholder={t('Your link will appear here')}
        messageSuccess={t('Link is copied to clipboard')}
        onCancel={() => setModalVisible(false)}
      />
    </HeaderWrapper>
  );
};

const mapStateToProps = (store: RootState) => ({
  metric: store.metrics.metric,
  sharingToken: store.dashboard.sharingToken,
  userPlan: store.account.user.data.plan,
  currentBot: store.account.bots.current,
  filter: store.dashboard.filter,
  dbDownload: store.dashboard.dbDownload,
  currentDashboard: store.dashboard.current,
  filterPanelHidden: store.dashboard.filterPanelHidden,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      downloadPDF: dashboardActions.downloadPDF,
      downloadCSV: dashboardActions.downloadCSV,
      downloadFullCSV: dashboardActions.downloadFullCSV,
      toggleFilterPanel: dashboardActions.toggleFilterPanel,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
