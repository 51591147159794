import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { changePassword } from 'actions/account';
import { RootState } from 'reducers';
import { ResponseMsgBox } from 'components/ui';

import { FormField, HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const NewPassword = ({
  error,
  prompt,
  changePwd,
  replaceUrl,
  search,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');

    if (token !== null) {
      changePwd(token);
    } else {
      replaceUrl('/auth/login');
    }
  }, [changePwd, replaceUrl, search]);

  const handleSubmit = ({ password }: { password: string }) => {
    const token = new URLSearchParams(search).get('token');

    if (token !== null) {
      changePwd(token, password);
    }
  };

  return (
    <>
      <Title>{t('Enter your new password')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <Form onFinish={handleSubmit}>
        <FormField
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter a new password'),
            },
            { min: 8, message: t('Password must be at least 8 symbols') },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('New password')}
          />
        </FormField>

        <FormField
          name="passwordRepeat"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please repeat your new password'),
            },
            { min: 8, message: t('Password must be at least 8 symbols') },
            ({ getFieldValue }: { getFieldValue: Function }) => ({
              validator(rule: string, value: string) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('Repeat new password')}
          />
        </FormField>

        <FormField>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            style={{ borderRadius: '4px' }}
            disabled={error !== ''}
          >
            {t('Change password')}
          </Button>
        </FormField>
      </Form>

      <HelpMessage>
        {`${t('If you have any problems, please')} `}
        <a href="mailto:onedash@eora.ru">{t('contact us')}</a>
      </HelpMessage>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  search: store.router.location.search,
  error: store.account.changePwd.error,
  prompt: store.account.changePwd.prompt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      replaceUrl: replace,
      changePwd: changePassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
