import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { gold } from '@ant-design/colors';
import { WarningOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Wrapper = styled(Header)`
  display: flex;
  height: 88px;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: ${gold[5]};
  padding: 0 20px;

  color: white;
`;

const WarningIcon = styled(WarningOutlined)`
  font-size: 35px;
  margin-right: 20px;
`;

const Message = styled.div`
  h3 {
    color: inherit;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 8px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }

  a {
    color: inherit;
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }
`;
const AttentionPanel = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <WarningIcon />

      <Message>
        <h3>{t('Your trial period is over!')}</h3>
        <p>
          {t('Please, go to the ')}
          <Link to="/settings/account">{t('Account settings')}</Link>
          {t(' and expand your subscription.')}
        </p>
      </Message>
    </Wrapper>
  );
};

export default AttentionPanel;
