import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';

import Dashboards from 'components/sections/dashboards/Dashboards';
import FixedPanel from 'components/home/fixed-panel';
import { HomeLayoutWrapper, Footer } from 'components/ui';

import * as dashboardActions from 'actions/dashboard';
import * as accountActions from 'actions/account';

import { checkDashboardShareToken } from 'api/dashboard';
import { RootState } from 'reducers';
import { history } from 'configureStore';

const Wrapper = styled.main`
  height: 100vh;
  overflow-y: auto;
`;

const Content = styled(HomeLayoutWrapper)`
  min-height: 100%;
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Share = ({
  user,
  bots,
  sharingToken,
  auth,
  dashboards,
  search,
  getUserData,
  switchBot,
  updateRef,
  updateChannel,
  setBotsList,
  setCurrentDashboard,
  setSharingToken,
  logOut,
}: Props) => {
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const [botData, setBotData] = useState<{ id?: number; name?: string } | null>(
    null,
  );

  // set dashboard, sharing token, botData from URL params, check token and redirect if error
  useEffect(() => {
    if (
      !urlParams.has('dashboard') ||
      !urlParams.has('token') ||
      dashboards[urlParams.get('dashboard') || 0] === undefined
    ) {
      history.push('/');
    }

    setCurrentDashboard(urlParams.get('dashboard'));
    checkDashboardShareToken(urlParams.get('token') || '')
      .then(res => {
        if (typeof res === 'string') return;
        setSharingToken(urlParams.get('token') || '');
        setBotData(res);
      })
      .catch(() => {
        history.push('/');
      });

    return () => {
      setSharingToken(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set current bot
  useEffect(() => {
    if (
      sharingToken !== undefined &&
      botData?.id !== undefined &&
      botData?.name !== undefined
    ) {
      const bot = { id: botData.id, name: botData.name };
      setBotsList([bot], bot);
    }
  }, [botData, sharingToken, setBotsList]);

  // set user data if logged in
  useEffect(() => {
    if (auth.passed) {
      getUserData();
    }
  }, [auth.passed, getUserData]);

  return (
    <Wrapper>
      <Content>
        <FixedPanel
          botPanel={{
            bots,
            switchBot: (botId: string) => {
              switchBot(botId);
              updateRef();
              updateChannel();
            },
          }}
          accountPanel={{
            user,
            auth,
            logOut,
          }}
        />

        <Dashboards />

        <Footer style={{ marginTop: '16px' }} />
      </Content>
    </Wrapper>
  );
};

const mapStateToProps = (store: RootState) => ({
  search: store.router.location.search,
  dashboards: store.dashboard.list,
  user: store.account.user,
  bots: store.account.bots,
  auth: store.account.auth,
  sharingToken: store.dashboard.sharingToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logOut: accountActions.logOut,
      setBotsList: accountActions.getBotsListSuccess,
      switchBot: accountActions.switchBot,
      getUserData: accountActions.getUserData,
      updateRef: dashboardActions.updateRef,
      updateChannel: dashboardActions.updateChannel,
      setCurrentDashboard: dashboardActions.setCurrentDashboard,
      setSharingToken: dashboardActions.setSharingToken,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Share);
