import { useMediaQuery } from 'helpers/hooks';
import React, { useCallback, } from 'react';
import { Layer, Rectangle, Tooltip, Sankey as SankeyChart } from 'recharts';
import { SankeyData } from 'types/metrics';

import Chart from './Chart';
import { MetricProps } from './Metric';

type SankeyNodeLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  payload: {
    name: string;
  };
  containerWidth: number;
};

const RenderSankeyLabeledNode = ({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth,
}: SankeyNodeLabelProps) => {
  const isOut = x + width + 6 > containerWidth;
  const laptop = useMediaQuery('(max-width: 1400px)');
  const tablet = useMediaQuery('(max-width: 1024px)');
  const fontSizeFromScreen = useCallback(() => {
    if (tablet) return 8;
    if (laptop) return 10;
    return 14;
  }, [laptop, tablet]);

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="#5192ca"
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 4}
        fontSize={fontSizeFromScreen()}
        stroke="#333"
      >
        {payload.name}
      </text>
    </Layer>
  );
};

type Props = {
  data?: SankeyData;
  margin: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
} & MetricProps;

const Sankey = ({
  metricKey,
  data,
  margin,
  empty,
  size,
  onReload,
  panels,
}: Props) => {
  return (
    <Chart
      metricKey={metricKey}
      empty={empty}
      size={size}
      onReload={onReload}
      panels={panels}
    >
      {data && data.nodes.length > 0 ? (
        <SankeyChart
          node={(props: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RenderSankeyLabeledNode {...props} />
          )}
          nodePadding={20}
          margin={margin}
          data={data}
        >
          <Tooltip />
        </SankeyChart>
      ) : (
        undefined
      )}
    </Chart>
  );
};

export default Sankey;
