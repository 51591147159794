/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Modal, Space } from 'antd';

import {
  MetricPanel,
  ActionButton,
  MetricPanelOptionsSelected,
} from 'types/metrics';
import { RootState } from 'reducers';
import { renderMetricPanel, renderMetricActionButton } from 'helpers/renderers';

const MetricFullWrapper = styled(Modal)`
  width: auto !important;
  min-width: 400px;
  padding-bottom: 0px;

  .ant-modal-body {
    padding: 16px 24px;

    display: flex;
    flex-direction: column;
  }

  .ant-modal-footer {
    padding: 14px 24px 20px;
  }
`;

const Panels = styled(Space)`
  margin-bottom: 12px;
`;

const MetricWrapper = styled.main<{ type: string }>`
  ${props => {
    switch (props.type) {
      case 'chart':
        return css`
          width: 71vw;
          min-height: 35vh;
          & > div {
            min-height: 35vh;
            width: 100%;
          }
        `;

      case 'stat':
        return css`
          padding: 20px 0;
        `;

      case 'table':
        return css`
          max-width: 60vw;
        `;

      default:
        return '';
    }
  }}
`;

type Props = {
  title: string;
  type: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  metric: ReactNode;
  panels?: MetricPanel[];
  actionButtons?: ActionButton[];
  onReload: (options?: MetricPanelOptionsSelected | null) => void;
} & ReturnType<typeof mapStateToProps>;

const MetricFull = ({
  title,
  type,
  visible,
  setVisible,
  metric,
  panels,
  actionButtons,
  filterInit,
  onReload,
}: Props) => {
  return (
    <MetricFullWrapper
      title={title}
      visible={visible}
      footer={actionButtons?.map(btn => renderMetricActionButton(btn))}
      onCancel={() => setVisible(false)}
      centered
      destroyOnClose
    >
      <Panels>
        {panels?.map(panel => renderMetricPanel(panel, onReload))}
      </Panels>

      <MetricWrapper type={type}>{metric}</MetricWrapper>
    </MetricFullWrapper>
  );
};

const mapStateToProps = (store: RootState) => ({
  filterInit: {
    referral: store.dashboard.filter.ref,
    datesRange: store.dashboard.filter.datesRange,
    refs: store.dashboard.refs,
  },
});

export default connect(mapStateToProps)(MetricFull);
