import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  DashboardOutlined,
  MailOutlined,
} from '@ant-design/icons';

import SettingsRoutes from 'routes/SettingsRoutes';
import { MenuItemLink as Link } from 'components/ui';

import { Section } from '../ui';

const { Sider, Content } = Layout;

const SettingPage = styled(Content)`
  padding: 20px 24px;
`;

const Settings = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const [menuItem, setMenuItem] = useState('personal');

  useEffect(() => {
    setMenuItem(prev => pathname.split('/')[2] || prev);
  }, [pathname]);

  return (
    <Section title="Settings">
      <Sider theme="light" style={{ width: '200px' }}>
        <Menu
          theme="light"
          selectedKeys={[menuItem]}
          mode="inline"
          style={{ height: '100%', padding: '16px 0' }}
        >
          <Menu.Item key="account">
            <Link to={`${url}/account`}>
              <SettingOutlined />
              <span>{t('Account settings')}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="personal">
            <Link to={`${url}/personal`}>
              <UserOutlined />
              <span>{t('Personal details')}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="users" disabled>
            <UserAddOutlined />
            <span>{t('Users')}</span>
          </Menu.Item>

          <Menu.Item key="groups" disabled>
            <UsergroupAddOutlined />
            <span>{t('Groups')}</span>
          </Menu.Item>

          <Menu.Item key="dashboards" disabled>
            <DashboardOutlined />
            <span>{t('Dashboards')}</span>
          </Menu.Item>

          <Menu.Item key="newsletters" disabled>
            <MailOutlined />
            <span>{t('Newsletters')}</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <SettingPage>
        <SettingsRoutes />
      </SettingPage>
    </Section>
  );
};

export default Settings;
