import styled from 'styled-components';
import { grey } from '@ant-design/colors';

const HelpMessage = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${grey[7]};
  margin-bottom: 0;
  font-weight: normal;
`;

export default HelpMessage;
