import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Layout, Spin, notification, message } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';
import replaceToArr from 'string-replace-to-array';

import { isTrialExpired } from 'helpers/utils';
import alertsConfig from 'helpers/configs/alerts';
import { RootState } from 'reducers';

import * as accountActions from 'actions/account';
import * as toolsActions from 'actions/tools';
import * as dashboardActions from 'actions/dashboard';

import HomeRoutes from 'routes/HomeRoutes';
import { Footer, HomeLayoutWrapper } from 'components/ui';
import { checkAlerts } from 'api/tools';
import { Alert } from 'types/tools';
import { BotData } from 'types/account';

import { useSectionPlan } from 'helpers/hooks';
import Sidebar from './Sidebar';
import AttentionPanel from './AttentionPanel';
import Tutorial from './tutorial';
import FixedPanel from './fixed-panel';

const AlertsWrapper = styled.div`
  .ant-notification {
    margin-right: 20px !important;
  }

  .ant-notification-notice {
    border-radius: 15px;
  }

  .ant-notification-notice-message {
    font-weight: 600;
  }
`;

const Spinner = styled(Spin)`
  max-height: 100% !important;
`;

const AlertTimestamp = styled.i`
  display: inline-block;
  margin-top: 15px;
  font-size: 12px;
`;

const NUM_MINS_ALERTS = 5;

const shorten = (line: string) =>
  line.length > 24 ? `${line.slice(0, 24)}...` : line;

const getAlertDescription = (alert: Alert, botsList: BotData[]) => {
  const alertConfig = alertsConfig.filter(a => a.type === alert.type)[0];
  const botData: BotData | undefined = botsList.filter(
    b => b.id === alert.botId,
  )[0];

  return [
    ...replaceToArr(
      alertConfig.msg,
      /({bot})|({ref})|({payload})/g,
      (fullMatch: string, bot: string, ref: string, payload: string) => {
        if (bot) return <b key="bot">{botData?.name || 'Unknown'}</b>;
        if (ref)
          return (
            <b key="ref" title={alert.ref}>
              {shorten(alert.ref || 'Unknown')}
            </b>
          );
        if (payload) return <b key="payload">{alert.payload.toString()}</b>;
        return '';
      },
    ),
    <br key="br" />,
    <AlertTimestamp key="timestamp">{alert.timestamp}</AlertTimestamp>,
  ];
};

const handleAlert = async (
  container: HTMLDivElement | null,
  botsList: BotData[],
) => {
  try {
    notification.destroy();
    const alertMessages = await checkAlerts();

    if (alertMessages.length > 0) {
      alertMessages.forEach(alert =>
        notification.open({
          message: <Trans>Alert</Trans>,
          description: getAlertDescription(alert, botsList).map(text =>
            text instanceof Object ? text : <Trans key={text}>{text}</Trans>,
          ),
          icon: <AlertOutlined style={{ color: gold[5] }} />,
          duration: NUM_MINS_ALERTS * 60 - 1,
          getContainer: () => (container === null ? document.body : container),
          top: 88,
        }),
      );
    }
  } catch (error) {
    message.error('Error in fetching the alerts');
  }
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Home = ({
  auth,
  user,
  bots,
  alerts,
  tutorialVisible,
  currentDashboard,
  switchBot,
  dbDownloadStatus,
  logOut,
  getBotsList,
  getUserData,
  updateRef,
  updateChannel,
  getAlerts,
  setTutorialVisible,
}: Props) => {
  const { t } = useTranslation();
  const [siderCollapsed, setSiderCollapsed] = useState(
    localStorage.getItem('siderCollapsed') === 'true',
  );

  const granted = useSectionPlan('alerts');

  const alertsContRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getBotsList();
    getUserData();
  }, [getBotsList, getUserData]);

  useEffect(() => {
    granted && getAlerts();
  }, [getAlerts, granted]);

  useEffect(() => {
    if (!alerts.loading && granted) {
      if (alerts.list.length > 0) {
        handleAlert(alertsContRef.current, bots.list);

        const interval = setInterval(
          () => handleAlert(alertsContRef.current, bots.list),
          NUM_MINS_ALERTS * 60 * 1000,
        );
        return () => clearInterval(interval);
      }
    }

    return () => {};
  }, [alerts, bots.list, granted]);

  const toggleSider = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    setSiderCollapsed(prevSiderCollapsed => {
      localStorage.setItem('siderCollapsed', (!prevSiderCollapsed).toString());
      return !prevSiderCollapsed;
    });
  };

  return (
    <Spinner
      spinning={dbDownloadStatus.loading}
      size="large"
      tip={t('Your report will be ready soon...')}
    >
      <Layout hasSider style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={siderCollapsed} toggleCollapsed={toggleSider} />

        <Layout
          style={{
            marginLeft: `${siderCollapsed ? 80 : 200}px`,
            transition: 'margin 0.2s',
            transitionDelay: '0.2s',
          }}
        >
          {user.data.plan === 'demo' &&
            isTrialExpired(user.data.trialExpDate) && <AttentionPanel />}

          <HomeLayoutWrapper>
            <FixedPanel
              botPanel={{
                visible: currentDashboard !== null,
                bots,
                switchBot: (botId: string) => {
                  switchBot(botId);
                  updateRef();
                  updateChannel();
                },
              }}
              accountPanel={{
                user,
                auth,
                logOut,
              }}
            />
            <HomeRoutes />

            <Footer style={{ marginTop: '16px' }} />
          </HomeLayoutWrapper>
        </Layout>

        <AlertsWrapper ref={alertsContRef} />

        <Tutorial visible={tutorialVisible} setVisible={setTutorialVisible} />
      </Layout>
    </Spinner>
  );
};

const mapStateToProps = (store: RootState) => ({
  auth: store.account.auth,
  user: store.account.user,
  bots: store.account.bots,
  alerts: store.tools.alerts,
  currentDashboard: store.dashboard.current,
  dbDownloadStatus: store.dashboard.dbDownload,
  tutorialVisible: store.dashboard.tutorialVisible,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBotsList: accountActions.getBotsList,
      getUserData: accountActions.getUserData,
      getAlerts: toolsActions.getAlerts,
      logOut: accountActions.logOut,
      switchBot: accountActions.switchBot,
      updateRef: dashboardActions.updateRef,
      updateChannel: dashboardActions.updateChannel,
      setTutorialVisible: dashboardActions.setTutorialVisible,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
