import React from 'react';
import styled from 'styled-components';

import { AccountState } from 'types/account';
import AccountPanel from './AccountPanel';
import BotPanel from './BotPanel';

const Wrapper = styled.div`
  height: 56px;

  position: absolute;
  top: 16px;
  right: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 40px;
  }

  z-index: 50;
`;

type Props = {
  botPanel: {
    visible?: boolean;
    bots?: AccountState['bots'];
    switchBot?: (botId: string) => void;
  };
  accountPanel: {
    visible?: boolean;
    user?: AccountState['user'];
    auth?: AccountState['auth'];
    logOut?: () => void;
  };
};

const isVisible = ({ visible }: { visible?: boolean }) =>
  visible === undefined ? true : visible;

const FixedPanel = ({ botPanel, accountPanel }: Props) => (
  <Wrapper>
    {isVisible(botPanel) && botPanel.bots && botPanel.switchBot && (
      <BotPanel bots={botPanel.bots} switchBot={botPanel.switchBot} />
    )}

    {isVisible(accountPanel) &&
      accountPanel.user &&
      accountPanel.auth &&
      accountPanel.logOut && (
        <AccountPanel
          user={accountPanel.user}
          auth={accountPanel.auth}
          logOut={accountPanel.logOut}
        />
      )}
  </Wrapper>
);

export default FixedPanel;
