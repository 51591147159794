import React from 'react';
import { Trans } from 'react-i18next';
import pic2 from 'assets/img/tutorial-slides/Picture2.png';

import Slide, {
  PrevButton,
  NextButton,
  SpaceCenterer,
  CarouselType,
} from './Slide';

const Picture = () => <img src={pic2} alt="" />;

export default ({ carousel }: CarouselType) => (
  <Slide>
    <h2>
      <Trans>Filter panel</Trans>
    </h2>

    <SpaceCenterer>
      <Picture />
    </SpaceCenterer>

    <p>
      <Trans>At the top of each </Trans>
      <b>
        <Trans>dashboard</Trans>
      </b>
      <Trans> there is a </Trans>
      <i>
        <Trans>filter panel</Trans>
      </i>
      .
    </p>

    <p>
      <Trans>You can quickly filter data through the all Metrics </Trans>
      <b>
        <Trans>globally</Trans>
      </b>
      <Trans> by date or reference.</Trans>
    </p>

    <SpaceCenterer>
      <PrevButton carousel={carousel} />
      <NextButton carousel={carousel} />
    </SpaceCenterer>
  </Slide>
);
