import axios from 'axios';
import moment from 'moment';

type Fields = {
  Date?: string;
  'Number of CSV reports'?: number;
  'Short links usage'?: number;
  'Number of clusterization usage'?: number;
  'Number of PDF reports'?: number;
  'full CSV archive'?: number;
};

type Record = {
  id: string;
  fields: Fields;
  createdTime: string;
};

/**
 * Gets record id for actual date from localStorage or api
 *
 * @returns Promise with recordId if exists, null - otherwise
 */
const getActualRecordId = async () => {
  const today = moment().format('YYYY-MM-DD');
  const airtableLastRecord = localStorage.getItem('airTLastRec');

  if (
    airtableLastRecord !== null &&
    airtableLastRecord.split(';')[0] === today
  ) {
    return airtableLastRecord.split(';')[1];
  }

  type Response = {
    records: Record[];
    offset: string;
  };

  const params = {
    view: 'Feature raw data',
    filterByFormula: `DATETIME_FORMAT(Date, 'YYYY-MM-DD') = '${today}'`,
  };

  const {
    data: { records },
  } = await axios.get<Response>(
    `https://api.airtable.com/v0/appAsf3Vv0BEHQUqJ/Feature%20usage?${new URLSearchParams(
      params,
    ).toString()}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
      },
    },
  );

  return records.length ? records[0].id : null;
};

export const sendRecord = async (fields: Fields) => {
  let recordId = await getActualRecordId();

  if (recordId === null) {
    // create new record

    const { data: record } = await axios.post<Record>(
      'https://api.airtable.com/v0/appAsf3Vv0BEHQUqJ/Feature%20usage',
      {
        fields: {
          Date: moment().format('YYYY-MM-DD'),
          'Number of CSV reports': 0,
          'Short links usage': 0,
          'Number of clusterization usage': 0,
          'Number of PDF reports': 0,
          'full CSV archive': 0,
          ...fields,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      },
    );

    recordId = record.id;
  } else {
    // update existing record

    const { data: record } = await axios.get<Record>(
      `https://api.airtable.com/v0/appAsf3Vv0BEHQUqJ/Feature%20usage/${recordId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        },
      },
    );

    const updatedFields = Object.entries(fields).reduce(
      (prev, [key, val]) => ({
        ...prev,
        [key]: (record.fields as { [key: string]: string } & Fields)[key] + val,
      }),
      {},
    );

    axios.patch(
      `https://api.airtable.com/v0/appAsf3Vv0BEHQUqJ/Feature%20usage/${recordId}`,
      {
        fields: updatedFields,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  localStorage.setItem(
    'airTLastRec',
    `${moment().format('YYYY-MM-DD')};${recordId}`,
  );
};
