import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';

import ToolsRoutes from 'routes/ToolsRoutes';
import { useLocation } from 'react-router-dom';
import { Section } from '../ui';

const { Content } = Layout;

const ToolsPage = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
`;

const Tools = () => {
  const { pathname } = useLocation();
  const [, subSection] = pathname.split('/').splice(1);
  const { t } = useTranslation();

  return (
    <Section title={t('Tools')} subSection={subSection}>
      <ToolsPage>
        <ToolsRoutes />
      </ToolsPage>
    </Section>
  );
};

export default Tools;
