import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Modal, Input, Select, DatePicker, Button, Form } from 'antd';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { grey, gold } from '@ant-design/colors';

import { AccountState } from 'types/account';
import { DashboardState } from 'types/dashboard';
import { FiltersWrapper } from 'components/sections/ui';
import { loadChannelList, loadIntentsList, loadRefsList } from 'api/dashboard';
import { renderMetricActionButton } from 'helpers/renderers';
import { RootState } from 'reducers';

const { RangePicker } = DatePicker;

const MainFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px !important;
`;

const ActionButtonsWrapper = styled.div`
  height: 30px;
  margin-top: 3px;
  & > .ant-btn-link {
    font-size: 20px;
  }

  .ant-btn-link:first-child {
    margin-right: 15px;
  }
`;

const initRefs = {
  list: [],
  loading: false,
  error: '',
};

const initIntents = {
  list: [],
  loading: false,
  error: '',
};

const initChannels = {
  list: [],
  loading: false,
  error: '',
};

export const openInfoModal = (title: string, content: string) => {
  Modal.info({
    title,
    maskClosable: true,
    content: <p>{content}</p>,
    icon: <InfoCircleOutlined style={{ color: gold[5] }} />,
    onOk() {},
  });
};

interface Props {
  mainData: any;
  actionButtons: any;
  filterConfig: {
    search: {
      visible: boolean;
      text: string;
    };
    intent: boolean;
    dateProps?: any;
    startButton: string;
  };
  handleFormFinish: Function;
  params: any;
  setParams: Function;
}

const ToolsFilter = ({
  mainData,
  filterConfig,
  actionButtons,
  handleFormFinish,
  params,
  setParams,
}: Props) => {
  const bots = useSelector<RootState, AccountState['bots']>(
    state => state.account.bots,
  );
  const [refs, setRefs] = useState<DashboardState['refs']>(initRefs);
  const [intents, setIntents] = useState<DashboardState['intents']>(
    initIntents,
  );
  const [channels, setChannels] = useState<DashboardState['channels']>(
    initChannels,
  );

  const { t } = useTranslation();

  const handleFormValuesChange = useCallback(
    async (field: { [key: string]: any[keyof any] }) => {
      const newParams = {
        ...params,
        ...field,
      };

      if (newParams.botId && newParams.datesRange) {
        const datesRange = {
          startDate: newParams.datesRange[0],
          endDate: newParams.datesRange[1],
        };

        if ('botId' in field || 'datesRange' in field) {
          newParams.ref = undefined;
          newParams.intent = undefined;
          newParams.channel = undefined;

          setRefs(prevState => ({
            ...prevState,
            loading: true,
          }));

          try {
            const refsList = await loadRefsList(newParams.botId, {
              datesRange,
            });

            setRefs(prevState => ({
              ...prevState,
              loading: false,
              list: refsList.map(item => item.ref),
            }));
          } catch (error) {
            setRefs(prevState => ({
              ...prevState,
              error: t('Error while loading referrals!'),
            }));
          }
        }

        if (!('intent' in field) && (params.channel || !intents.list.length)) {
          newParams.intent = undefined;

          setIntents(prevState => ({
            ...prevState,
            loading: true,
          }));

          try {
            const intentsList = await loadIntentsList(newParams.botId, {
              ref: newParams.ref,
              datesRange,
            });

            setIntents(prevState => ({
              ...prevState,
              loading: false,
              list: intentsList.map(item => item.intent),
            }));
          } catch (error) {
            setIntents(prevState => ({
              ...prevState,
              error: t('Error while loading intents!'),
            }));
          }
        }

        if (!('channel' in field) && (params.intent || !channels.list.length)) {
          newParams.channel = undefined;

          setChannels(prevState => ({
            ...prevState,
            loading: true,
          }));

          try {
            const channelsList = await loadChannelList(newParams.botId, {
              ref: newParams.ref,
              datesRange,
            });

            setChannels(prevState => ({
              ...prevState,
              loading: false,
              list: channelsList,
            }));
          } catch (error) {
            setChannels(prevState => ({
              ...prevState,
              error: t('Error while loading channels!'),
            }));
          }
        }
      }

      setParams((prevParams: any) => ({ ...prevParams, ...newParams }));
    },
    [channels.list.length, intents.list.length, params, setParams, t],
  );

  return (
    <FiltersWrapper
      layout="inline"
      onFinish={handleFormFinish}
      onValuesChange={handleFormValuesChange}
    >
      <MainFilterWrapper>
        {filterConfig.search.visible && (
          <FormItem name="message">
            <Input
              placeholder={t(filterConfig.search.text)}
              suffix={<SearchOutlined style={{ color: grey[0] }} />}
              style={{ width: '200px' }}
            />
          </FormItem>
        )}

        <FormItem
          name="botId"
          rules={[
            {
              required: true,
              message: t('This field is required!'),
            },
          ]}
        >
          <Select
            showSearch
            allowClear={false}
            placeholder={t('Select bot')}
            loading={bots.loading}
            options={bots.list.map(bot => ({
              label: bot.name,
              value: bot.id,
            }))}
            style={{ maxWidth: '160px' }}
          />
        </FormItem>

        <FormItem
          name="channel"
          validateStatus={channels.error && 'error'}
          help={channels.error || undefined}
          valuePropName="option"
        >
          <Select
            showSearch
            allowClear
            placeholder={t('Select channel')}
            value={params.channel}
            options={channels.list.map(channel => ({
              label: channel,
              value: channel,
            }))}
            loading={channels.loading}
            style={{ maxWidth: '200px' }}
          />
        </FormItem>

        <FormItem
          name="datesRange"
          rules={[
            {
              required: true,
              message: t('This field is required!'),
            },
          ]}
        >
          <RangePicker
            ranges={{
              [t('1 day')]: [moment(), moment()],
              [t('2 days')]: [moment().subtract(1, 'd'), moment()],
              [t('7 days')]: [moment().subtract(6, 'd'), moment()],
              [t('30 days')]: [moment().subtract(29, 'd'), moment()],
            }}
            style={{ maxWidth: '280px' }}
            allowClear={false}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...filterConfig.dateProps}
          />
        </FormItem>

        {filterConfig.intent && (
          <FormItem
            name="intent"
            validateStatus={intents.error && 'error'}
            help={intents.error || undefined}
            valuePropName="option"
          >
            <Select
              showSearch
              allowClear
              placeholder={t('Select intent')}
              value={params.intent}
              options={intents.list.map(intent => ({
                label: intent,
                value: intent,
              }))}
              loading={intents.loading}
              style={{ maxWidth: '160px' }}
            />
          </FormItem>
        )}

        <FormItem
          name="ref"
          validateStatus={refs.error && 'error'}
          help={refs.error || undefined}
          valuePropName="option"
        >
          <Select
            showSearch
            allowClear
            placeholder={t('Select ref')}
            value={params.ref}
            options={refs.list.map((ref: any) => ({
              label: ref,
              value: ref,
            }))}
            loading={refs.loading}
            style={{ maxWidth: '160px' }}
          />
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit" loading={mainData.loading}>
            {t(filterConfig.startButton)}
          </Button>
        </FormItem>
      </MainFilterWrapper>

      <ActionButtonsWrapper>
        {actionButtons.map((button: any) =>
          renderMetricActionButton({
            ...button,
          }),
        )}
      </ActionButtonsWrapper>
    </FiltersWrapper>
  );
};

export default ToolsFilter;
