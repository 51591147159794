import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './assets/fonts/fonts.css';

import App from './App';
import store, { history } from './configureStore';
import './helpers/i18n';

render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
