import axios from 'axios';
import { AlertData, Alert } from 'types/tools';
import moment from 'moment';

export const deleteAlert = <Response>(id: number) =>
  axios.delete<Response>(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/alert/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
  );

export const createAlert = <Response>(alertData: AlertData) =>
  axios.post<Response>(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/alert/create`,
    {
      ...alertData,
      bot_id: alertData.botId,
      timestamp: moment().format('YYYY-MM-DD HH:mm'),
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
  );

export const checkAlerts = async () => {
  type Response = {
    result: (Alert & { bot: number })[];
  };

  const {
    data: { result },
  } = await axios.get<Response>(
    `${process.env.REACT_APP_BACKEND_ADDRESS}/alert/check`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    },
  );

  return result.map(r => ({ ...r, botId: r.bot }));
};
