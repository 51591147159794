import styled from 'styled-components';
import { Form } from 'antd';
import { grey } from '@ant-design/colors';

const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0;
    color: ${grey[10]};
  }

  .ant-form-item-explain {
    white-space: pre;
  }

  .ant-skeleton {
    display: flex;
  }
`;

export default FormItem;
