import React from 'react';
import styled from 'styled-components';
import { Select as SelectAntd, Skeleton } from 'antd';
import { RobotOutlined } from '@ant-design/icons';

import { AccountState } from 'types/account';

const { Option } = SelectAntd;

const Select = styled(SelectAntd)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  :not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid transparent;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }
`;

type Props = {
  bots: AccountState['bots'];
  switchBot: Function;
};

const BotPanel = ({ bots, switchBot }: Props) =>
  bots.loading ? (
    <Skeleton.Button
      active
      size="large"
      style={{ display: 'flex', width: '120px' }}
    />
  ) : (
    <Select
      value={bots.current?.id}
      onChange={switchBot}
      size="large"
      style={{ minWidth: '120px' }}
      optionLabelProp="label"
      dropdownStyle={{ borderRadius: '10px' }}
      dropdownAlign={{ offset: [0, 10] }}
      getPopupContainer={(triggerNode: HTMLDivElement) =>
        triggerNode.parentNode
      }
    >
      {bots.list.map(bot => (
        <Option
          key={bot.id}
          value={bot.id}
          label={
            <>
              <RobotOutlined
                style={{ padding: '0 5px', marginRight: '11px' }}
              />
              {bot.name}
            </>
          }
          style={{ textAlign: 'center' }}
        >
          {bot.name}
        </Option>
      ))}
    </Select>
  );

export default BotPanel;
