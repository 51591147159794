import React from 'react';
import styled from 'styled-components';
import { red, green } from '@ant-design/colors';

const MessageBox = styled.div`
  & .error-msg {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${red[5]};
    margin-bottom: 24px;
  }

  & .prompt-msg {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${green[5]};
    margin-bottom: 24px;
  }
`;

type Props = {
  error?: string;
  prompt?: string;
};

const ResponseMsgBox = ({ error, prompt }: Props) => {
  return (
    <MessageBox>
      {error && <p className="error-msg">{error || ''}</p>}
      {prompt && <p className="prompt-msg">{prompt || ''}</p>}
    </MessageBox>
  );
};

export default ResponseMsgBox;
