import { MetricName } from 'types/metrics';

export const planNames = [
  'demo',
  'essential',
  'pro',
  'premium',
  'enterprise',
] as const;

/**
 * COMMON     - is the feature granted to the plan. Requires adding 'granted' boolean field;
 * TIME_LIMIT - for what period (since the subscription purchase) is the feature granted.
 *              Requires adding 'duration' array field containing the integer number at the
 *              first index defining the amount of time intervals, and the string value at the
 *              second index defining the the time interval ('h' - hours, 'd' - days,
 *              'm' - months, 'y' - years);
 * NUM_LIMIT  - the maximum amount of some feature's countable value. Requires 'value' integer
 *              field.
 */
export const planTypes = ['COMMON', 'TIME_LIMIT', 'NUM_LIMIT'];

type TimeInterval = 'h' | 'd' | 'w' | 'm' | 'y';

export type CommonPermission = { type: 'COMMON'; granted: boolean };

export type TimeLimitPermission = {
  type: 'TIME_LIMIT';
  interval: [number, TimeInterval];
};

export type NumLimitPermission = { type: 'NUM_LIMIT'; value: number };

type Permission = CommonPermission | TimeLimitPermission | NumLimitPermission;

type PlansConfig = {
  metric: {
    [key in MetricName]: {
      [key in typeof planNames[number]]: Permission;
    };
  };
  section: {
    [key: string]: {
      [key in typeof planNames[number]]: CommonPermission;
    };
  };
  botsNumber: { [key in typeof planNames[number]]: NumLimitPermission };
  reports: {
    [key: string]: {
      [key in typeof planNames[number]]: Permission;
    };
  };
};

type CommonPermissionPlanPresets = {
  [key in typeof planNames[number]]: {
    [key in typeof planNames[number]]: CommonPermission;
  };
};

const commonPermissionPlanPresets: CommonPermissionPlanPresets = {
  demo: {
    demo: { type: 'COMMON', granted: false },
    essential: { type: 'COMMON', granted: true },
    pro: { type: 'COMMON', granted: true },
    premium: { type: 'COMMON', granted: true },
    enterprise: { type: 'COMMON', granted: true },
  },
  essential: {
    demo: { type: 'COMMON', granted: true },
    essential: { type: 'COMMON', granted: true },
    pro: { type: 'COMMON', granted: true },
    premium: { type: 'COMMON', granted: true },
    enterprise: { type: 'COMMON', granted: true },
  },
  pro: {
    demo: { type: 'COMMON', granted: false },
    essential: { type: 'COMMON', granted: false },
    pro: { type: 'COMMON', granted: true },
    premium: { type: 'COMMON', granted: true },
    enterprise: { type: 'COMMON', granted: true },
  },
  premium: {
    demo: { type: 'COMMON', granted: false },
    essential: { type: 'COMMON', granted: false },
    pro: { type: 'COMMON', granted: false },
    premium: { type: 'COMMON', granted: true },
    enterprise: { type: 'COMMON', granted: true },
  },
  enterprise: {
    demo: { type: 'COMMON', granted: true },
    essential: { type: 'COMMON', granted: false },
    pro: { type: 'COMMON', granted: false },
    premium: { type: 'COMMON', granted: false },
    enterprise: { type: 'COMMON', granted: true },
  },
};

export const plansConfig: PlansConfig = {
  metric: {
    usersTotal: commonPermissionPlanPresets.essential,
    conversion: commonPermissionPlanPresets.essential,
    sessionsCount: commonPermissionPlanPresets.essential,
    newUsers: commonPermissionPlanPresets.essential,
    uniqueUsers: commonPermissionPlanPresets.essential,
    unsubscribed: commonPermissionPlanPresets.essential,
    engagement: commonPermissionPlanPresets.essential,
    sessions: commonPermissionPlanPresets.essential,
    lastMsgDate: commonPermissionPlanPresets.essential,
    sessionsAvgTime: commonPermissionPlanPresets.essential,
    sessionsAvgCount: commonPermissionPlanPresets.essential,
    nps: commonPermissionPlanPresets.essential,
    likes: commonPermissionPlanPresets.essential,
    refs: commonPermissionPlanPresets.essential,
    messages: commonPermissionPlanPresets.essential,
    messagesCount: commonPermissionPlanPresets.essential,
    misunderstood: commonPermissionPlanPresets.essential,
    misunderstoodCount: commonPermissionPlanPresets.essential,
    misunderstoodIntents: commonPermissionPlanPresets.essential,
    topIntents: commonPermissionPlanPresets.essential,
    dauMau: commonPermissionPlanPresets.essential,
    retention: commonPermissionPlanPresets.essential,
    intentStats: commonPermissionPlanPresets.essential,
    activity: commonPermissionPlanPresets.essential,
    funnel: commonPermissionPlanPresets.essential,
    flowThroughHandler: commonPermissionPlanPresets.essential,
    conversationalFlow: commonPermissionPlanPresets.essential,
  },
  section: {
    all: commonPermissionPlanPresets.essential,
    users: commonPermissionPlanPresets.essential,
    activity: commonPermissionPlanPresets.essential,
    conversations: commonPermissionPlanPresets.essential,
    'link-creator': commonPermissionPlanPresets.pro,
    clustering: commonPermissionPlanPresets.pro,
    'search-messages': commonPermissionPlanPresets.essential,
    summarization: commonPermissionPlanPresets.pro,
    'intent-naming': commonPermissionPlanPresets.essential,
    reports: commonPermissionPlanPresets.pro,
    alerts: commonPermissionPlanPresets.premium,
    'my-bots': commonPermissionPlanPresets.essential,
  },
  botsNumber: {
    demo: { type: 'NUM_LIMIT', value: 1 },
    essential: { type: 'NUM_LIMIT', value: 1 },
    pro: { type: 'NUM_LIMIT', value: 5 },
    premium: { type: 'NUM_LIMIT', value: Infinity },
    enterprise: { type: 'NUM_LIMIT', value: Infinity },
  },
  reports: {
    sharing: commonPermissionPlanPresets.pro,
    pdf: commonPermissionPlanPresets.essential,
    csv: {
      demo: {
        type: 'TIME_LIMIT',
        interval: [1, 'm'],
      },
      essential: {
        type: 'TIME_LIMIT',
        interval: [1, 'm'],
      },
      pro: {
        type: 'TIME_LIMIT',
        interval: [5, 'm'],
      },
      premium: {
        type: 'TIME_LIMIT',
        interval: [Infinity, 'y'],
      },
      enterprise: {
        type: 'TIME_LIMIT',
        interval: [Infinity, 'y'],
      },
    },
  },
};
