import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AccountState } from 'types/account';
import { plansConfig } from 'helpers/configs/plans';
import { RootState } from 'reducers';

/**
 * Is sidebar's section granted to the current user's plan.
 *
 * @param sectionKey sidebar section name
 */
const useSectionPlan = (sectionKey: keyof typeof plansConfig.section) => {
  const plan = useSelector<RootState, AccountState['user']['data']['plan']>(
    state => state.account.user.data.plan,
  );
  const isGranted = useMemo(() => {
    if (!(sectionKey in plansConfig.section)) return true;
    if (plan === null) return null;

    const currentPlan = plansConfig.section[sectionKey][plan];

    switch (currentPlan.type) {
      case 'COMMON':
        return currentPlan.granted;

      default:
        return true;
    }
  }, [sectionKey, plan]);

  return isGranted;
};

export default useSectionPlan;
