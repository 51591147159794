import React, { useEffect, useMemo, useRef } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ResponsiveContainer } from 'recharts';
import { saveAs } from 'file-saver';

import { RootState } from 'reducers';
import { updateMetricRef } from 'actions/metrics';
import { getImage } from 'helpers/utils';

import { arrayToCSVBlob, getMetricArray } from 'helpers/csv';
import Metric, { MetricProps } from './Metric';

const ChartWrapper = styled.div``;

type Props = MetricProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Chart = ({
  metricKey,
  metric,
  updMetricRef,
  children,
  empty,
  size,
  height,
  aspect,
  onReload,
  panels,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { title, loaded } = useMemo(() => metric[metricKey], [
    metric,
    metricKey,
  ]);

  const data = useMemo(() => metric.conversationalFlow, [
    metric.conversationalFlow,
  ]);

  useEffect(() => {
    if (loaded && ref.current) {
      updMetricRef(metricKey, ref);
    }
  }, [metricKey, updMetricRef, loaded]);

  const downloadPng = async () => {
    if (ref && ref.current) {
      const img = await getImage(ref.current, 1920);
      saveAs(img.file, `${title}.png`);
    }
  };

  const downloadCSV = () => {
    const cvsArray = getMetricArray(metricKey, data);
    const csvBlob = arrayToCSVBlob(cvsArray);

    saveAs(csvBlob, `${title}.csv`);
  };

  return (
    <Metric
      metricKey={metricKey}
      type="chart"
      empty={empty}
      size={size}
      height={height}
      onReload={onReload}
      panels={panels}
      download={metricKey === 'conversationalFlow' ? downloadCSV : downloadPng}
    >
      <ChartWrapper ref={ref}>
        <ResponsiveContainer width="100%" aspect={aspect || 5 / 3}>
          {children || <div />}
        </ResponsiveContainer>
      </ChartWrapper>
    </Metric>
  );
};

const mapStateToProps = (store: RootState) => ({
  metric: store.metrics.metric,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updMetricRef: updateMetricRef,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
