import React, { useState, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Switch, Divider, InputNumber, Select, Form, Skeleton } from 'antd';
import { Alert, AlertData, AlertManagerPanelItem } from 'types/tools';

const Wrapper = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 505px;

  > * {
    margin: 0 12px 4px 0 !important;
  }

  .ant-form-item-control-input {
    min-height: 0;
  }
`;

type Props = {
  type: number;
  items: AlertManagerPanelItem[];
  requiredFields: string[];
  id?: number;
  createAlert: (alertData: AlertData) => void;
  editAlert: (id: number, alertData: AlertData) => void;
  deleteAlert: (id: number) => void;
  checked: boolean;
  initialValues?: Alert;
  loading: boolean;
};

const AlertManagerPanel = ({
  id,
  type,
  items,
  requiredFields,
  createAlert,
  editAlert,
  deleteAlert,
  checked,
  initialValues,
  loading,
}: Props) => {
  const [fieldsFilled, setFieldsFilled] = useState([] as string[]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [form] = Form.useForm();

  const disabled = useMemo(
    () => !requiredFields.every(dep => fieldsFilled.includes(dep)) && !checked,
    [checked, fieldsFilled, requiredFields],
  );

  useEffect(() => form.resetFields(), [form, initialValues]);

  const handleSwitchChange = (isChecked: boolean) => {
    if (isChecked) {
      const alertData = {
        ...form.getFieldsValue(['botId', 'ref', 'payload']),
        type,
      };

      if (dataUpdated) {
        if (id !== undefined) editAlert(id, alertData);
        setDataUpdated(false);
      } else {
        createAlert(alertData);
      }
    } else {
      setFieldsFilled([]);
      if (id !== undefined) deleteAlert(id);
    }
  };

  const handleSelectChange = (val: string, item: AlertManagerPanelItem) => {
    setDataUpdated(true);
    if (item.onChange) item.onChange(val, form);
    setFieldsFilled(prevState =>
      prevState.includes(item.key) ? prevState : [...prevState, item.key],
    );
  };

  const handleInputNumChange = (
    val: string | number,
    item: AlertManagerPanelItem,
  ) => {
    if (val || val === 0) {
      setDataUpdated(true);
      setFieldsFilled(prevState =>
        prevState.includes(item.key) ? prevState : [...prevState, item.key],
      );
    } else {
      setFieldsFilled(prevState =>
        prevState.filter(field => field !== item.key),
      );
    }
  };

  return (
    <Wrapper layout="inline" form={form} initialValues={initialValues}>
      <ContentWrapper>
        {items.map(item => {
          if (item.loading)
            return (
              <Skeleton.Input
                key={item.key}
                active
                style={{ minWidth: '90px' }}
                size="small"
              />
            );

          switch (item.type) {
            case 'text':
              return (
                <p key={item.key}>
                  <Trans>{item.placeholder}</Trans>
                </p>
              );

            case 'select':
              return (
                <Form.Item key={item.key} name={item.key}>
                  <Select
                    placeholder={<Trans>{item.placeholder}</Trans>}
                    size="small"
                    onChange={(val: string) => handleSelectChange(val, item)}
                    disabled={
                      item.dependencies
                        ? !item.dependencies?.every(dep =>
                            fieldsFilled.includes(dep),
                          ) && !item.defaultValue
                        : false
                    }
                    options={item.options}
                    loading={loading || item.loading}
                    style={{ maxWidth: '120px' }}
                  />
                </Form.Item>
              );

            case 'inputNum':
              return (
                <Form.Item key={item.key} name={item.key}>
                  <InputNumber
                    key={item.key}
                    placeholder={item.placeholder}
                    size="small"
                    min={0}
                    onChange={(val: string | number) =>
                      handleInputNumChange(val, item)
                    }
                    disabled={
                      item.dependencies
                        ? !item.dependencies?.every(dep =>
                            fieldsFilled.includes(dep),
                          ) && !item.defaultValue
                        : false
                    }
                    loading={loading || item.loading}
                  />
                </Form.Item>
              );

            default:
              return null;
          }
        })}
      </ContentWrapper>

      <Divider type="vertical" style={{ margin: '0 14px 4px' }} />

      <Switch
        onChange={handleSwitchChange}
        style={{ marginBottom: '4px' }}
        disabled={disabled || loading}
        checked={checked && !dataUpdated}
        loading={loading}
      />
    </Wrapper>
  );
};

export default AlertManagerPanel;
