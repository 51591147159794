import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'assets/locales/en';
import ru from 'assets/locales/ru';
import test from 'assets/locales/test';

// the translations
const resources = {
  en,
  ru,
  test,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('locale') || 'en',
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
