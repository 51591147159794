import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { resetPassword } from 'actions/account';
import { RootState } from 'reducers';
import { ResponseMsgBox } from 'components/ui';

import { FormField, HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const PasswordReset = ({ error, prompt, resetUserPassword }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('Forgot your password title')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <Form onFinish={resetUserPassword}>
        <FormField
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter valid email'),
              type: 'email',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: gold[5] }} />}
            placeholder={t('Email')}
          />
        </FormField>

        <FormField>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            style={{ borderRadius: '4px' }}
          >
            {t('Reset password')}
          </Button>
        </FormField>
      </Form>

      <HelpMessage>
        {`${t('If you don’t receive an email within a few minutes, please')} `}
        <a href="mailto:onedash@eora.ru">{t('contact us')}</a>
      </HelpMessage>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  error: store.account.resetPwd.error,
  prompt: store.account.resetPwd.prompt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetUserPassword: resetPassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
