import React, { useState } from 'react';
import styled from 'styled-components';
import { History } from 'history';
import { HashRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import i18n from 'i18next';
import { ConfigProvider } from 'antd';
import en from 'antd/es/locale/en_GB';
import ru from 'antd/es/locale/ru_RU';

import AppRoutes from './routes/AppRoutes';
import 'moment/locale/ru';

const Router = styled(ConnectedRouter)`
  width: 100vw;
  height: 100vh;
  overflow: auto;
`;

interface AppProps {
  history: History;
}

const App = ({ history }: AppProps) => {
  const [locale, setLocale] = useState(
    localStorage.getItem('locale') === 'ru' ? ru : en,
  );

  moment.locale(localStorage.getItem('locale') || 'en', {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  i18n.on('languageChanged', lng => {
    setLocale(lng === 'ru' ? ru : en);
    moment.locale(lng);
  });

  return (
    <ConfigProvider locale={locale}>
      <Router history={history}>
        <HashRouter>
          <AppRoutes />
        </HashRouter>
      </Router>
    </ConfigProvider>
  );
};

export default App;
