import React, { useRef, RefObject } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Table, Button, Input, Popconfirm, Tag } from 'antd';
import { blue, red } from '@ant-design/colors';

import { AccountState } from 'types/account';

import CopyButton from './CopyButton';

const TableWrapper = styled.div`
  .ant-popover {
    .anticon-exclamation-circle {
      color: ${red[4]};
      font-size: 20px;
    }

    .ant-popover-message-title {
      padding-left: 32px;
    }

    .ant-btn {
      padding: 0 14px;
    }

    .ant-btn-primary {
      background-color: ${blue[5]};
      border-color: ${blue[5]};

      :hover,
      :focus {
        background-color: ${blue[4]};
        border-color: ${blue[4]};
      }
    }

    .ant-btn-default {
      :hover,
      :focus {
        color: ${blue[5]};
        border-color: ${blue[5]};
      }
    }
  }
`;

const DeleteButton = styled(Button)`
  color: ${blue[5]};
  padding: 4px 0;

  :hover,
  :focus {
    color: ${blue[3]};
  }
`;

const InputTokenWrapper = styled.div`
  position: relative;
`;

const InputToken = ({ token }: { token: string }) => (
  <InputTokenWrapper>
    <Input.Password value={token} readOnly visibilityToggle={false} />
    <CopyButton text={token} />
  </InputTokenWrapper>
);

const PopcornfirmTitle = () => (
  <>
    <b>
      <Trans>Are you sure you want to delete this bot?</Trans>
    </b>
    <br />
    <p style={{ margin: '0' }}>
      <Trans>
        Be careful: all bot&apos;s data will be deleted permanently!
      </Trans>
    </p>
  </>
);

const getMyBotsCols = (
  deleteBot: (id: number) => void,
  tableRef: RefObject<HTMLDivElement>,
) => [
  {
    title: <Trans>Name</Trans>,
    dataIndex: 'name',
    key: 'name',
  },
  // {
  //   title: 'Dashboards',
  //   dataIndex: 'boards',
  //   key: 'boards',
  // },
  {
    title: <Trans>Date of creation</Trans>,
    dataIndex: 'dateCreation',
    key: 'dateCreation',
  },
  {
    title: <Trans>Token</Trans>,
    dataIndex: 'token',
    key: 'token',
    render: (token: string) => <InputToken token={token} />,
  },
  {
    title: <Trans>Anonymized</Trans>,
    dataIndex: 'anonymized',
    key: 'anonymized',
    render: (anonymized: boolean) =>
      anonymized ? (
        <Tag color="success">
          <Trans>Yes</Trans>
        </Tag>
      ) : (
        <Tag color="error">
          <Trans>No</Trans>
        </Tag>
      ),
  },
  {
    title: <Trans>Action</Trans>,
    dataIndex: 'action',
    key: 'action',
    render: (_: any, record: { key: number }) => (
      <Popconfirm
        title={<PopcornfirmTitle />}
        onConfirm={() => deleteBot(record.key)}
        okText={<Trans>Yes</Trans>}
        cancelText={<Trans>No</Trans>}
        okButtonProps={{
          type: 'default',
        }}
        cancelButtonProps={{
          type: 'primary',
        }}
        getPopupContainer={() => tableRef.current}
      >
        <DeleteButton type="link">
          <Trans>Delete</Trans>
        </DeleteButton>
      </Popconfirm>
    ),
  },
];

type Props = {
  bots: AccountState['bots'];
  deleteBot: (id: number) => void;
};

const BotsTable = ({ bots, deleteBot }: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <TableWrapper ref={tableRef}>
      <Table
        dataSource={bots.list.map(bot => ({
          key: bot.id,
          name: bot.name,
          dateCreation: bot.timestamp?.format('DD.MM.YYYY'),
          // boards: 'All, Users, Activity, Conversations',
          token: bot.token,
          anonymized: bot.anonymized,
        }))}
        bordered
        columns={getMyBotsCols(deleteBot, tableRef)}
        loading={bots.loading}
        pagination={{ hideOnSinglePage: true }}
        style={{ width: '815px' }}
      />
    </TableWrapper>
  );
};

export default BotsTable;
