import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Dropdown as DropdownAntd, Menu, Button } from 'antd';
import { grey } from '@ant-design/colors';
import { WarningFilled } from '@ant-design/icons';

const DropdownOverlay = styled(Menu)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  color: ${grey[5]};

  .ant-btn:not(:disabled) {
    color: ${grey[5]};
  }
`;

const WarningIcon = styled(WarningFilled)`
  font-size: 12px;
  color: ${grey[4]};
`;

type OverlayItem = {
  key: string;
  content: string;
  onClick?: Function;
  disabled?: boolean;
  warning?: boolean;
};

const dpOverlay = (overlayItems: OverlayItem[], alignItems = 'center') => (
  <DropdownOverlay>
    {overlayItems.map((item: OverlayItem) => (
      <Menu.Item key={item.key}>
        <Button
          size="small"
          type="link"
          block
          onClick={item.onClick}
          disabled={item.disabled || false}
          icon={item.warning && <WarningIcon />}
          style={{ padding: 0, textAlign: alignItems }}
        >
          {item.content}
        </Button>
      </Menu.Item>
    ))}
  </DropdownOverlay>
);

type Props = {
  children?: ReactNode;
  overlayItems: OverlayItem[];
  placement?: string;
  trigger?: string[];
  overlayStyle?: { [key: string]: string };
  alignItems?: string;
};

const Dropdown = ({
  children,
  overlayItems,
  placement,
  trigger,
  overlayStyle,
  alignItems,
}: Props) => (
  <DropdownAntd
    trigger={trigger}
    getPopupContainer={(triggerNode: HTMLDivElement) => triggerNode.parentNode}
    overlay={() => dpOverlay(overlayItems, alignItems)}
    placement={placement || 'bottomRight'}
    overlayStyle={{ paddingTop: '10px', ...overlayStyle }}
  >
    {children}
  </DropdownAntd>
);

export default Dropdown;
