/* eslint-disable camelcase */
import React from 'react';
import { TFunction } from 'i18next';
import {
  ChartData,
  MetricName,
  MetricPanel,
  MetricPanelOptionsSelected,
  MetricsState,
} from 'types/metrics';
import IntentsTooltip from 'components/sections/dashboards/IntentsTooltip';
import i18n from 'helpers/i18n';

export type Metric = {
  metric: MetricsState['metric'][MetricName];
  metricKey: MetricName;
  value?: string;
  empty?: boolean;
  sizeProps?: {
    size?: number;
    height?: number;
    aspect?: number;
    pageSize?: number;
    margin?: object;
  };
  onReload: (options?: MetricPanelOptionsSelected | null) => void;
  panels?: MetricPanel[];
  chartOptions?: {
    type: string;
    chart?: ChartData;
    xAxis?: { [key: string]: any };
    yAxis?: { [key: string]: any };
    tooltipProps?: object;
    additionalChartProps?: object;
    additionalChartComponents?: any;
  };
};

export type Dashboard = Metric[];

export const getDashboardConfig = (
  t: TFunction,
  metric: MetricsState['metric'],
  loadMetric: {
    [key in MetricName]: (
      options?: MetricPanelOptionsSelected | null | undefined,
    ) => void;
  },
  intents: {
    default?: string;
    loading: boolean;
    list: string[];
    error: string;
  },
  getIntentMsg: Function,
  loadIntentsTree: Function,
): Dashboard => [
  {
    metric: metric.usersTotal,
    metricKey: 'usersTotal',
    onReload: loadMetric.usersTotal,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.usersTotal.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
    ],
  },
  {
    metric: metric.conversion,
    metricKey: 'conversion',
    value: `${metric.conversion.value}${metric.conversion.percent ? '%' : ''}`,
    empty: !intents.default,
    onReload: loadMetric.conversion,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.conversion.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
      {
        key: 'unit',
        type: 'radio',
        loading: false,
        value: metric.conversion.percent ? 'percent' : 'count',
        options: [
          { value: 'percent', label: '%' },
          { value: 'count', label: '#' },
        ],
      },
    ],
  },
  {
    metric: metric.sessionsCount,
    metricKey: 'sessionsCount',
    onReload: loadMetric.sessionsCount,
  },
  {
    metric: metric.newUsers,
    metricKey: 'newUsers',
    onReload: loadMetric.newUsers,
    chartOptions: { type: 'area' },
  },
  {
    metric: metric.uniqueUsers,
    metricKey: 'uniqueUsers',
    onReload: loadMetric.uniqueUsers,
    chartOptions: { type: 'area' },
  },
  {
    metric: metric.unsubscribed,
    metricKey: 'unsubscribed',
    onReload: loadMetric.unsubscribed,
    chartOptions: { type: 'bar' },
  },
  {
    metric: metric.engagement,
    metricKey: 'engagement',
    onReload: loadMetric.engagement,
    chartOptions: {
      type: 'area',
      chart: metric.engagement.chart[metric.engagement.view],
      yAxis: {
        label: {
          dy: 40,
          value: t(
            `${metric.engagement.view[0].toUpperCase()}${metric.engagement.view.slice(
              1,
            )}/${
              metric.engagement.view === 'sessions' ? t('Users') : t('Sessions')
            }`,
          ),
        },
      },
    },
    panels: [
      {
        key: 'view',
        type: 'radio',
        loading: false,
        value: metric.engagement.view,
        options: [
          { value: 'time', label: t('Time') },
          { value: 'interactions', label: t('Interactions') },
          { value: 'sessions', label: t('Sessions') },
        ],
      },
    ],
  },
  {
    metric: metric.sessions,
    metricKey: 'sessions',
    onReload: loadMetric.sessions,
    chartOptions: {
      type: 'bar',
      yAxis: { label: { value: t('Sessions') } },
    },
  },
  {
    metric: metric.lastMsgDate,
    metricKey: 'lastMsgDate',
    onReload: loadMetric.lastMsgDate,
    value: parseInt(metric.lastMsgDate.value, 10)
      ? `${parseInt(metric.lastMsgDate.value, 10)} ${i18n.t('hour', {
          count: parseInt(metric.lastMsgDate.value, 10),
        })} ${t(' ago')}`
      : t('< 30 minutes ago'),
  },
  {
    metric: metric.sessionsAvgTime,
    metricKey: 'sessionsAvgTime',
    onReload: loadMetric.sessionsAvgTime,
  },
  {
    metric: metric.sessionsAvgCount,
    metricKey: 'sessionsAvgCount',
    onReload: loadMetric.sessionsAvgCount,
  },
  {
    metric: metric.nps,
    metricKey: 'nps',
    value: `⭐\xa0\xa0${metric.nps.value}`,
    empty: metric.nps.value === null,
    onReload: loadMetric.nps,
  },
  {
    metric: metric.likes,
    metricKey: 'likes',
    value: metric.likes.percent
      ? `👍\xa0\xa0${metric.likes.data?.like_percent}%`
      : `👍\xa0\xa0${metric.likes.data?.likes} / ${metric.likes.data?.dislikes}`,
    empty: metric.likes.data === undefined,
    onReload: loadMetric.likes,
    panels: [
      {
        key: 'unit',
        type: 'radio',
        loading: false,
        value: metric.likes.percent ? 'percent' : 'count',
        options: [
          { value: 'percent', label: '%' },
          { value: 'count', label: '#' },
        ],
      },
    ],
  },
  {
    metric: metric.refs,
    metricKey: 'refs',
    onReload: loadMetric.refs,
  },
  {
    metric: metric.messages,
    metricKey: 'messages',
    onReload: loadMetric.messages,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.messages.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
    ],
  },
  {
    metric: metric.messagesCount,
    metricKey: 'messagesCount',
    onReload: loadMetric.messagesCount,
    chartOptions: {
      type: 'bar',
      yAxis: { label: { value: t('Messages') } },
    },
  },
  {
    metric: metric.misunderstood,
    metricKey: 'misunderstood',
    onReload: loadMetric.misunderstood,
  },
  {
    metric: metric.misunderstoodCount,
    metricKey: 'misunderstoodCount',
    onReload: loadMetric.misunderstoodCount,
    chartOptions: {
      type: 'bar',
      yAxis: {
        label: { value: t('Messages') },
        tickFormatter: (tick: number) =>
          `${tick}${metric.misunderstoodCount.percent ? '%' : ''}`,
      },
      tooltipProps: {
        formatter: (value: string | number, name: string) =>
          name === 'count' && metric.misunderstoodCount.percent
            ? `${value}%`
            : value,
      },
    },
    panels: [
      {
        key: 'unit',
        type: 'radio',
        loading: false,
        value: metric.misunderstoodCount.percent ? 'percent' : 'count',
        options: [
          { value: 'percent', label: '%' },
          { value: 'count', label: '#' },
        ],
      },
    ],
  },
  {
    metric: metric.misunderstoodIntents,
    metricKey: 'misunderstoodIntents',
    onReload: loadMetric.misunderstoodIntents,
  },
  {
    metric: metric.topIntents,
    metricKey: 'topIntents',
    onReload: loadMetric.topIntents,
    sizeProps: {
      size: 1.5,
      height: 3,
      aspect: 3 / 1,
    },
    chartOptions: {
      type: 'bar',
      xAxis: {
        dataKey: 'intent',
        label: { value: '' },
        tickFormatter: (tick: string) =>
          tick.length > 10 ? `${tick.slice(0, 10)}...` : tick,
        angle: -45,
        textAnchor: 'end',
        interval: 0,
        height: 65,
      },
      yAxis: { label: { value: t('Count') } },
      tooltipProps: {
        content: <IntentsTooltip />,
      },
      additionalChartProps: {
        onClick: (e: any) => {
          if (e?.activeLabel) {
            getIntentMsg(e?.activeLabel);
          }
        },
        cursor: 'pointer',
      },
    },
    panels: [
      {
        key: 'view',
        type: 'radio',
        loading: false,
        value: metric.topIntents.view,
        options: [
          { value: 'all', label: t('All') },
          { value: 'entrance', label: t('Entrance') },
          { value: 'leaving', label: t('Leaving') },
          {
            value: 'entranceReturning',
            label: t('Entrance for returning users'),
          },
          {
            value: 'leavingReturning',
            label: t('Leaving for returning users'),
          },
        ],
      },
    ],
  },
  {
    metric: metric.dauMau,
    metricKey: 'dauMau',
    onReload: loadMetric.dauMau,
    empty: !metric.dauMau.chart || !metric.dauMau.chart?.length,
    chartOptions: {
      type: 'daumau',
      xAxis: { label: { dy: 10, position: 'insideBottomRight' } },
      yAxis: { dataKey: undefined, label: { value: t('Count') } },
    },
  },
  {
    metric: metric.retention,
    metricKey: 'retention',
    onReload: loadMetric.retention,
    sizeProps: {
      size: 1.5,
      height: 3,
      pageSize: 7,
    },
    panels: [
      {
        key: 'novelty',
        type: 'radio',
        loading: false,
        value: metric.retention.existing ? 'existing' : 'new',
        options: [
          { value: 'existing', label: t('Existing') },
          { value: 'new', label: t('New') },
        ],
      },
      {
        key: 'unit',
        type: 'radio',
        loading: false,
        value: metric.retention.percent ? 'percent' : 'count',
        options: [
          { value: 'percent', label: '%' },
          { value: 'count', label: '#' },
        ],
      },
      {
        key: 'period',
        type: 'radio',
        loading: false,
        value: metric.retention.period,
        options: [
          { value: 'days', label: t('Daily') },
          { value: 'weeks', label: t('Weekly') },
          { value: 'months', label: t('Monthly') },
        ],
      },
    ],
  },
  {
    metric: metric.intentStats,
    metricKey: 'intentStats',
    onReload: loadMetric.intentStats,
    empty:
      !intents.default ||
      !metric.intentStats.chart ||
      !metric.intentStats.chart?.length,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.intentStats.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
    ],
    chartOptions: {
      type: 'intentStats',
      xAxis: { label: { dy: 10, position: 'insideBottomRight' } },
      yAxis: { dataKey: undefined, label: { value: t('Sessions') } },
    },
  },
  {
    metric: metric.activity,
    metricKey: 'activity',
    onReload: loadMetric.activity,
    empty: !metric.activity.chart || !metric.activity.chart?.length,
    sizeProps: {
      size: 1.5,
    },
    chartOptions: {
      type: 'scatter',
    },
  },
  {
    metric: metric.funnel,
    metricKey: 'funnel',
    onReload: loadMetric.funnel,
    empty:
      !intents.default || !metric.funnel.chart || !metric.funnel.chart?.length,
    panels: [
      {
        key: 'intents',
        type: 'cascader',
        allowClear: true,
        showSearch: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.funnel.intents,
        options: metric.funnel.intentsTree,
        loadOptions: loadIntentsTree,
      },
    ],
    sizeProps: {
      size: 1.5,
    },
    chartOptions: {
      type: 'funnel',
    },
  },
  {
    metric: metric.flowThroughHandler,
    metricKey: 'flowThroughHandler',
    onReload: loadMetric.flowThroughHandler,
    empty:
      !intents.default ||
      !metric.flowThroughHandler.chart ||
      !metric.flowThroughHandler.chart?.nodes.length,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.flowThroughHandler.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
    ],
    sizeProps: {
      size: 1.5,
      margin: { right: 100, bottom: 5 },
    },
    chartOptions: {
      type: 'sankey',
    },
  },
  {
    metric: metric.conversationalFlow,
    metricKey: 'conversationalFlow',
    onReload: loadMetric.conversationalFlow,
    empty:
      !intents.default ||
      !metric.conversationalFlow.chart ||
      !metric.conversationalFlow.chart?.nodes.length,
    panels: [
      {
        key: 'intent',
        type: 'select',
        allowClear: true,
        placeholder: t('Select intent'),
        loading: intents.loading,
        value: metric.conversationalFlow.intent,
        options: intents.list.map(i => ({ value: i, label: i })),
      },
    ],
    sizeProps: {
      size: 1.5,
      margin: { right: 140, bottom: 5 },
    },
    chartOptions: {
      type: 'sankey',
    },
  },
];
