import {
  MetricsState,
  MetricName,
  MetricsActionType,
  MetricsActionTypeConstant,
} from 'types/metrics';

const initialState: MetricsState = {
  metric: {
    usersTotal: {
      title: 'Users total',
      url: 'users/total',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'users'],
      value: 0,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    conversion: {
      title: 'Conversion to intent',
      url: 'intents/target_action',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: 0,
      percent: true,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    sessionsCount: {
      title: 'Sessions count',
      url: 'sessions/total',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: 0,
      loaded: false,
      error: '',
      visible: true,
    },
    newUsers: {
      title: 'New users',
      url: 'users/new',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'users'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    uniqueUsers: {
      title: 'Unique users',
      url: 'users/unique',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'users'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    unsubscribed: {
      title: 'Unsubscribed users',
      url: 'users/unsubscribed',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    engagement: {
      title: 'Engagement',
      url: 'engagement',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      view: 'time',
      chart: {
        time: undefined,
        sessions: undefined,
        interactions: undefined,
      },
      loaded: false,
      error: '',
      visible: true,
    },
    sessions: {
      title: 'User sessions',
      url: 'sessions',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    lastMsgDate: {
      title: 'Last received message',
      url: 'messages/time',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: '',
      loaded: false,
      error: '',
      visible: true,
    },
    sessionsAvgTime: {
      title: 'Average session duration, min',
      url: 'sessions/statistics',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: 0,
      loaded: false,
      error: '',
      visible: true,
    },
    sessionsAvgCount: {
      title: 'Average sessions count on user',
      url: 'sessions/statistics',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: 0,
      loaded: false,
      error: '',
      visible: true,
    },
    nps: {
      title: 'NPS',
      url: 'nps_tag',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      value: 0,
      loaded: false,
      error: '',
      visible: true,
    },
    likes: {
      title: 'Like score',
      url: 'likes',
      ref: undefined,
      type: 'stat',
      boards: ['all', 'activity'],
      data: undefined,
      percent: true,
      loaded: false,
      error: '',
      visible: true,
    },
    refs: {
      title: 'Referrals',
      url: 'refs',
      ref: undefined,
      type: 'table',
      boards: ['all', 'conversations'],
      data: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    messages: {
      title: 'Messages',
      url: 'messages',
      ref: undefined,
      type: 'table',
      boards: ['all', 'conversations'],
      data: undefined,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    messagesCount: {
      title: 'Number of messages',
      url: 'messages/daily',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    misunderstood: {
      title: 'Misunderstood messages',
      url: 'messages/miss/top',
      ref: undefined,
      type: 'table',
      boards: ['all', 'conversations'],
      data: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    misunderstoodCount: {
      title: 'Number of misunderstood messages',
      url: 'messages/miss',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'conversations'],
      chart: undefined,
      percent: false,
      loaded: false,
      error: '',
      visible: true,
    },
    misunderstoodIntents: {
      title: 'Misunderstood intents',
      url: 'messages/miss/intents',
      ref: undefined,
      type: 'table',
      boards: ['all', 'conversations'],
      data: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    topIntents: {
      title: 'Top Intents',
      url: {
        all: 'intents',
        entrance: 'intents/entrance',
        leaving: 'intents/leaving',
        entranceReturning: 'intents/entrance/returning',
        leavingReturning: 'intents/leaving/returning',
      },
      ref: undefined,
      type: 'chart',
      boards: ['all', 'conversations'],
      view: 'all',
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    dauMau: {
      title: 'DAU, MAU, DAU/MAU',
      url: 'users/activity',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'users'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    retention: {
      title: 'Retention and chunk',
      url: 'users/retention',
      ref: undefined,
      type: 'table',
      boards: ['all', 'activity'],
      data: undefined,
      existing: false,
      percent: false,
      period: 'weeks',
      loaded: false,
      error: '',
      visible: true,
    },
    intentStats: {
      title: 'Intent traffic',
      url: 'intents/users',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'conversations'],
      chart: undefined,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    activity: {
      title: 'Weekly user activity by time of day',
      url: 'messages/hourly',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      chart: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    funnel: {
      title: 'Funnel',
      url: 'users/funnel',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'activity'],
      chart: undefined,
      intents: [],
      intentsTree: [],
      loaded: false,
      error: '',
      visible: true,
    },
    flowThroughHandler: {
      title: 'Flow through handler',
      url: 'intents/flow_through_intent',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'conversations'],
      chart: undefined,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
    conversationalFlow: {
      title: 'Conversational flow',
      url: 'intents/conversation_flow',
      ref: undefined,
      type: 'chart',
      boards: ['all', 'conversations'],
      chart: undefined,
      intent: undefined,
      loaded: false,
      error: '',
      visible: true,
    },
  },
};

const metricsReducer = (
  state = initialState,
  action: MetricsActionType,
): MetricsState => {
  switch (action.type) {
    case MetricsActionTypeConstant.UPDATE_METRIC_REF: {
      return {
        ...state,
        metric: {
          ...state.metric,
          [action.key]: {
            ...(state.metric as any)[action.key],
            ref: action.ref,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NEW_USERS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          newUsers: {
            ...state.metric.newUsers,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NEW_USERS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          newUsers: {
            ...state.metric.newUsers,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NEW_USERS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          newUsers: {
            ...state.metric.newUsers,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNIQUE_USERS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          uniqueUsers: {
            ...state.metric.uniqueUsers,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNIQUE_USERS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          uniqueUsers: {
            ...state.metric.uniqueUsers,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNIQUE_USERS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          uniqueUsers: {
            ...state.metric.uniqueUsers,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessions: {
            ...state.metric.sessions,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessions: {
            ...state.metric.sessions,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessions: {
            ...state.metric.sessions,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LAST_MSG_DATE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          lastMsgDate: {
            ...state.metric.lastMsgDate,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LAST_MSG_DATE_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          lastMsgDate: {
            ...state.metric.lastMsgDate,
            value: action.value,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LAST_MSG_DATE_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          lastMsgDate: {
            ...state.metric.lastMsgDate,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ENGAGEMENT: {
      return {
        ...state,
        metric: {
          ...state.metric,
          engagement: {
            ...state.metric.engagement,
            view: action.view,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          engagement: {
            ...state.metric.engagement,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ENGAGEMENT_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          engagement: {
            ...state.metric.engagement,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_TOTAL_USERS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          usersTotal: {
            ...state.metric.usersTotal,
            intent: action.intent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_TOTAL_USERS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          usersTotal: {
            ...state.metric.usersTotal,
            value: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_TOTAL_USERS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          usersTotal: {
            ...state.metric.usersTotal,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENTS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          topIntents: {
            ...state.metric.topIntents,
            view: action.view,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENTS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          topIntents: {
            ...state.metric.topIntents,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENTS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          topIntents: {
            ...state.metric.topIntents,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_DAU_MAU: {
      return {
        ...state,
        metric: {
          ...state.metric,
          dauMau: {
            ...state.metric.dauMau,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_DAU_MAU_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          dauMau: {
            ...state.metric.dauMau,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_DAU_MAU_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          dauMau: {
            ...state.metric.dauMau,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSION: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversion: {
            ...state.metric.conversion,
            intent: action.intent,
            percent: action.percent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSION_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversion: {
            ...state.metric.conversion,
            value: action.value,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSION_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversion: {
            ...state.metric.conversion,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER: {
      return {
        ...state,
        metric: {
          ...state.metric,
          flowThroughHandler: {
            ...state.metric.flowThroughHandler,
            intent: action.intent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          flowThroughHandler: {
            ...state.metric.flowThroughHandler,
            chart: action.chart,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          flowThroughHandler: {
            ...state.metric.flowThroughHandler,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversationalFlow: {
            ...state.metric.conversationalFlow,
            intent: action.intent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversationalFlow: {
            ...state.metric.conversationalFlow,
            chart: action.chart,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          conversationalFlow: {
            ...state.metric.conversationalFlow,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNSUBSCRIBED: {
      return {
        ...state,
        metric: {
          ...state.metric,
          unsubscribed: {
            ...state.metric.unsubscribed,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNSUBSCRIBED_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          unsubscribed: {
            ...state.metric.unsubscribed,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_UNSUBSCRIBED_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          unsubscribed: {
            ...state.metric.unsubscribed,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodCount: {
            ...state.metric.misunderstoodCount,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodCount: {
            ...state.metric.misunderstoodCount,
            chart: action.data,
            percent: action.percent,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodCount: {
            ...state.metric.misunderstoodCount,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstood: {
            ...state.metric.misunderstood,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstood: {
            ...state.metric.misunderstood,
            data: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstood: {
            ...state.metric.misunderstood,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES_COUNT: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messagesCount: {
            ...state.metric.messagesCount,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES_COUNT_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messagesCount: {
            ...state.metric.messagesCount,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES_COUNT_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messagesCount: {
            ...state.metric.messagesCount,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messages: {
            ...state.metric.messages,
            intent: action.intent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messages: {
            ...state.metric.messages,
            data: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MESSAGES_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          messages: {
            ...state.metric.messages,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSION_STATS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsAvgTime: {
            ...state.metric.sessionsAvgTime,
            loaded: false,
            error: '',
          },
          sessionsAvgCount: {
            ...state.metric.sessionsAvgCount,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSION_STATS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsAvgTime: {
            ...state.metric.sessionsAvgTime,
            value: action.avgTime,
            loaded: true,
          },
          sessionsAvgCount: {
            ...state.metric.sessionsAvgCount,
            value: action.avgSessions,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSION_STATS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsAvgTime: {
            ...state.metric.sessionsAvgTime,
            error: action.error,
          },
          sessionsAvgCount: {
            ...state.metric.sessionsAvgCount,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NPS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          nps: {
            ...state.metric.nps,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NPS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          nps: {
            ...state.metric.nps,
            value: action.value,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_NPS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          nps: {
            ...state.metric.nps,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LIKES: {
      return {
        ...state,
        metric: {
          ...state.metric,
          likes: {
            ...state.metric.likes,
            percent: action.percent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LIKES_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          likes: {
            ...state.metric.likes,
            data: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_LIKES_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          likes: {
            ...state.metric.likes,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_REFS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          refs: {
            ...state.metric.refs,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_REFS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          refs: {
            ...state.metric.refs,
            data: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_REFS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          refs: {
            ...state.metric.refs,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENT_STATS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          intentStats: {
            ...state.metric.intentStats,
            intent: action.intent,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENT_STATS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          intentStats: {
            ...state.metric.intentStats,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENT_STATS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          intentStats: {
            ...state.metric.intentStats,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_RETENTION: {
      return {
        ...state,
        metric: {
          ...state.metric,
          retention: {
            ...state.metric.retention,
            loaded: false,
            error: '',
            period: action.period,
            data: undefined,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_RETENTION_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          retention: {
            ...state.metric.retention,
            data: action.data,
            existing: action.existing,
            percent: action.percent,
            loaded: true,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_RETENTION_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          retention: {
            ...state.metric.retention,
            error: state.metric.retention.data ? '' : action.error,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ACTIVITY: {
      return {
        ...state,
        metric: {
          ...state.metric,
          activity: {
            ...state.metric.activity,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ACTIVITY_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          activity: {
            ...state.metric.activity,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_ACTIVITY_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          activity: {
            ...state.metric.activity,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FUNNEL: {
      return {
        ...state,
        metric: {
          ...state.metric,
          funnel: {
            ...state.metric.funnel,
            intents: action.intents,
            intentsTree: action.intentsTree,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FUNNEL_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          funnel: {
            ...state.metric.funnel,
            chart: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_FUNNEL_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          funnel: {
            ...state.metric.funnel,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENTS_TREE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          funnel: {
            ...state.metric.funnel,
            intentsTree: state.metric.funnel.intentsTree.map(i =>
              i.label === action.intent ? { ...i, loading: true } : i,
            ),
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_INTENTS_TREE_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          funnel: {
            ...state.metric.funnel,
            intentsTree: state.metric.funnel.intentsTree.map(i =>
              i.label === action.intentsTree[0].label
                ? { ...action.intentsTree[0], loading: false }
                : i,
            ),
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodIntents: {
            ...state.metric.misunderstoodIntents,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodIntents: {
            ...state.metric.misunderstoodIntents,
            data: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          misunderstoodIntents: {
            ...state.metric.misunderstoodIntents,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS_COUNT: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsCount: {
            ...state.metric.sessionsCount,
            loaded: false,
            error: '',
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS_COUNT_SUCCESS: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsCount: {
            ...state.metric.sessionsCount,
            value: action.data,
            loaded: true,
          },
        },
      };
    }

    case MetricsActionTypeConstant.GET_SESSIONS_COUNT_FAILURE: {
      return {
        ...state,
        metric: {
          ...state.metric,
          sessionsCount: {
            ...state.metric.sessionsCount,
            error: action.error,
          },
        },
      };
    }

    case MetricsActionTypeConstant.SET_METRIC_INTENT: {
      return {
        ...state,
        metric: {
          ...state.metric,
          [action.metric]: {
            ...state.metric[action.metric as MetricName],
            intent: action.intent,
            intents: action.intents,
            intentsTree: action.intentsTree,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default metricsReducer;
