import {
  AccountActionType,
  AccountActionTypeConstant,
  AccountState,
} from 'types/account';

const initialState: AccountState = {
  firstSession: false,
  auth: { passed: false, checking: true, error: '' },
  bots: {
    current: null,
    list: [],
    error: '',
    loading: true,
  },
  user: {
    data: {
      name: null,
      email: null,
      company: null,
      image: null,
      plan: null,
      regDate: null,
      trialExpDate: null,
    },
    error: '',
    loading: true,
  },
  logIn: {
    error: '',
    prompt: '',
  },
  signUp: {
    error: '',
    prompt: '',
  },
  resetPwd: {
    error: '',
    prompt: '',
  },
  changePwd: {
    error: '',
    prompt: '',
  },
  verifyUser: {
    error: '',
    prompt: '',
  },
  updateUserData: {
    error: '',
    prompt: '',
    loading: false,
  },
  createBot: {
    error: '',
    prompt: '',
    loading: false,
    intercomLoading: false,
    token: null,
  },
  deleteBot: {
    error: '',
    prompt: '',
    loading: false,
  },
};

const accountReducer = (
  state = initialState,
  action: AccountActionType,
): AccountState => {
  switch (action.type) {
    case AccountActionTypeConstant.LOG_IN: {
      return {
        ...state,
        logIn: { ...state.logIn, prompt: '', error: '' },
      };
    }

    case AccountActionTypeConstant.LOG_IN_SUCCESS: {
      return {
        ...state,
        logIn: { ...state.logIn, prompt: action.prompt, error: '' },
        firstSession: action.firstSession,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            email: action.email,
          },
        },
      };
    }

    case AccountActionTypeConstant.LOG_IN_FAILURE: {
      return {
        ...state,
        logIn: { ...state.logIn, error: action.error },
      };
    }

    case AccountActionTypeConstant.SIGN_UP: {
      return {
        ...state,
        signUp: { ...state.signUp, prompt: '', error: '' },
      };
    }

    case AccountActionTypeConstant.SIGN_UP_SUCCESS: {
      return {
        ...state,
        signUp: { ...state.signUp, prompt: action.prompt, error: '' },
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            email: action.email,
          },
        },
      };
    }

    case AccountActionTypeConstant.SIGN_UP_FAILURE: {
      return {
        ...state,
        signUp: { ...state.signUp, error: action.error },
      };
    }

    case AccountActionTypeConstant.RESET_PASSWORD: {
      return {
        ...state,
        resetPwd: { ...state.resetPwd, prompt: '', error: '' },
      };
    }

    case AccountActionTypeConstant.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPwd: { ...state.resetPwd, prompt: action.prompt, error: '' },
      };
    }

    case AccountActionTypeConstant.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        resetPwd: { ...state.resetPwd, error: action.error },
      };
    }

    case AccountActionTypeConstant.VERIFY_USER: {
      return {
        ...state,
        verifyUser: { ...state.verifyUser, prompt: '', error: '' },
      };
    }

    case AccountActionTypeConstant.VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verifyUser: { ...state.verifyUser, prompt: action.prompt, error: '' },
      };
    }

    case AccountActionTypeConstant.VERIFY_USER_FAILURE: {
      return {
        ...state,
        verifyUser: { ...state.verifyUser, error: action.error },
      };
    }

    case AccountActionTypeConstant.CHANGE_PASSWORD: {
      return {
        ...state,
        changePwd: { ...state.changePwd, prompt: '', error: '' },
      };
    }

    case AccountActionTypeConstant.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePwd: { ...state.changePwd, prompt: action.prompt, error: '' },
      };
    }

    case AccountActionTypeConstant.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        changePwd: { ...state.changePwd, error: action.error, prompt: '' },
      };
    }

    case AccountActionTypeConstant.LOG_OUT: {
      return {
        ...state,
        auth: {
          ...state.auth,
          passed: false,
        },
      };
    }

    case AccountActionTypeConstant.GET_USER_DATA: {
      return {
        ...state,
        user: { ...state.user, error: '', loading: true },
      };
    }

    case AccountActionTypeConstant.GET_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          data: action.data,
          error: '',
          loading: false,
        },
      };
    }

    case AccountActionTypeConstant.GET_USER_DATA_FAILURE: {
      return {
        ...state,
        user: { ...state.user, error: action.error },
      };
    }

    case AccountActionTypeConstant.UPDATE_USER_DATA: {
      return {
        ...state,
        updateUserData: {
          ...state.updateUserData,
          error: '',
          prompt: '',
          loading: true,
        },
      };
    }

    case AccountActionTypeConstant.UPDATE_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...action.data,
          },
        },
        updateUserData: {
          ...state.updateUserData,
          prompt: action.prompt,
          loading: false,
        },
      };
    }

    case AccountActionTypeConstant.UPDATE_USER_DATA_FAILURE: {
      return {
        ...state,
        updateUserData: { ...state.updateUserData, error: action.error },
      };
    }

    case AccountActionTypeConstant.GET_BOTS_LIST: {
      return {
        ...state,
        bots: { ...state.bots, error: '', loading: true },
      };
    }

    case AccountActionTypeConstant.GET_BOTS_LIST_SUCCESS: {
      return {
        ...state,
        bots: {
          ...state.bots,
          current: action.botInit,
          list: action.bots,
          error: '',
          loading: false,
        },
      };
    }

    case AccountActionTypeConstant.GET_BOTS_LIST_FAILURE: {
      return {
        ...state,
        bots: { ...state.bots, error: action.error },
      };
    }

    case AccountActionTypeConstant.SWITCH_BOT: {
      return {
        ...state,
        bots: {
          ...state.bots,
          current: state.bots.list.filter(b => b.id === +action.botId)[0],
        },
      };
    }

    case AccountActionTypeConstant.CHECK_AUTH: {
      return {
        ...state,
        auth: {
          ...state.auth,
          error: '',
          checking: true,
        },
      };
    }

    case AccountActionTypeConstant.CHECK_AUTH_SUCCESS: {
      return {
        ...state,
        firstSession: action.firstSession,
        auth: {
          ...state.auth,
          checking: false,
          passed: true,
        },
      };
    }

    case AccountActionTypeConstant.CHECK_AUTH_FAILURE: {
      return {
        ...state,
        auth: {
          ...state.auth,
          error: action.error,
          checking: false,
          passed: false,
        },
      };
    }

    case AccountActionTypeConstant.CREATE_INTERCOM_BOT: {
      return {
        ...state,
        createBot: {
          ...state.createBot,
          error: '',
          prompt: '',
          intercomLoading: true,
        },
      };
    }

    case AccountActionTypeConstant.CREATE_BOT: {
      return {
        ...state,
        createBot: {
          ...state.createBot,
          error: '',
          prompt: '',
          loading: true,
        },
      };
    }

    case AccountActionTypeConstant.CREATE_INTERCOM_BOT_SUCCESS:
    case AccountActionTypeConstant.CREATE_BOT_SUCCESS: {
      return {
        ...state,
        createBot: {
          ...state.createBot,
          prompt: action.prompt,
          loading: false,
          intercomLoading: false,
          token: action.botToken,
        },
        bots: {
          ...state.bots,
          list: state.bots.list.concat({
            id: action.botId,
            name: action.botName,
            token: action.botToken,
            timestamp: action.timestamp,
            anonymized: action.anonymized,
          }),
        },
      };
    }

    case AccountActionTypeConstant.CREATE_INTERCOM_BOT_FAILURE:
    case AccountActionTypeConstant.CREATE_BOT_FAILURE: {
      return {
        ...state,
        createBot: {
          ...state.createBot,
          loading: false,
          intercomLoading: false,
          error: action.error,
        },
      };
    }

    case AccountActionTypeConstant.DELETE_BOT: {
      return {
        ...state,
        deleteBot: {
          ...state.deleteBot,
          error: '',
          prompt: '',
          loading: true,
        },
      };
    }

    case AccountActionTypeConstant.DELETE_BOT_SUCCESS: {
      return {
        ...state,
        deleteBot: {
          ...state.deleteBot,
          prompt: action.prompt,
          loading: false,
        },
        bots: {
          ...state.bots,
          current:
            action.newCurrentBot === null
              ? state.bots.current
              : action.newCurrentBot,
          list: state.bots.list.filter(bot => bot.id !== action.id),
        },
      };
    }

    case AccountActionTypeConstant.DELETE_BOT_FAILURE: {
      return {
        ...state,
        deleteBot: {
          ...state.deleteBot,
          error: action.error,
        },
      };
    }

    case AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION: {
      return {
        ...state,
      };
    }

    case AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION_SUCCESS: {
      return {
        ...state,
      };
    }

    case AccountActionTypeConstant.UPDATE_BOT_ANONYMIZATION_FAILURE: {
      return {
        ...state,
        createBot: {
          ...state.createBot,
          error: action.error,
        },
      };
    }

    default:
      return state;
  }
};

export default accountReducer;
