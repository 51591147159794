import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Space, Input } from 'antd';
import { gold } from '@ant-design/colors';
import Highlighter from 'react-highlight-words';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

export const getColumnSearchProps = (
  dataIndex: string,
  searchText: string,
  setSearchText: Function,
  searchedColumn: string,
  setSearchedColumn: Function,
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: any) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={i18n.t('Search')}
        value={selectedKeys[0]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          confirm();
          setSearchText(selectedKeys[0]);
          setSearchedColumn(dataIndex);
        }}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          size="small"
          style={{ width: 90 }}
        >
          <Trans>Search</Trans>
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            setSearchText('');
          }}
          size="small"
          style={{ width: 90 }}
        >
          <Trans>Reset</Trans>
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? gold[5] : undefined }} />
  ),
  onFilter: (value: string, record: any) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : '',
  render: (text: string) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: `${gold[5]}`, padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});
