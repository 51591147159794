import React, { RefObject } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Button, Space } from 'antd';
import Carousel from 'antd/lib/carousel';

export default styled.div`
  padding: 24px;
  overflow: auto;

  > * {
    display: block;
  }

  > *:first-child {
    margin-top: 0;
  }

  img {
    max-width: 100%;
    max-height: 30vh;
    margin: 25px 0;
    border-radius: 5px;
  }

  p {
    margin-bottom: 5px;
    padding: 0 26px;
  }

  h2 {
    margin: 25px 0 5px 0;
    padding: 0 26px;
  }
`;

export type CarouselType = { carousel: RefObject<Carousel> };

export const SpaceCenterer = styled(Space)`
  display: flex !important;
  justify-content: center;
`;

export const NextButton = ({ carousel }: CarouselType) => (
  <Button
    type="primary"
    onClick={() => carousel.current?.next()}
    style={{ margin: '40px auto 0' }}
  >
    <Trans>Next</Trans>
  </Button>
);

export const PrevButton = ({ carousel }: CarouselType) => (
  <Button
    onClick={() => carousel.current?.prev()}
    style={{ margin: '40px auto 0' }}
  >
    <Trans>Previous</Trans>
  </Button>
);
