import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, message } from 'antd';

import { grey } from '@ant-design/colors';
import { CopyOutlined } from '@ant-design/icons';

const CopyButtonWrapper = styled(Button)`
  position: absolute;
  right: 0;
  transform: scale(0.85);

  &,
  :hover,
  :focus {
    background-color: #fff;
  }

  svg {
    fill: ${grey[6]};

    :hover {
      fill: ${grey[9]};
    }
  }
`;

type CopyButtonProps = {
  text: string;
};

const CopyButton = ({ text }: CopyButtonProps) => {
  const { t } = useTranslation();
  return (
    <CopyToClipboard
      key="copy"
      text={text}
      onCopy={() => message.success(t('Token is copied to clipboard'))}
    >
      <CopyButtonWrapper type="link" icon={<CopyOutlined />} />
    </CopyToClipboard>
  );
};

export default CopyButton;
