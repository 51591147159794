import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

import { RootState } from 'reducers';
import { capitalize } from 'helpers/utils';
import { planNames } from 'helpers/configs/plans';

import { Title } from '../ui';

const ContentWrapper = styled.div`
  max-width: 480px;
`;

const Plan = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: black;
  line-height: 24px;
  margin: -10px 0 40px;
`;

const SkeletonSpan = styled(Skeleton.Input)<{ height?: number }>`
  display: inline;

  .ant-skeleton-input {
    width: 110px;
    height: ${props => props.height || 16.8}px;
    transform: translateY(2px);
  }
`;

const Expiration = styled.div``;

const ExpirationSkeleton = styled(Skeleton)`
  li {
    height: 14px !important;
    margin: 0 0 8px !important;

    :last-child {
      margin-top: 24px !important;
    }
  }
`;

const ExpandPlan = styled.div`
  margin-top: 40px;
  font-size: 24px;
  color: black;
`;

const ExpandPlanSkeleton = styled(Skeleton)`
  li {
    height: 24px !important;
  }
`;

type Props = ReturnType<typeof mapStateToProps>;

const Account = ({ user }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('Account settings')}</Title>

      <ContentWrapper>
        <p>{t('Your OneDash plan:')}</p>

        <Plan>
          {user.loading ? (
            <SkeletonSpan active height={24} />
          ) : (
            `${capitalize(user.data.plan)}`
          )}
        </Plan>

        {/* <Expiration>
          {user.loading ? (
            <ExpirationSkeleton active title={false} paragraph={{ rows: 3 }} />
          ) : (
            user.data.plan === 'demo' && (
              <>
                <p>
                  <Trans>
                    This subscription is valid for 2 weeks from the registration
                    date and allows you to connect no more than one bot
                  </Trans>
                </p>

                <p>
                  <b style={{ color: 'black' }}>{t('Expiration date:')}</b>
                  {` ${user.data.trialExpDate?.format('Do MMMM, YYYY')}`}
                </p>
              </>
            )
          )}
        </Expiration> */}

        <ExpandPlan>
          {user.loading ? (
            <ExpandPlanSkeleton active title={false} paragraph={{ rows: 2 }} />
          ) : (
            user.data.plan !== 'enterprise' && (
              <>
                <p>
                  {t('To expand your subscription to ')}
                  <b>
                    {planNames
                      .slice(
                        planNames.indexOf(user.data.plan || 'demo') + 1,
                        -1,
                      )
                      .map(capitalize)
                      .join('\u00A0or\u00A0')}
                  </b>
                  {t(', please contact us at ')}
                  <a href="mailto:onedash@eora.ru">{t('onedash@eora.ru')}</a>
                </p>
                <p>
                  {t('You can see our subscription plans')}
                  <a
                    href="https://onedash.cc/pricing?ref=billingpage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('\u00A0here')}
                  </a>
                </p>
              </>
            )
          )}
        </ExpandPlan>
      </ContentWrapper>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  user: store.account.user,
});

export default connect(mapStateToProps)(Account);
