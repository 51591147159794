import React, { useEffect, useMemo, useCallback } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Menu, Layout, Tooltip } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { gold } from '@ant-design/colors';
import { history } from 'configureStore';

import {
  switchSiderMenuItem,
  setCurrentDashboard,
  setTutorialVisible,
} from 'actions/dashboard';
import { RootState } from 'reducers';
import { mergeArraysByKey } from 'helpers/utils';
import { MenuItemLink as Link } from 'components/ui';
import { plansConfig } from 'helpers/configs/plans';
import {
  sidebarConfig,
  Section as SidebarSection,
  Sidebar as SidebarT,
} from 'helpers/configs/sidebar';

const { Sider } = Layout;
const { SubMenu } = Menu;

const HeaderLogo = styled(Link)`
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #f0f0f0;

  .img-wrapper img {
    height: 32px;
  }

  .label {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: black;
  }
`;

const MenuWrapper = styled(Menu)`
  height: calc(100vh - 230px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const BottomMenu = styled.div`
  height: 150px;
  position: relative;
`;

const FieldWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .img-wrapper {
    text-align: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 80px;
  }

  .label {
    display: flex;
    flex-shrink: 0;
    transition: opacity 0.3s ease;
    opacity: ${props => +!props.collapsed};
    margin-left: -14px;
  }
`;

const MenuButton = styled.button`
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: 0;
  right: 0;
  padding: 15px;
  transition: color 0.3s;
  background-color: white;

  &:hover {
    color: ${gold[5]};
  }
`;

const Ellipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const getSectionBody = (section: SidebarSection) => (
  <>
    {section.icon && <section.icon />}
    <span>
      <Trans>{section.value}</Trans>
    </span>
    {section.sup && (
      <sup>
        <i>{section.sup}</i>
      </sup>
    )}
  </>
);

type Props = { collapsed: boolean; toggleCollapsed: () => void } & ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>;

const Sidebar = ({
  user,
  collapsed,
  toggleCollapsed,
  dashboards,
  siderMenuItem,
  updSiderMenuItem,
  updCurrentDashboard,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const menuDisabled = useCallback(
    (key: string) => {
      const { section } = plansConfig;
      const { plan } = user.data;

      if (plan === null || user.loading) {
        return true;
      }
      if (!section[key]) {
        return false;
      }
      return !section[key][plan].granted;
    },
    [user],
  );

  const renderSection = useCallback(
    (section: SidebarSection, path = '') => {
      const { key, disabled, children, to } = section;
      const newPath = `${path}/${key}`;
      const onClick =
        to === null ? section.onClick : () => history.push(newPath);

      return children ? (
        <SubMenu
          key={key}
          onTitleClick={onClick}
          disabled={disabled}
          title={<span>{getSectionBody(section)}</span>}
        >
          {children.map(subSection => renderSection(subSection, newPath))}
        </SubMenu>
      ) : (
        <Menu.Item
          key={key}
          onClick={onClick}
          disabled={disabled || menuDisabled(key)}
        >
          <Tooltip
            title={
              menuDisabled(key) && (
                <>
                  <Trans>Upgrade your plan in </Trans>
                  <Link to="/settings/account">
                    <Trans>Settings</Trans>
                  </Link>
                </>
              )
            }
            placement="right"
          >
            <Ellipsis>{getSectionBody(section)}</Ellipsis>
          </Tooltip>
        </Menu.Item>
      );
    },
    [menuDisabled],
  );

  useEffect(() => {
    const [section, subSection] = pathname.split('/').splice(1);

    updSiderMenuItem(
      section === 'settings'
        ? section || siderMenuItem
        : subSection || section || siderMenuItem,
    );

    updCurrentDashboard(section === 'dashboards' ? subSection || null : null);
  }, [pathname, updSiderMenuItem, updCurrentDashboard, siderMenuItem]);

  const sidebarSections = useMemo(
    () =>
      mergeArraysByKey<SidebarT, SidebarT>(
        sidebarConfig,
        [
          {
            key: 'tutorial',
            onClick: () => dispatch(setTutorialVisible(true)),
          },
        ],
        'key',
      ).map(section =>
        renderSection({ ...section, value: t(section.value || '') }),
      ),
    [dispatch, renderSection, t],
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      trigger={null}
      theme="light"
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 99,
      }}
    >
      <HeaderLogo to="/dashboards/all">
        <FieldWrapper collapsed={collapsed}>
          <div className="img-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/logo192.png`}
              alt="OneDash logo"
            />
          </div>
          <span className="label">OneDash</span>
        </FieldWrapper>
      </HeaderLogo>

      <MenuWrapper
        theme="light"
        selectedKeys={[siderMenuItem]}
        mode="inline"
        onSelect={({ key }: { key: string }) => {
          updSiderMenuItem(key);
          updCurrentDashboard(key);
        }}
      >
        {sidebarSections}
      </MenuWrapper>

      <BottomMenu>
        <MenuButton onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </MenuButton>
      </BottomMenu>
    </Sider>
  );
};

const mapStateToProps = (store: RootState) => ({
  user: store.account.user,
  siderMenuItem: store.dashboard.siderMenuItem,
  dashboards: store.dashboard.list,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updSiderMenuItem: switchSiderMenuItem,
      updCurrentDashboard: setCurrentDashboard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
