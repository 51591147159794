import axios from 'axios';
import { DatesRange } from 'types/dashboard';

export type MetricOptions = {
  [key: string]: string | string[] | boolean | number | undefined;
};

export const getMetric = <Response>(
  url: string,
  botId: number,
  filter?: { ref?: string; channel?: string; datesRange?: DatesRange },
  options?: MetricOptions,
  sharingToken?: string,
) =>
  sharingToken === undefined
    ? axios.post<Response>(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/metric`,
        {
          metric: url,
          bot_id: botId,
          data: {
            ...options,
            ref: filter?.ref,
            channel: filter?.channel,
            start_date: filter?.datesRange?.startDate.format('YYYY-MM-DD'),
            end_date: filter?.datesRange?.endDate.format('YYYY-MM-DD'),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        },
      )
    : axios.post<Response>(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/metric/share`,
        {
          metric: url,
          share_token: sharingToken,
          data: {
            ...options,
            ref: filter?.ref,
            start_date: filter?.datesRange?.startDate.format('YYYY-MM-DD'),
            end_date: filter?.datesRange?.endDate.format('YYYY-MM-DD'),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        },
      );
