import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { verifyUser } from 'actions/account';
import { RootState } from 'reducers';
import { ResponseMsgBox } from 'components/ui';

import { HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Verification = ({
  error,
  prompt,
  search,
  verifyUsr,
  replaceUrl,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');

    if (token) {
      verifyUsr(token);
    } else {
      replaceUrl('/auth/login');
    }
  }, [search, verifyUsr, replaceUrl]);

  return (
    <>
      <Title>{t('Your account is being verified...')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <HelpMessage>
        If you aren&apos;t redirected automatically within 10 seconds, please do
        it manually by pressing the button below
      </HelpMessage>

      <Button
        type="primary"
        htmlType="submit"
        block
        size="large"
        style={{ borderRadius: '4px', margin: '24px 0' }}
        onClick={() => replaceUrl('/auth/login')}
      >
        {t('Redirect')}
      </Button>

      <HelpMessage>
        {`${t('If you have any problems, please')} `}
        <a href="mailto:onedash@eora.ru">{t('contact us')}</a>
      </HelpMessage>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  error: store.account.verifyUser.error,
  prompt: store.account.verifyUser.prompt,
  search: store.router.location.search,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      verifyUsr: verifyUser,
      replaceUrl: replace,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
