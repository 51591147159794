/* eslint-disable camelcase */
import { Dispatch } from 'redux';
import i18n from 'i18next';
import {
  MetricsActionType,
  MetricsActionTypeConstant,
  ChartData,
  TableDataBackend,
  TableData,
  SankeyDataBackend,
  SankeyData,
  ScatterDataBackend,
  ScatterData,
  FunnelData,
  MetricPanelOption,
  IntentPanelOptionSelected,
  UnitPanelOptionSelected,
  MetricName,
  LikesData,
  PaginationTablePage,
  EngagementData,
} from 'types/metrics';
import { RootState } from 'reducers';
import {
  mapToChartData,
  mapToIntentsChartData,
  mapToTableData,
  mapToSankeyData,
  mapToScatterData,
  mapSankeyToTree,
  getRelativeDate,
} from 'helpers/utils';
import { getMetric } from 'api/metrics';

export const updateMetricRef = (key: string, ref: any) => ({
  type: MetricsActionTypeConstant.UPDATE_METRIC_REF,
  key,
  ref,
});

const getUsersTotalSuccess = (data: number): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_TOTAL_USERS_SUCCESS,
  data,
});

const getUsersTotalFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_TOTAL_USERS_FAILURE,
  error,
});

export const getUsersTotal = (options?: IntentPanelOptionSelected | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          usersTotal: { intent: intentInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent || (options === null ? intentInit : undefined);

    dispatch({ type: MetricsActionTypeConstant.GET_TOTAL_USERS, intent });

    try {
      type Response = {
        result: number;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          intent,
        },
        sharingToken,
      );

      dispatch(getUsersTotalSuccess(+result));
    } catch (error) {
      dispatch(
        getUsersTotalFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getConversionSuccess = (value: number): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_CONVERSION_SUCCESS,
  value,
});

const getConversionFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_CONVERSION_FAILURE,
  error,
});

type ConversionPanelOptionsSelected = IntentPanelOptionSelected &
  UnitPanelOptionSelected;

export const getConversion = (
  options?: ConversionPanelOptionsSelected | null,
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          conversion: { intent: intentInit, percent: initPercent, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent ||
      (options === null || options?.unit ? intentInit : undefined);

    const percent = options?.unit ? options.unit === 'percent' : initPercent;

    dispatch({
      type: MetricsActionTypeConstant.GET_CONVERSION,
      intent,
      percent,
    });

    if (intent === undefined) {
      dispatch(getConversionSuccess(0));
      return;
    }

    try {
      type Response = {
        result: number;
        percent: number;
      };

      const { data } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          intent,
        },
        sharingToken,
      );

      dispatch(
        getConversionSuccess(
          percent ? +(data.percent * 100).toFixed(2) : data.result,
        ),
      );
    } catch (error) {
      dispatch(
        getConversionFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getSessionsCountSuccess = (data: number): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSIONS_COUNT_SUCCESS,
  data,
});

const getSessionsCountFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSIONS_COUNT_FAILURE,
  error,
});

export const getSessionsCount = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_SESSIONS_COUNT });

    try {
      type Response = {
        result: number;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.sessionsCount.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getSessionsCountSuccess(+result));
    } catch (error) {
      dispatch(
        getSessionsCountFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getNewUsersSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_NEW_USERS_SUCCESS,
  data,
});

const getNewUsersFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_NEW_USERS_FAILURE,
  error,
});

export const getNewUsers = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_NEW_USERS });

    try {
      type Response = {
        result: ChartData;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.newUsers.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange);

      dispatch(getNewUsersSuccess(chartData));
    } catch (error) {
      dispatch(
        getNewUsersFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getUniqueUsersSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_UNIQUE_USERS_SUCCESS,
  data,
});

const getUniqueUsersFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_UNIQUE_USERS_FAILURE,
  error,
});

export const getUniqueUsers = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_UNIQUE_USERS });

    try {
      type Response = {
        result: ChartData;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.uniqueUsers.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange);

      dispatch(getUniqueUsersSuccess(chartData));
    } catch (error) {
      dispatch(
        getUniqueUsersFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getUnsubscribedSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_UNSUBSCRIBED_SUCCESS,
  data,
});

const getUnsubscribedFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_UNSUBSCRIBED_FAILURE,
  error,
});

export const getUnsubscribed = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_UNSUBSCRIBED });

    try {
      type Response = {
        result: ChartData;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.unsubscribed.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange);

      dispatch(getUnsubscribedSuccess(chartData));
    } catch (error) {
      dispatch(
        getUnsubscribedFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getEngagementSuccess = (data: EngagementData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_ENGAGEMENT_SUCCESS,
  data,
});

const getEngagementFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_ENGAGEMENT_FAILURE,
  error,
});

type EngagementPanelOptions = {
  view?: 'time' | 'sessions' | 'interactions';
};

export const getEngagement = (options?: EngagementPanelOptions | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          engagement: { chart, view: viewInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const view = options?.view || viewInit;

    dispatch({ type: MetricsActionTypeConstant.GET_ENGAGEMENT, view });

    if (viewInit !== view) {
      if (chart) {
        dispatch(getEngagementSuccess(chart));
      }
      return;
    }

    try {
      type Response = {
        result: {
          time: ChartData;
          sessions: ChartData;
          interactions: ChartData;
        };
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = {
        time: mapToChartData(
          result.time.map(r => ({
            ...r,
            count: +(r.count as number).toFixed(2),
          })),
          filter.datesRange,
        ),
        sessions: mapToChartData(
          result.sessions.map(r => ({
            ...r,
            count: +(r.count as number).toFixed(2),
          })),
          filter.datesRange,
        ),
        interactions: mapToChartData(
          result.interactions.map(r => ({
            ...r,
            count: +(r.count as number).toFixed(2),
          })),
          filter.datesRange,
        ),
      };

      dispatch(getEngagementSuccess(chartData));
    } catch (error) {
      dispatch(
        getEngagementFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getSessionsSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSIONS_SUCCESS,
  data,
});

const getSessionsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSIONS_FAILURE,
  error,
});

export const getSessions = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_SESSIONS });

    try {
      type Response = {
        result: ChartData;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.sessions.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange);

      dispatch(getSessionsSuccess(chartData));
    } catch (error) {
      dispatch(
        getSessionsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getRefsSuccess = (data: TableData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_REFS_SUCCESS,
  data,
});

const getRefsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_REFS_FAILURE,
  error,
});

export const getRefs = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_REFS });

    try {
      type Response = {
        result: TableDataBackend;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.refs.url,
        bots.current.id,
        {
          datesRange: filter.datesRange,
        },
        undefined,
        sharingToken,
      );

      const header = [
        {
          title: 'Referral',
          key: 'ref',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            a.ref.localeCompare(b.ref),
        },
        {
          title: 'Count',
          key: 'count',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            +a.count - +b.count,
        },
      ];
      dispatch(getRefsSuccess(mapToTableData(result, header)));
    } catch (error) {
      dispatch(
        getRefsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getSessionStatsSuccess = (data: {
  average_time: number;
  average_sessions: number;
}): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSION_STATS_SUCCESS,
  avgTime: Math.round((data.average_time / 60 + Number.EPSILON) * 10) / 10,
  avgSessions: Math.round((data.average_sessions + Number.EPSILON) * 10) / 10,
});

const getSessionStatsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_SESSION_STATS_FAILURE,
  error,
});

export const getSessionStats = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_SESSION_STATS });

    try {
      type Response = {
        result: { average_time: number; average_sessions: number };
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.sessionsAvgTime.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getSessionStatsSuccess(result));
    } catch (error) {
      dispatch(
        getSessionStatsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getNPSSuccess = (value: number | null): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_NPS_SUCCESS,
  value,
});

const getNPSFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_NPS_FAILURE,
  error,
});

export const getNPS = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_NPS });

    try {
      type Response = {
        result: number | null;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.nps.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getNPSSuccess(result ? +result.toFixed(1) : null));
    } catch (error) {
      dispatch(
        getNPSFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getLikesSuccess = (data: LikesData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_LIKES_SUCCESS,
  data,
});

const getLikesFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_LIKES_FAILURE,
  error,
});

export const getLikes = (options?: UnitPanelOptionSelected | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          likes: { data, percent: initPercent, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    try {
      type Response = {
        result: LikesData;
      };

      const percent = options?.unit ? options.unit === 'percent' : initPercent;

      dispatch({
        type: MetricsActionTypeConstant.GET_LIKES,
        percent,
      });

      if (initPercent !== percent) {
        if (data) {
          dispatch(getLikesSuccess(data));
        }
        return;
      }

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getLikesSuccess(result));
    } catch (error) {
      dispatch(
        getLikesFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getMessagesSuccess = (data: TableData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MESSAGES_SUCCESS,
  data,
});

const getMessagesFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MESSAGES_FAILURE,
  error,
});

export const getMessages = (
  options: (IntentPanelOptionSelected & PaginationTablePage) | null = {
    page: 0,
  },
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          messages: { intent: intentInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent || (options === null ? intentInit : undefined);

    dispatch({ type: MetricsActionTypeConstant.GET_MESSAGES, intent });

    try {
      type Response = {
        result: TableDataBackend;
        total_count: number;
      };

      const {
        data: { result, total_count },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          intent: intent || '',
          page_size: 6,
          page: options?.page,
        },
        sharingToken,
      );

      const header = [
        {
          title: 'Message',
          key: 'message',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            a.message.localeCompare(b.message),
        },
        {
          title: 'Count',
          key: 'count',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            +a.count - +b.count,
        },
      ];

      dispatch(
        getMessagesSuccess({
          ...mapToTableData(result, header),
          total: total_count,
        }),
      );
    } catch (error) {
      dispatch(
        getMessagesFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getMessagesCountSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MESSAGES_COUNT_SUCCESS,
  data,
});

const getMessagesCountFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MESSAGES_COUNT_FAILURE,
  error,
});

export const getMessagesCount = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_MESSAGES_COUNT });

    try {
      type Response = {
        result: ChartData;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.messagesCount.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange);

      dispatch(getMessagesCountSuccess(chartData));
    } catch (error) {
      dispatch(
        getMessagesCountFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getMisunderstoodSuccess = (data: TableData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_SUCCESS,
  data,
});

const getMisunderstoodFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_FAILURE,
  error,
});

export const getMisunderstood = (
  pageObject: PaginationTablePage | null = { page: 0 },
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_MISUNDERSTOOD });

    try {
      type Response = {
        result: TableDataBackend;
        total_count: number;
      };

      const {
        data: { result, total_count },
      } = await getMetric<Response>(
        metric.misunderstood.url,
        bots.current.id,
        filter,
        {
          page_size: 6,
          page: pageObject?.page,
        },
        sharingToken,
      );

      const header = [
        {
          title: 'Message',
          key: 'message',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            a.message.localeCompare(b.message),
        },
        {
          title: 'Count',
          key: 'count',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            +a.count - +b.count,
        },
      ];

      dispatch(
        getMisunderstoodSuccess({
          ...mapToTableData(result, header),
          total: total_count,
        }),
      );
    } catch (error) {
      dispatch(
        getMisunderstoodFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getMisunderstoodCountSuccess = (
  data: ChartData,
  percent: boolean,
): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT_SUCCESS,
  data,
  percent,
});

const getMisunderstoodCountFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT_FAILURE,
  error,
});

export const getMisunderstoodCount = (
  options?: UnitPanelOptionSelected | null,
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          misunderstoodCount: { percent: initPercent, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_COUNT });

    try {
      type Response = {
        result: ChartData;
      };

      const percent = options?.unit ? options.unit === 'percent' : initPercent;

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          percent,
        },
        sharingToken,
      );

      const chartData = mapToChartData(result, filter.datesRange).map(m => ({
        ...m,
        count: percent ? +(m.count as number).toFixed(2) : +m.count,
      }));

      dispatch(getMisunderstoodCountSuccess(chartData, percent));
    } catch (error) {
      dispatch(
        getMisunderstoodCountFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getMisunderstoodIntentsSuccess = (
  data: TableData,
): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS_SUCCESS,
  data,
});

const getMisunderstoodIntentsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS_FAILURE,
  error,
});

export const getMisunderstoodIntents = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_MISUNDERSTOOD_INTENTS });

    try {
      type Response = {
        result: TableDataBackend;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.misunderstoodIntents.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const header = [
        {
          title: 'Intent',
          key: 'intent',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            a.intent.localeCompare(b.intent),
        },
        {
          title: 'Count',
          key: 'count',
          sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
            +a.count - +b.count,
        },
      ];

      dispatch(getMisunderstoodIntentsSuccess(mapToTableData(result, header)));
    } catch (error) {
      dispatch(
        getMisunderstoodIntentsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getIntentsSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_INTENTS_SUCCESS,
  data,
});

const getIntentsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_INTENTS_FAILURE,
  error,
});

type IntentPanelOptions = {
  view?:
    | 'all'
    | 'entrance'
    | 'leaving'
    | 'entranceReturning'
    | 'leavingReturning';
};

export const getIntents = (options?: IntentPanelOptions | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          topIntents: { view: viewInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;
    if (options?.view === viewInit) return;

    const view = options?.view || viewInit;

    dispatch({ type: MetricsActionTypeConstant.GET_INTENTS, view });

    try {
      type Response = {
        result: { intent: string; count: string }[];
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url[view],
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const chartData = mapToIntentsChartData(result);

      dispatch(getIntentsSuccess(chartData));
    } catch (error) {
      dispatch(
        getIntentsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getDauMauSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_DAU_MAU_SUCCESS,
  data,
});

const getDauMauFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_DAU_MAU_FAILURE,
  error,
});

export const getDauMau = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_DAU_MAU });

    try {
      type Response = {
        result: {
          daily: { date: string; count: string }[];
          monthly: { date: string; count: string }[];
        };
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.dauMau.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      const daily = mapToChartData(result.daily, filter.datesRange);
      const monthly = mapToChartData(result.monthly, filter.datesRange);
      const chartData = daily.map((rec, i) => ({
        date: rec.date,
        daily: rec.count,
        monthly: monthly[i].count,
        ratio: +(+rec.count / +monthly[i].count).toFixed(2) || 0,
      }));

      dispatch(getDauMauSuccess(chartData));
    } catch (error) {
      dispatch(
        getDauMauFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getRetentionSuccess = (
  data: TableData,
  percent: boolean,
  existing: boolean,
): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_RETENTION_SUCCESS,
  data,
  percent,
  existing,
});

const getRetentionFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_RETENTION_FAILURE,
  error,
});

type RetentionPanelOptions = {
  novelty?: string;
  period?: string;
} & UnitPanelOptionSelected;

export const getRetention = (options?: RetentionPanelOptions | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          retention: {
            percent: initPercent,
            existing: initExisting,
            period: initPeriod,
            url,
          },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const period = options?.period ? options?.period : initPeriod;
    dispatch({ type: MetricsActionTypeConstant.GET_RETENTION, period });

    try {
      type Response = {
        result: [{ date: string; count: number[] }];
      };

      const percent = options?.unit ? options.unit === 'percent' : initPercent;

      const existing = options?.novelty
        ? options.novelty === 'existing'
        : initExisting;

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          percent,
          new: !existing,
          [period]: true,
        },
        sharingToken,
      );

      const { startDate, endDate } = filter.datesRange;
      const numDays = endDate.diff(startDate, 'd') + 1;

      const getHeaderTitle = () => {
        switch (period) {
          case 'days':
            return i18n.languages[0] === 'ru' ? 'День' : 'Day';
          case 'weeks':
            return i18n.languages[0] === 'ru' ? 'Неделя' : 'Week';
          case 'months':
            return i18n.languages[0] === 'ru' ? 'Месяц' : 'Month';

          default:
            return null;
        }
      };

      const header = [
        ...new Array(Math.min(numDays, result.length)).keys(),
      ].map((d: number) => ({
        title: `${getHeaderTitle()} ${d + 1}`,
        key: `day-${d + 1}`,
        sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
          +a[`day-${d + 1}`].slice(0, percent ? -1 : undefined) -
          +b[`day-${d + 1}`].slice(0, percent ? -1 : undefined),
      }));

      const days: { [key: string]: string } = {};
      header.forEach((d: { title: string; key: string }) => {
        days[d.key] = '';
      });

      const resultFormatted = result.map(
        ({ date, count: countList }: { date: string; count: number[] }) => {
          const entry: { [key: string]: string } = { date, ...days };
          countList.forEach((count, i) => {
            entry[`day-${i + 1}`] = `${Math.round(
              (count + Number.EPSILON) * 10,
            ) / 10}${percent ? '%' : ''}`;
          });
          return entry;
        },
      );

      dispatch(
        getRetentionSuccess(
          mapToTableData(resultFormatted, [
            {
              title: 'Date',
              key: 'date',
              sorter: (a: TableDataBackend[0], b: TableDataBackend[0]) =>
                a.date.localeCompare(b.date),
            },
            ...header,
          ]),
          percent,
          existing,
        ),
      );
    } catch (error) {
      dispatch(
        getRetentionFailure(
          error.response.data.detail || error.response
            ? error.response.data.message
            : error.message,
        ),
      );
    }
  };
};

const getIntentStatsSuccess = (data: ChartData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_INTENT_STATS_SUCCESS,
  data,
});

const getIntentStatsFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_INTENT_STATS_FAILURE,
  error,
});

export const getIntentStats = (options?: IntentPanelOptionSelected | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          intentStats: { intent: intentInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent || (options === null ? intentInit : undefined);

    dispatch({ type: MetricsActionTypeConstant.GET_INTENT_STATS, intent });

    if (intent === undefined) {
      dispatch(getIntentStatsSuccess([]));
      return;
    }

    try {
      type Response = {
        result: {
          in: { date: string; count: string }[];
          out: { date: string; count: string }[];
        };
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          intent,
        },
        sharingToken,
      );

      const entered = mapToChartData(result.in, filter.datesRange).map(rec => ({
        date: rec.date,
        entered: +rec.count,
      }));

      const exit = mapToChartData(result.out, filter.datesRange).map(rec => ({
        date: rec.date,
        exit: +rec.count,
      }));

      const chartData = entered.map((rec, i) => ({
        ...rec,
        ...exit[i],
      }));

      dispatch(getIntentStatsSuccess(chartData));
    } catch (error) {
      dispatch(
        getIntentStatsFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getActivitySuccess = (data: ScatterData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_ACTIVITY_SUCCESS,
  data,
});

const getActivityFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_ACTIVITY_FAILURE,
  error,
});

export const getActivity = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_ACTIVITY });

    try {
      type Response = {
        result: ScatterDataBackend;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.activity.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getActivitySuccess(mapToScatterData(result)));
    } catch (error) {
      dispatch(
        getActivityFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getFunnelSuccess = (data: FunnelData): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_FUNNEL_SUCCESS,
  data,
});

const getFunnelFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_FUNNEL_FAILURE,
  error,
});

type FunnelPanelOptions = {
  intents?: string[];
};

export const getFunnel = (options?: FunnelPanelOptions | null) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter, intents },
      metrics: {
        metric: {
          funnel: { intents: intentsInit, intentsTree: intentsTreeInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;
    const intentDefault = intents.default ? [intents.default] : undefined;

    const intentsResulted =
      options?.intents || (options === null ? intentsInit : intentDefault);

    const intentsTree =
      (options === null || options?.intents !== undefined) &&
      intentsTreeInit.length > 0
        ? intentsTreeInit
        : intents.list.map(i => ({
            value: i,
            label: i,
            isLeaf: false,
          }));

    dispatch({
      type: MetricsActionTypeConstant.GET_FUNNEL,
      intents: intentsResulted,
      intentsTree,
    });

    if (intentsResulted === undefined || intentsResulted?.length === 0) {
      dispatch(getFunnelSuccess([]));
      return;
    }

    try {
      type Response = {
        absolute: { intent: string; count: number }[];
        percent: { intent: string; count: number }[];
      };

      const { data } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        {
          intents: intentsResulted,
        },
        sharingToken,
      );

      const colors = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c'];
      const funnelData =
        data === null ||
        data.absolute[0].intent === null ||
        data.absolute[0].count === 0
          ? []
          : data.absolute.map((rec, i) => ({
              ...rec,
              // eslint-disable-next-line no-irregular-whitespace
              intent: `${data.percent[i].intent}: ${
                data.absolute[i].count
                // eslint-disable-next-line no-irregular-whitespace
              } (${data.percent[i].count.toFixed(2)}%)   `,
              percent: data.percent[i].count,
              fill: colors[i],
            }));

      dispatch(getFunnelSuccess(funnelData));
    } catch (error) {
      dispatch(
        getFunnelFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getFlowThroughHandlerSuccess = (
  chart: SankeyData,
): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER_SUCCESS,
  chart,
});

const getFlowThroughHandlerFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER_FAILURE,
  error,
});

export const getFlowThroughHandler = (
  options?: IntentPanelOptionSelected | null,
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          flowThroughHandler: { intent: intentInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent || (options === null ? intentInit : undefined);

    dispatch({
      type: MetricsActionTypeConstant.GET_FLOW_THROUGH_HANDLER,
      intent,
    });

    if (intent === undefined) {
      dispatch(getFlowThroughHandlerSuccess({ nodes: [], links: [] }));
      return;
    }

    try {
      type Response = {
        result: SankeyDataBackend;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        { intent },
        sharingToken,
      );

      dispatch(getFlowThroughHandlerSuccess(mapToSankeyData(result)));
    } catch (error) {
      dispatch(
        getFlowThroughHandlerFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getConversationalFlowSuccess = (
  chart: SankeyData,
): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW_SUCCESS,
  chart,
});

const getConversationalFlowFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW_FAILURE,
  error,
});

export const getConversationalFlow = (
  options?: IntentPanelOptionSelected | null,
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: {
        metric: {
          conversationalFlow: { intent: intentInit, url },
        },
      },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    const intent =
      options?.intent || (options === null ? intentInit : undefined);

    dispatch({
      type: MetricsActionTypeConstant.GET_CONVERSATIONAL_FLOW,
      intent,
    });

    if (intent === undefined) {
      dispatch(getConversationalFlowSuccess({ nodes: [], links: [] }));
      return;
    }

    try {
      type Response = {
        result: SankeyDataBackend;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        url,
        bots.current.id,
        filter,
        { intent },
        sharingToken,
      );

      dispatch(getConversationalFlowSuccess(mapToSankeyData(result)));
    } catch (error) {
      dispatch(
        getConversationalFlowFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

const getIntentsTreeSuccess = (intentsTree: MetricPanelOption[]) => ({
  type: MetricsActionTypeConstant.GET_INTENTS_TREE_SUCCESS,
  intentsTree,
});

export const getIntentsTree = ([option]: MetricPanelOption[]) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const {
    dashboard: { sharingToken, filter },
    account: { bots },
  } = getState();

  if (bots.current === null) return;

  dispatch({
    type: MetricsActionTypeConstant.GET_INTENTS_TREE,
    intent: option.label,
  });

  try {
    type Response = {
      result: SankeyDataBackend;
    };

    const {
      data: { result },
    } = await getMetric<Response>(
      'intents/conversation_flow',
      bots.current.id,
      filter,
      { intent: option.value },
      sharingToken,
    );

    dispatch(
      getIntentsTreeSuccess(
        mapSankeyToTree(mapToSankeyData(result)) as MetricPanelOption[],
      ),
    );
  } catch (error) {
    dispatch(getIntentsTreeSuccess([]));
  }
};

const getLastMsgDateSuccess = (value: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_LAST_MSG_DATE_SUCCESS,
  value,
});

const getLastMsgDateFailure = (error: string): MetricsActionType => ({
  type: MetricsActionTypeConstant.GET_LAST_MSG_DATE_FAILURE,
  error,
});

export const getLastMsgDate = () => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { sharingToken, filter },
      metrics: { metric },
      account: { bots },
    } = getState();

    if (bots.current === null) return;

    dispatch({ type: MetricsActionTypeConstant.GET_LAST_MSG_DATE });

    try {
      type Response = {
        result: number;
      };

      const {
        data: { result },
      } = await getMetric<Response>(
        metric.lastMsgDate.url,
        bots.current.id,
        filter,
        undefined,
        sharingToken,
      );

      dispatch(getLastMsgDateSuccess(getRelativeDate(result)));
    } catch (error) {
      dispatch(
        getLastMsgDateFailure(
          error.response ? error.response.data.message : error.message,
        ),
      );
    }
  };
};

export const setMetricIntent = (
  metric: MetricName,
  intent: string | string[] | null,
) => {
  return async (
    dispatch: Dispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const {
      dashboard: { intents },
    } = getState();

    if (metric === 'funnel') {
      const intentsTree = intents.list.map(i => ({
        value: i,
        label: i,
        isLeaf: false,
      }));

      dispatch({
        type: MetricsActionTypeConstant.SET_METRIC_INTENT,
        metric,
        intents: intent,
        intentsTree,
      });
    } else {
      dispatch({
        type: MetricsActionTypeConstant.SET_METRIC_INTENT,
        metric,
        intent,
      });
    }
  };
};
