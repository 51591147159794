import React, { useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Form, Input, Checkbox, Button, Modal } from 'antd';
import {
  MailOutlined,
  UnlockOutlined,
  GlobalOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gold } from '@ant-design/colors';

import { signUp } from 'actions/account';
import { RootState } from 'reducers';
import { SignUpFormData } from 'types/account';
import { ResponseMsgBox } from 'components/ui';
import { checkPromocode } from 'api/account';

import { FormField, HelpMessage, Title } from './ui';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SignUp = ({ userEmail, error, prompt, signUpUser }: Props) => {
  const [promocodeInputVisible, setPromocodeInputVisible] = useState(false);
  const [isPromocodeValid, setIsPromocodeValid] = useState<boolean | null>(
    null,
  );
  const { t } = useTranslation();
  const handleOnFinish = async (data: SignUpFormData) => {
    if (promocodeInputVisible && data.promocode) {
      setIsPromocodeValid(await checkPromocode(data.promocode));
      isPromocodeValid && signUpUser(data);
    } else {
      signUpUser(data);
    }
  };
  return (
    <>
      <Title>{t('Register a new account')}</Title>

      <ResponseMsgBox error={t(error)} prompt={t(prompt)} />

      <Form initialValues={{ accepted: false }} onFinish={handleOnFinish}>
        <FormField
          name="name"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter your full name'),
            },
          ]}
        >
          <Input
            size="large"
            type="text"
            prefix={<UserOutlined style={{ color: gold[5] }} />}
            placeholder={t('Full name')}
          />
        </FormField>

        <FormField
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter valid email'),
              type: 'email',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: gold[5] }} />}
            placeholder={t('Email')}
          />
        </FormField>

        <FormField name="website" hasFeedback>
          <Input
            size="large"
            type="text"
            prefix={<GlobalOutlined style={{ color: gold[5] }} />}
            placeholder={t('Company website')}
          />
        </FormField>

        <FormField
          name="password"
          style={{ marginBottom: 10 }}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('Please enter password'),
            },
            { min: 8, message: t('Password must be at least 8 symbols') },
          ]}
        >
          <Input.Password
            size="large"
            prefix={<UnlockOutlined style={{ color: gold[5] }} />}
            placeholder={t('Password')}
          />
        </FormField>

        <Button
          type="link"
          onClick={() => {
            setIsPromocodeValid(null);
            setPromocodeInputVisible(prevState => !prevState);
          }}
        >
          {t(`${promocodeInputVisible ? 'No promocode' : 'Have a promocode?'}`)}
        </Button>

        {promocodeInputVisible && (
          <FormField
            name="promocode"
            hasFeedback
            rules={[
              {
                required: true,
                message: t('Please enter a promocode'),
              },
            ]}
            help={
              isPromocodeValid === false
                ? `${t('Promocode is invalid')}`
                : undefined
            }
          >
            <Input size="large" type="text" placeholder={t('Promocode')} />
          </FormField>
        )}

        <FormField
          name="accepted"
          valuePropName="checked"
          rules={[
            {
              required: true,
              transform: (value: boolean) => value || undefined,
              type: 'boolean',
              message: t('You must accept the Terms of Service'),
            },
          ]}
        >
          <Checkbox>
            {`${t('I agree to the')} `}
            <a href="/">{t('Terms of Service')}</a>
          </Checkbox>
        </FormField>

        <FormField>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            style={{ borderRadius: '4px' }}
          >
            {t('Create account')}
          </Button>
        </FormField>
      </Form>

      <HelpMessage>
        {`${t('Already have an account?')} `}
        <Link to="/auth/login">{t('Log in now')}</Link>
      </HelpMessage>

      <Modal
        visible={prompt !== ''}
        centered
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0', height: '80vh' }}
      >
        <iframe
          title="airtable"
          src={`https://airtable.com/embed/shrjCX8F7ioLNVYji?backgroundColor=pink&prefill_email=${userEmail}`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (store: RootState) => ({
  userEmail: store.account.user.data.email,
  error: store.account.signUp.error,
  prompt: store.account.signUp.prompt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signUpUser: signUp,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
