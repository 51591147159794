import React from 'react';
import { Trans } from 'react-i18next';
import pic3 from 'assets/img/tutorial-slides/Picture3.png';

import Slide, {
  PrevButton,
  NextButton,
  SpaceCenterer,
  CarouselType,
} from './Slide';

const Picture = () => <img src={pic3} alt="" />;

export default ({ carousel }: CarouselType) => (
  <Slide>
    <h2>
      <Trans>Link creator</Trans>
    </h2>

    <SpaceCenterer>
      <Picture />
    </SpaceCenterer>

    <p>
      <Trans>You can use </Trans>
      <a
        href="https://app.onedash.cc/#/tools/link-creator"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>Link creator</Trans>
      </a>
      <b>
        <Trans> tool </Trans>
      </b>
      <Trans>to generate short links with your UTM-parameters inside.</Trans>
    </p>

    <p>
      <Trans>With this </Trans>
      <b>
        <Trans>link</Trans>
      </b>
      <Trans>
        , you can track users regardless of the bot’s channel limitations.
      </Trans>
    </p>

    <p>
      <Trans>More information about Link creator is available </Trans>
      <a
        href="https://docs.onedash.cc/#/link-creator"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>here</Trans>
      </a>
      .
    </p>

    <SpaceCenterer>
      <PrevButton carousel={carousel} />
      <NextButton carousel={carousel} />
    </SpaceCenterer>
  </Slide>
);
