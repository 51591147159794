import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal as ModalAntd, Carousel as CarouselAntd, Button } from 'antd';
import { gold } from '@ant-design/colors';
import CarouselType from 'antd/lib/carousel';

import slides from './slides';

const Modal = styled(ModalAntd)`
  width: 45vw !important;

  .ant-modal-header {
    padding: 16px 50px;
    text-align: center;
  }

  .ant-modal-body {
    height: 75vh;
    padding: 0;
  }

  .ant-carousel {
    height: 100%;
  }
`;

const Carousel = styled(CarouselAntd)`
  height: 100%;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    overflow: auto;
  }

  .slick-dots {
    bottom: -45px;

    button {
      height: 4px !important;
      background-color: ${gold[5]} !important;
    }
  }
`;

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

const Tutorial = ({ visible, setVisible }: Props) => {
  const carousel = useRef<CarouselType>(null);
  const [slidesSeen, setSlidesSeen] = useState([0]);
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Let’s quickly explain to you how OneDash works')}
      visible={visible}
      centered
      footer={
        <Button
          type={slidesSeen.length === slides.length ? 'primary' : 'default'}
          onClick={() => setVisible(false)}
        >
          {t('Close')}
        </Button>
      }
      closable={false}
    >
      <Carousel
        ref={carousel}
        afterChange={(slideIdx: number) =>
          setSlidesSeen(prevSlidesSeen =>
            prevSlidesSeen.includes(slideIdx)
              ? prevSlidesSeen
              : [...prevSlidesSeen, slideIdx],
          )
        }
      >
        {slides.map(({ key, Slide }) => (
          <Slide key={key} carousel={carousel} />
        ))}
      </Carousel>
    </Modal>
  );
};

export default Tutorial;
