import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer: FooterAntd } = Layout;

const Wrapper = styled(FooterAntd)`
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled.span`
  color: #595959;
`;

const Divider = () => (
  <span style={{ margin: '0 3px', userSelect: 'none' }}>|</span>
);

type LinkProps = {
  href: string;
  text: string;
};

const Link = ({ href, text }: LinkProps) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

type FooterProps = {
  style?: { [key: string]: string };
  auth?: boolean;
};

const Footer = ({ style, auth = false }: FooterProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper style={style}>
      {auth ? null : (
        <>
          <Link href="mailto://onedash@eora.ru" text={t('Contact us')} />
          <Divider />
        </>
      )}

      <Link
        href="https://onedash.cc/blog?utm_source=dashboard&utm_campaign=footer&utm_medium=organic"
        text={t('Release notes')}
      />

      <Divider />

      <Copyright>OneDash, 2020</Copyright>

      <Divider />

      <Link href="https://docs.onedash.cc/#/" text={t('Documentation')} />

      {auth ? null : (
        <>
          <Divider />
          <Link
            href="https://airtable.com/shrcHcofZacKLUixm"
            text={t('Found a bug?')}
          />
        </>
      )}
    </Wrapper>
  );
};

export default Footer;
