import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { renameIntent } from 'actions/tools';

interface Props {
  slug: string;
  intent: string;
  botId: number;
}

const EditableTableCell = ({ slug, intent, botId }: Props) => {
  const [newSlug, setNewSlug] = useState<string>(slug);
  const [slugPreviousName, setSlugPreviousName] = useState<string>(slug);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setNewSlug(prevName => {
      setSlugPreviousName(prevName);
      return e.target.value;
    });
  };
  const handleBlur = () => {
    if (slugPreviousName.trim() !== newSlug.trim()) {
      setSlugPreviousName(newSlug);
      dispatch(renameIntent(newSlug, intent, botId));
    }
  };

  return (
    <Input
      onBlur={handleBlur}
      bordered={false}
      value={newSlug}
      onChange={handleChange}
      placeholder={t('Enter new name here')}
      suffix={<EditOutlined />}
    />
  );
};
export default EditableTableCell;
