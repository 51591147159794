import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal as ModalAntd, Form, Input, Button } from 'antd';
import { FormItem } from '../ui';

const Modal = styled(ModalAntd)`
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  padding: 10px 16px;
`;

type Props = {
  visible: boolean;
  disabled?: boolean;
  onSubmit: (data: { intercomToken: string }) => void;
  onCancel: () => void;
};

const IntercomModal = ({ visible, disabled, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Intercom token')}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={<div />}
    >
      <Form layout="vertical" onFinish={onSubmit}>
        <FormItem
          style={{ padding: 24 }}
          name="intercomToken"
          hasFeedback
          rules={[
            {
              required: true,
              message: t('This field is required!'),
            },
          ]}
        >
          <Input placeholder={t('Intercom token')} />
        </FormItem>

        <ModalButtonsWrapper>
          <FormItem style={{ margin: 0 }}>
            <Button type="default" onClick={onCancel}>
              {t('Cancel')}
            </Button>
          </FormItem>

          <FormItem style={{ margin: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              style={{ marginLeft: '20px' }}
            >
              {t('Create')}
            </Button>
          </FormItem>
        </ModalButtonsWrapper>
      </Form>
    </Modal>
  );
};

export default IntercomModal;
