import styled from 'styled-components';
import { Form } from 'antd';
import { red } from '@ant-design/colors';

const FormField = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .ant-form-item-explain {
    text-align: left;
    color: ${red[5]};
  }

  .ant-form-item-control-input {
    min-height: unset;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }
`;

export default FormField;
