import React, { useState, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { RootState } from 'reducers';
import { loadRefsList } from 'api/dashboard';
import alertsConfig from 'helpers/configs/alerts';
import { usePromptError, useSectionPlan } from 'helpers/hooks';
import { SelectOptions, Alert, AlertManagerPanelsConfig } from 'types/tools';
import * as toolsActions from 'actions/tools';

import { withPlan } from 'helpers/commonComponents';
import AlertSwitcher from './AlertManagerPanel';
import { Section, Title } from '../ui';

const ContentWrapper = styled.div`
  padding: 20px 24px;
`;

const AlertSwitchersWrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  margin-bottom: 22px;
`;

type Refs = {
  loading: boolean;
  list: SelectOptions;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Alerts = ({
  bots,
  alerts,
  createAlert,
  editAlert,
  deleteAlert,
}: Props) => {
  const { t } = useTranslation();
  const [refs, setRefs] = useState<Refs>({ loading: false, list: [] });
  const granted = useSectionPlan('alerts');

  usePromptError(
    alerts.prompt,
    alerts.error,
    alerts.prompt !== 'Alerts data is fetched successfully!', // TODO: remove
  );

  const getRefsList = async (id: number) => {
    setRefs(prefRefs => ({ ...prefRefs, loading: true }));
    const rList = await loadRefsList(id, {});
    setRefs({
      loading: false,
      list: rList.map(refItem => ({
        label: refItem.ref,
        value: refItem.ref,
      })),
    });
  };

  type MergedAlerts = (AlertManagerPanelsConfig[0] & {
    id?: number;
    checked: boolean;
    initialValues: Alert | undefined;
  })[];

  const mergedAlerts = useMemo<MergedAlerts>(() => {
    return alertsConfig.map(alertConfig => {
      const alertValues: Alert | undefined = alerts.list.filter(
        a => a.type === alertConfig.type,
      )[0];

      const items = alertConfig.items.map(alertConfigItem => {
        switch (alertConfigItem.key) {
          case 'botId': {
            const botIdConfigItem = {
              ...alertConfigItem,
              options: bots.list.map(bot => ({
                value: bot.id,
                label: bot.name,
              })),
              loading: bots.loading,
              defaultValue: alertValues?.botId,
            };

            if (alertConfig.type === 1) {
              if (
                alertValues?.botId &&
                refs.list.length === 0 &&
                !refs.loading
              ) {
                getRefsList(alertValues?.botId);
              }
              return {
                ...botIdConfigItem,
                onChange: async (val: string | number, form: any) => {
                  form.setFields([{ name: 'ref', value: undefined }]);
                  getRefsList(+val);
                },
              };
            }

            return botIdConfigItem;
          }

          case 'ref':
            return {
              ...alertConfigItem,
              options: refs.list,
              loading: refs.loading,
              defaultValue: alertValues?.ref,
            };

          case 'payload':
            return {
              ...alertConfigItem,
              defaultValue: alertValues?.payload,
            };

          default:
            return alertConfigItem;
        }
      });

      return {
        ...alertConfig,
        id: alertValues?.id,
        checked: alertValues !== undefined,
        initialValues: alertValues,
        items,
      };
    });
  }, [alerts.list, bots.list, bots.loading, refs.list, refs.loading]);

  return (
    <Section title={t('Alerts')}>
      {withPlan(
        <ContentWrapper>
          <Title>{t('Manage alerts')}</Title>

          <Description>
            <Trans>
              After turning on the alert, if the conditions are met, you will
              see a notification in the corner of the screen, and also get a
              copy of this notification in your email.
            </Trans>
          </Description>

          <AlertSwitchersWrapper>
            {mergedAlerts.map(alert => (
              <AlertSwitcher
                id={alert.id}
                key={alert.type}
                type={alert.type}
                items={alert.items}
                requiredFields={alert.requiredFields}
                createAlert={createAlert}
                editAlert={editAlert}
                deleteAlert={deleteAlert}
                checked={alert.checked}
                initialValues={alert.initialValues}
                loading={alerts.loading}
              />
            ))}
          </AlertSwitchersWrapper>
        </ContentWrapper>,
        granted,
      )}
    </Section>
  );
};

const mapStateToProps = (store: RootState) => ({
  bots: store.account.bots,
  alerts: store.tools.alerts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createAlert: toolsActions.createAlert,
      editAlert: toolsActions.editAlert,
      deleteAlert: toolsActions.deleteAlert,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
